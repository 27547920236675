import { format } from "date-fns";
import i18n from "shared/i18n";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { ReportSummaryContext } from "@/types/Report";
import {
  QueryValueAggregation,
  QueryValueData,
  QueryValueFormattingRule,
  QueryValueFormattingRuleOperator,
  QueryValueMetric,
  QueryValueReportConfig,
  QueryValueReportFilters,
  QueryValueSingleValue,
} from "@/types/reports/PlotQueryValue";
import { ReportDateFilter } from "@/types/reports/filters";
import {
  filterProcesses,
  getPreviousPeriodStartEndDate,
  getStartEndDate,
  getStartEndDateFromProcesses,
} from "@/views/reports/filters/filters";

const { localeText } = i18n.global;

export const processDetailMetrics: QueryValueMetric[] = [
  "utilization",
  "productive_days",
  "active_days",
  "inactive_days",
];

export const precisionByMetric: Record<QueryValueMetric, number> = {
  working_hours: 1,
  unit_value: 2,
  utilization: 1,
  productive_days: 1,
  active_days: 1,
  inactive_days: 1,
  delta_planner: 1,
  project_progress: 1,
};

export const defaultFilters = {
  location: [],
  processes: {
    mode: "component",
    components: [],
    single_processes: [],
  },
  unit_value_type: null,
  daterange: {
    type: "last_week",
    start_date: null,
    end_date: null,
  },
};

export const metrics: QueryValueMetric[] = [
  "project_progress",
  "delta_planner",
  "working_hours",
  "unit_value",
  "utilization",
  "productive_days",
  "active_days",
  "inactive_days",
];

export const aggregationsByMetric: Record<QueryValueMetric, QueryValueAggregation[]> = {
  working_hours: ["sum"],
  unit_value: ["median", "average", "latest"],
  utilization: ["latest"],
  productive_days: ["latest"],
  active_days: ["latest"],
  inactive_days: ["latest"],
  delta_planner: ["latest"],
  project_progress: ["latest"],
};

export const hideAggregationForMetric: QueryValueMetric[] = ["delta_planner", "project_progress"];

export const filterKeysByMetric: Record<QueryValueMetric, (keyof QueryValueReportFilters)[]> = {
  working_hours: ["location", "processes", "daterange"],
  unit_value: ["location", "unit_value_type"],
  utilization: ["location", "processes", "daterange"],
  productive_days: ["location", "processes", "daterange"],
  active_days: ["location", "processes", "daterange"],
  inactive_days: ["location", "processes", "daterange"],
  delta_planner: [],
  project_progress: ["location"],
};

export const previousPeriodForByMetric: Record<QueryValueMetric, QueryValueAggregation[]> = {
  working_hours: ["sum"],
  unit_value: ["latest"],
  utilization: ["latest"],
  productive_days: ["latest"],
  active_days: ["latest"],
  inactive_days: ["latest"],
  delta_planner: [],
  project_progress: ["latest"],
};

type TranslateFn = (key: string) => string;

const getWorkingDaysUnit = (
  t: TranslateFn,
  filters: QueryValueReportFilters,
  config: QueryValueReportConfig,
  full?: boolean,
) =>
  full
    ? ` ${t("analytics.reports.query_value_units.working_days_full")}`
    : t("analytics.reports.query_value_units.working_days");

export const valueUnitByMetric: Record<
  QueryValueMetric,
  (
    t: TranslateFn,
    filters: QueryValueReportFilters,
    config: QueryValueReportConfig,
    full?: boolean,
  ) => string
> = {
  working_hours: (t: TranslateFn) => t("analytics.reports.query_value_units.hours"),
  unit_value: (t: TranslateFn, filters: QueryValueReportFilters) =>
    `${t("unit_values.unit_value_unit")}/${
      filters.unit_value_type ? t(`unit_values.types.${filters.unit_value_type}.unit`) : "?"
    }`,
  utilization: () => "%",
  productive_days: getWorkingDaysUnit,
  active_days: getWorkingDaysUnit,
  inactive_days: getWorkingDaysUnit,
  delta_planner: getWorkingDaysUnit,
  project_progress: () => "%",
};

const isFormattingRuleMatching = (rule: QueryValueFormattingRule, value: number) => {
  const ruleValue = rule.value;
  if (ruleValue === null) {
    return false;
  }
  const operators: Record<QueryValueFormattingRuleOperator, () => boolean> = {
    equal: () => value === ruleValue,
    greater: () => value > ruleValue,
    greater_or_equal: () => value >= ruleValue,
    lesser: () => value < ruleValue,
    lesser_or_equal: () => value <= ruleValue,
  };
  return operators[rule.operator]();
};

export const findMatchingFormattingRule = (
  rules: QueryValueFormattingRule[],
  data: QueryValueSingleValue,
): QueryValueFormattingRule | undefined => {
  if (data.value === null) {
    return undefined;
  }
  return rules.find((rule) => isFormattingRuleMatching(rule, data.value as number));
};

export const calculatePreviousPeriodChange = (
  value: number | null,
  previous_period_value: number | null,
) => {
  if (
    value === null ||
    previous_period_value === null ||
    !Number.isFinite(value) ||
    !Number.isFinite(previous_period_value)
  ) {
    return null;
  }
  const difference = value - previous_period_value;
  return previous_period_value !== 0 ? difference / previous_period_value : null;
};

const formatNumber = (value: number, precision: number) =>
  value.toLocaleString(localeText, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    useGrouping: false,
  });

export const formatValueTextWithTotalDays = (
  value: number | null,
  total: number | null,
  metric: QueryValueMetric,
) => {
  if (value === null || total === null) {
    return undefined;
  }
  return `${formatNumber(value, precisionByMetric[metric])} / ${formatNumber(
    total,
    precisionByMetric[metric],
  )}`
    .replaceAll(`.${"0".repeat(precisionByMetric[metric])}`, "")
    .replaceAll(`,${"0".repeat(precisionByMetric[metric])}`, "");
};

export const createProcessTableLinkQuery = (
  filters: QueryValueReportFilters,
  reportSummaryContext: ReportSummaryContext,
) => {
  const processIds =
    filters.processes.mode === "component"
      ? filters.processes.components.flatMap((item) => item.processes)
      : filters.processes.single_processes;

  const dateFormat = "yyyy-MM-dd";
  const dates =
    reportSummaryContext.start_date &&
    reportSummaryContext.end_date &&
    filters.daterange.type !== "total"
      ? [
          format(reportSummaryContext.start_date, dateFormat),
          format(reportSummaryContext.end_date, dateFormat),
        ]
      : [];

  return {
    processes: [...new Set(processIds)].join(",") || undefined,
    date: dates.join(",") || undefined,
    location: filters.location.join(",") || undefined,
  };
};

export const getReportSummaryContextFromFilter = (
  dateFilter: ReportDateFilter,
  config: QueryValueReportConfig,
  processes: ShortenedProcessWithTags[] | undefined,
): ReportSummaryContext => {
  const interval = getStartEndDate(dateFilter);
  const fallbackInterval = getStartEndDateFromProcesses(processes);
  const { startDate: previousPeriodStartDate, endDate: previousPeriodEndDate } =
    config.show_previous_period && dateFilter.type !== "total"
      ? getPreviousPeriodStartEndDate(dateFilter)
      : { startDate: null, endDate: null };
  return {
    start_date: interval.startDate || fallbackInterval.startDate,
    end_date: interval.endDate || fallbackInterval.endDate,
    previous_period_start_date: previousPeriodStartDate,
    previous_period_end_date: previousPeriodEndDate,
  };
};

export const getFilteredAndSortedProcesses = (
  queryValueData: QueryValueData,
  filters: QueryValueReportFilters,
  config: QueryValueReportConfig,
) => {
  if (!queryValueData.shortenedProcessesWithTags) {
    return undefined;
  }
  const filterKeys = filterKeysByMetric[config.metric];
  const result = filterProcesses(
    queryValueData.shortenedProcessesWithTags,
    queryValueData.hierarchyTags,
    {
      location: filterKeys.includes("location") ? filters.location : undefined,
      daterange: filterKeys.includes("daterange") ? filters.daterange : undefined,
      processes: filterKeys.includes("processes") ? filters.processes : undefined,
    },
  );
  result.sort((a, b) => a.date.localeCompare(b.date));
  return result;
};

export const getPreviousPeriodProcesses = (
  queryValueData: QueryValueData,
  filters: QueryValueReportFilters,
  config: QueryValueReportConfig,
  reportSummaryContext: ReportSummaryContext,
) => {
  if (
    !reportSummaryContext.previous_period_start_date ||
    !reportSummaryContext.previous_period_end_date
  ) {
    return undefined;
  }
  return getFilteredAndSortedProcesses(
    queryValueData,
    {
      ...filters,
      daterange: {
        type: "date_range",
        start_date: reportSummaryContext.previous_period_start_date,
        end_date: reportSummaryContext.previous_period_end_date,
      },
    },
    config,
  );
};
