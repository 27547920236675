<template>
  <div class="flex flex-col gap-2 -mt-0.5">
    <label
      class="flex items-center w-fit"
      :class="{ 'cursor-pointer': isPreviousPeriodOptionAllowed }"
    >
      <span
        v-if="!isPreviousPeriodOptionAllowed"
        class="h-4 w-4 inline-block rounded border border-gray-300 bg-gray-100 cursor-not-allowed"
      />
      <input
        v-else
        type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-yellow-500 focus:ring-yellow-500 cursor-pointer"
        :checked="config.show_previous_period"
        @change="handleShowPreviousPeriodChange"
      />
      <span class="ml-2" :class="{ 'text-gray-400': !isPreviousPeriodOptionAllowed }">{{
        config.metric === "project_progress"
          ? t("analytics.reports.show_planned_progress_value")
          : t("analytics.reports.show_previous_period_value")
      }}</span>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import {
  QueryValueAggregation,
  QueryValueReportConfig,
  QueryValueReportFilters,
} from "@/types/reports/PlotQueryValue";
import { previousPeriodForByMetric } from "@/views/reports/plots/query_value/queryValue";

const props = defineProps<{
  config: QueryValueReportConfig;
  filters: QueryValueReportFilters;
  aggregation: QueryValueAggregation;
}>();
const emit = defineEmits<{
  (eventName: "configChange", config: QueryValueReportConfig): void;
}>();

const { t } = useI18n();

const handleShowPreviousPeriodChange = (event: Event) => {
  const inputElement = event.target as HTMLInputElement;
  emit("configChange", {
    ...props.config,
    show_previous_period: inputElement.checked,
  });
};

const isPreviousPeriodOptionAllowed = computed(() =>
  previousPeriodForByMetric[props.config.metric].includes(props.aggregation),
);
</script>
