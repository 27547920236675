import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useHasOrganizationPermission } from "shared/composables/organization";
import {
  useCurrentCustomerName,
  useCurrentSiteId,
  useHasPermission,
} from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import logger from "shared/services/logger";
import {
  User,
  UserProject,
  InviteUser,
  UserRole,
  UserOrganizationToUpdate,
  UserProjectPermissionGroup,
  UserOrganizationPermissionGroup,
} from "shared/types/User";
import UserRepository from "@/repositories/UserRepository";

export const userProjectPermissionsOrdered: UserProjectPermissionGroup[] = [
  "project_admin",
  "all_camera_features_user",
  "planner_user",
  "processes_user",
  "site_activity_user",
  "daily_report_user",
  "live_camera_feature_user",
  "project_base",
];

export const userOrganizationPermissionsOrdered: UserOrganizationPermissionGroup[] = [
  "organization_base",
  "organization_admin",
];

export const useUsersForProject = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const hasAdminPermissions = useHasPermission("app_admin");

  const { data, isLoading, error } = useQuery<User[]>({
    queryKey: ["usersForProject", currentCustomerName, currentSiteId],
    queryFn: async () => {
      if (hasAdminPermissions) {
        return await UserRepository.loadAllUsersForProject(currentCustomerName, currentSiteId);
      } else {
        return await UserRepository.loadUsersForProject(currentCustomerName, currentSiteId);
      }
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });
  const users = computed(() => (data.value || []) as User[]);
  return { users, isLoading, error };
};

export const useUpdateUserProjectPermission = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: updateUserProjectPermission,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useMutation<User, Error, { username: string; updatedProjectPermission: UserProject }>({
    mutationFn: ({ username, updatedProjectPermission }) =>
      UserRepository.updateUserProjectPermission(
        username,
        currentCustomerName,
        currentSiteId,
        updatedProjectPermission,
      ),
    onSuccess: (updatedUser) => {
      queryClient.setQueryData(
        ["usersForProject", currentCustomerName, currentSiteId],
        (users: User[] | undefined) =>
          users
            ?.map((user) => (user._id === updatedUser._id ? updatedUser : user))
            .filter((user) =>
              user.projects.some(
                (project) =>
                  project.customer_name === currentCustomerName &&
                  project.site_id === currentSiteId,
              ),
            ),
      );
    },
    onError: (error) => {
      logger.error(error);
      useCustomToast().error(t("admin.project_participants.error.permission_update"));
    },
  });

  return { updateUserProjectPermission, isUpdateLoading, isUpdateError };
};

export const useUpdateUserOrganizationPermission = () => {
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: updateUserOrganizationPermission,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useMutation<
    User,
    Error,
    { username: string; organizationPermission: UserOrganizationToUpdate; organizationId: string }
  >({
    mutationFn: ({ username, organizationPermission, organizationId }) =>
      UserRepository.updateUserOrganizationPermission(
        username,
        organizationId,
        organizationPermission,
      ),
    onSuccess: () => {
      queryClient.resetQueries({
        predicate: (query) => query.queryKey[0] === "users-for-organization",
      });
    },
    onError: (error) => {
      logger.error(error);
      useCustomToast().error(t("admin.project_participants.error.permission_update"));
    },
  });

  return { updateUserOrganizationPermission, isUpdateLoading, isUpdateError };
};

export const useAdminUpdateUserProperties = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: adminUpdateUserProperties,
    isLoading: isUpdatePropertiesLoading,
    isError: isUpdatePropertiesError,
  } = useMutation<User, Error, { username: string; name: string; company: string; role: UserRole }>(
    {
      mutationFn: ({ username, name, company, role }) =>
        UserRepository.adminUpdateUserProperties(username, name, company, role),
      onSuccess: (updatedUser) => {
        queryClient.setQueryData(
          ["usersForProject", currentCustomerName, currentSiteId],
          (users: User[] | undefined) =>
            users?.map((user) => (user._id === updatedUser._id ? updatedUser : user)),
        );
        queryClient.resetQueries({
          predicate: (query) => query.queryKey[0] === "users-for-organization",
        });
      },
      onError: (error) => {
        logger.error(error);
        useCustomToast().error(t("admin.project_participants.error.properties_update"));
      },
    },
  );

  return { adminUpdateUserProperties, isUpdatePropertiesLoading, isUpdatePropertiesError };
};

export const useBulkInviteUsersToProject = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: bulkInviteUsersToProject,
    isLoading: isInviteLoading,
    isError: isInviteError,
  } = useMutation<User[], Error, InviteUser[]>({
    mutationFn: (usersToInvite: InviteUser[]) =>
      UserRepository.bulkInviteUsersToProject(currentCustomerName, currentSiteId, usersToInvite),
    onSuccess: (invitedUsers) => {
      queryClient.setQueryData(
        ["usersForProject", currentCustomerName, currentSiteId],
        (users: User[] | undefined) => [...(users || []), ...invitedUsers],
      );
    },
    onError: (error) => {
      logger.error(error);
      useCustomToast().error(t("admin.project_participants.error.invite"));
    },
  });

  return { bulkInviteUsersToProject, isInviteLoading, isInviteError };
};

export const useBulkInviteUsersToOrganization = () => {
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: bulkInviteUsersToOrganization,
    isLoading: isInviteLoading,
    isError: isInviteError,
  } = useMutation<User[], Error, { usersToInvite: InviteUser[]; organizationId: string }>({
    mutationFn: ({ usersToInvite, organizationId }) =>
      UserRepository.bulkInviteUsersToOrganization(organizationId, usersToInvite),
    onSuccess: () => {
      queryClient.resetQueries({
        predicate: (query) => query.queryKey[0] === "users-for-organization",
      });
    },
    onError: (error) => {
      logger.error(error);
      useCustomToast().error(t("admin.project_participants.error.invite"));
    },
  });

  return { bulkInviteUsersToOrganization, isInviteLoading, isInviteError };
};

export const useDeleteUser = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: deleteUser, isLoading } = useMutation<void, Error, string>({
    mutationFn: (username) => UserRepository.deleteUser(username),
    onSuccess: (result, username) => {
      queryClient.setQueryData(
        ["usersForProject", currentCustomerName, currentSiteId],
        (users: User[] | undefined) => users && users.filter((user) => user.username !== username),
      );
      queryClient.resetQueries({
        predicate: (query) => query.queryKey[0] === "users-for-organization",
      });
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("admin.project_participants.error.delete"));
      return false;
    },
  });

  return { deleteUser, isLoading };
};

export const useUsersForOrganization = (organizationId: Ref<string>) => {
  const { t } = useI18n();
  const hasOrganizationAdminPermission = useHasOrganizationPermission(
    "organization_admin",
    organizationId.value,
  );

  const { data, isLoading, isFetching, error } = useQuery<User[]>({
    queryKey: ["users-for-organization", organizationId],
    queryFn: () =>
      hasOrganizationAdminPermission.value
        ? UserRepository.loadUsersForOrganization(organizationId.value)
        : UserRepository.loadBaseUsersForOrganization(organizationId.value),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("organizations.load_users_error"));
      return false;
    },
  });
  const users = computed(() => (data.value || []) as User[]);
  return { users, isLoading, isFetching, error };
};

export const useGetSortedFeaturePermissionsForUser = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();

  return (user: User) => {
    const project = user.projects.find(
      (project: UserProject) =>
        project.customer_name === customerName && project.site_id === siteId,
    );
    if (!project) {
      return [];
    }
    return project.explicit_groups
      .slice()
      .sort(
        (a, b) =>
          userProjectPermissionsOrdered.indexOf(a) - userProjectPermissionsOrdered.indexOf(b),
      );
  };
};
