<template>
  <Modal :open="true" @close="emit('close')" customCls="w-full m-2 lg:w-1/3">
    <template #title>
      <div class="text-lg leading-6 font-medium text-gray-900">
        {{ t("organizations.add_organization") }}
      </div>
    </template>
    <template #content>
      <Form
        @submit="handleSubmit"
        :initialValues="initialValues"
        :validationSchema="schema"
        class="text-left flex flex-col gap-4"
        v-slot="{ isSubmitting, values }"
      >
        <InputField name="name" :label="t('organizations.organization_name')" />
        <CheckboxField name="isRoot" :label="t('organizations.root')" />
        <div>
          {{ t("organizations.parent") }}:
          {{ !values.isRoot && organization ? organization.name : t("organizations.root") }}
        </div>
        <div class="flex mt-4 justify-end">
          <button
            type="submit"
            class="focus:outline-none flex justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-0 disabled:cursor-default disabled:bg-gray-300"
            :disabled="isSubmitting"
          >
            <LoadingSpinner v-if="isSubmitting" size="w-5 h-5" color="white" class="mr-2" />
            {{ t("buttons.create") }}
          </button>
        </div>
      </Form>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { Form, SubmissionHandler } from "vee-validate";
import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useCreateOrganization } from "shared/composables/organization";
import { Organization, OrganizationToCreate } from "shared/types/Organization";
import CheckboxField from "@/components/forms/CheckboxField.vue";
import InputField from "@/components/forms/InputField.vue";

type OrganizationForm = {
  name: string;
  isRoot: boolean;
};

const { t } = useI18n();

const props = defineProps<{ organization: Organization | undefined }>();
const emit = defineEmits<{
  (eventName: "close"): void;
  (eventName: "selectOrganization", organizationId: string): void;
}>();

const { createOrganization } = useCreateOrganization();

const schema = yup.object({
  name: yup.string().required(t("organizations.validation_field_required")),
});

const initialValues: Ref<OrganizationForm> = computed(() => ({
  name: "",
  isRoot: !props.organization,
}));

const handleSubmit: SubmissionHandler = async (genericObject) => {
  const values = genericObject as OrganizationForm;
  const payload: OrganizationToCreate = {
    name: values.name,
    parent_id: values.isRoot ? null : props.organization?._id ?? null,
    explicit_projects: [],
  };
  const result = await createOrganization(payload).catch(() => undefined);
  if (result) {
    emit("selectOrganization", result.created_organization._id);
    emit("close");
  }
};
</script>
