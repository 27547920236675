<template>
  <Modal :open="true" @close="emit('close')" custom-cls="sm:w-full sm:max-w-lg">
    <template #title>
      <div>
        <h3 class="pt-2 text-lg font-medium leading-6 text-gray-900">
          {{ user.name }}
        </h3>
        <small class="truncate text-gray-400"> {{ user.email }} </small>
      </div>
    </template>
    <template #content>
      <div class="sm:pt-4 pt-2 sm:border-t sm:border-gray-200">
        <button
          v-if="!organizationId"
          type="button"
          :disabled="isUpdateUserProjectPermissionLoading"
          class="inline-flex items-center w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:col-start-1 sm:mt-0 sm:text-sm"
          @click="handleRemoveUserFromProjectClick"
        >
          <MinusIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          {{ t("admin.project_participants.modal_delete_user.delete_from_project") }}
        </button>
        <button
          v-if="organizationId"
          type="button"
          :disabled="isUpdateUserOrganizationPermissionLoading"
          class="inline-flex items-center w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:col-start-1 sm:mt-0 sm:text-sm"
          @click="handleRemoveUserFromOrganizationClick"
        >
          <MinusIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          {{ t("admin.project_participants.modal_delete_user.delete_from_organization") }}
        </button>
        <div class="pt-4" v-if="hasPermission('app_admin')">
          <button
            type="button"
            :disabled="isDeleteUserLoading"
            class="inline-flex items-center w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-xs sm:text-base font-medium text-white shadow-sm hover:bg-red-600 sm:col-start-2 sm:text-sm"
            @click="handleDeleteUserPermanentlyClick"
          >
            <XMarkIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            {{ t("admin.project_participants.modal_delete_user.delete_permanently") }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { MinusIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import { useI18n } from "vue-i18n";
import Modal from "shared/components/modals/Modal.vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { User } from "shared/types/User";
import {
  useDeleteUser,
  useUpdateUserOrganizationPermission,
  useUpdateUserProjectPermission,
} from "@/composables/user";

const props = defineProps<{ user: User; organizationId?: string }>();
const emit = defineEmits<{ (eventName: "close"): void }>();

const { t } = useI18n();

const customerName = useCurrentCustomerName();
const siteId = useCurrentSiteId();
const { updateUserProjectPermission, isUpdateLoading: isUpdateUserProjectPermissionLoading } =
  useUpdateUserProjectPermission();
const {
  updateUserOrganizationPermission,
  isUpdateLoading: isUpdateUserOrganizationPermissionLoading,
} = useUpdateUserOrganizationPermission();
const { deleteUser, isLoading: isDeleteUserLoading } = useDeleteUser();

const handleRemoveUserFromProjectClick = async () => {
  const currentProjectPermission = props.user.projects.find(
    (project) => project.customer_name === customerName && project.site_id === siteId,
  );
  if (currentProjectPermission) {
    await updateUserProjectPermission({
      username: props.user.username,
      updatedProjectPermission: {
        ...currentProjectPermission,
        explicit_groups: [],
        groups: [],
      },
    })
      .then(() => {
        emit("close");
      })
      .catch(() => undefined);
  } else {
    emit("close");
  }
};

const handleRemoveUserFromOrganizationClick = async () => {
  if (!props.organizationId) {
    return;
  }
  await updateUserOrganizationPermission({
    username: props.user.username,
    organizationId: props.organizationId,
    organizationPermission: { explicit_groups: [], company_type: null },
  })
    .then(() => {
      emit("close");
    })
    .catch(() => undefined);
};

const handleDeleteUserPermanentlyClick = async () => {
  await deleteUser(props.user.username)
    .then(() => {
      emit("close");
    })
    .catch(() => undefined);
};
</script>
