import i18n from "shared/i18n";
import projectProgressService from "@/services/projectProgressService";
import { QueryValueContext, QueryValueSingleValue } from "@/types/reports/PlotQueryValue";

const { t } = i18n.global;

const convertToValue = (value: number | null) => (value !== null ? value * 100 : null);

const calculateProjectProgressMetric = (
  context: QueryValueContext,
): QueryValueSingleValue | null => {
  const { planConfig, processes, projectDurationSettings, hierarchyTags, filters } = context;

  if (!planConfig || !processes || !projectDurationSettings || hierarchyTags.length === 0) {
    return null;
  }

  const date = undefined;
  const value = projectProgressService.calculateProjectProgressPercentage(
    planConfig,
    processes,
    projectDurationSettings,
    hierarchyTags,
    date,
    filters.location,
  );

  const processesForPlannedValue = undefined;
  const planForPlannedProgress = projectProgressService.calculatePlanForPlannedProgress(planConfig);
  const plannedValue = projectProgressService.calculateProjectProgressPercentage(
    planForPlannedProgress,
    processesForPlannedValue,
    projectDurationSettings,
    hierarchyTags,
    date,
    filters.location,
  );
  const previousPeriodValue = convertToValue(plannedValue);

  return {
    value: convertToValue(value),
    previous_period_value: previousPeriodValue,
    previous_period_value_prefix: `${t("analytics.planner.planned_event_name")}: `,
    previous_period_value_percentage_sign: " pp",
    previous_period_value_use_dot: true,
    previous_period_value_invert_shown_info: true,
  };
};

export default calculateProjectProgressMetric;
