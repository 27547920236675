<template>
  <Modal
    :open="true"
    @close="$emit('closed')"
    customCls="w-full m-2 lg:w-1/3"
    customStyle="min-width: 500px"
  >
    <template #title>
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{
          $t(
            unitValue
              ? "unit_values.column_modal.title_edit"
              : "unit_values.column_modal.title_add",
          )
        }}
      </h3></template
    >
    <template #content>
      <Form
        @submit="handleSubmit"
        :initialValues="initialValues"
        :validationSchema="schema"
        class="text-left flex flex-col gap-4"
        v-slot="{ isSubmitting, values }"
      >
        <ColumnModalTypeField :existingTypes="existingTypes" />
        <div class="text-xs flex gap-1" v-if="values.type">
          <span
            v-for="process in getAllProcess(values.type)"
            :key="process"
            class="bg-gray-100 px-1.5 py-0.5 rounded-md text-center"
          >
            {{ $t(`process_classes.${process}`) }}
          </span>
        </div>
        <div class="flex flex-col gap-5 border rounded-xl p-4 mt-4 shadow">
          <div>{{ $t("unit_values.column_modal.cleaned_unit_values_header") }}</div>
          <div class="flex flex-col gap-4">
            <FloatField name="type_duration_factor">
              <template #label>
                <span class="flex items-center gap-1"
                  >{{ `${$t("unit_values.column_modal.type_duration_factor")} (%)` }}
                  <OaiTooltip position="top">
                    <InformationCircleIcon class="h-5 text-green" />
                    <template #tooltip>
                      <div class="text-xs lg:max-w-96 max-w-64">
                        {{ $t("unit_values.column_modal.type_duration_factor_tooltip") }}
                      </div>
                    </template>
                  </OaiTooltip></span
                >
              </template>
            </FloatField>
            <FloatField name="type_workforce_delta">
              <template #label>
                <span class="flex items-center gap-1"
                  >{{ $t("unit_values.column_modal.type_workforce_delta") }}
                  <OaiTooltip position="top">
                    <InformationCircleIcon class="h-5 text-green" />
                    <template #tooltip>
                      <div class="text-xs lg:max-w-96 max-w-64">
                        {{ $t("unit_values.column_modal.type_workforce_delta_tooltip") }}
                      </div>
                    </template>
                  </OaiTooltip>
                </span>
              </template>
            </FloatField>
            <FloatField name="type_value_delta">
              <template #label>
                <span class="flex items-center"
                  >{{ $t("unit_values.column_modal.type_value_delta") }}
                  <OaiTooltip position="top">
                    <InformationCircleIcon class="h-5 text-green" />
                    <template #tooltip>
                      <div class="text-xs lg:max-w-96 max-w-64">
                        {{ $t("unit_values.column_modal.type_value_delta_tooltip") }}
                      </div>
                    </template>
                  </OaiTooltip></span
                >
              </template>
            </FloatField>
          </div>
        </div>
        <div class="flex mt-4">
          <button
            type="button"
            class="focus:outline-none flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:ring-0"
            @click="emit('deleted', unitValue.type)"
            v-if="unitValue && canDelete"
          >
            {{ $t("buttons.delete") }}
          </button>
          <div class="flex-1" />
          <button
            type="submit"
            class="focus:outline-none flex justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-0 disabled:cursor-default disabled:bg-gray-300"
            :disabled="isSubmitting"
          >
            <LoadingSpinner v-if="isSubmitting" size="w-5 h-5" color="white" class="mr-2" />
            {{ $t("buttons.apply") }}
          </button>
        </div>
      </Form>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { InformationCircleIcon } from "@heroicons/vue/24/solid";
import { Form, SubmissionHandler } from "vee-validate";
import { computed, PropType, Ref } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import FloatField from "shared/components/forms/FloatField.vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import numberService from "shared/services/numberService";
import { useProcessClasses } from "@/composables/process";
import { UnitValue, UnitValueColumnModalForm, UnitValueType } from "@/types/UnitValue";
import ColumnModalTypeField from "@/views/unit_values/components/ColumnModalTypeField.vue";
import { getProcesses } from "@/views/unit_values/services/processTableLinkService";
import { precision } from "../services/unitValues";

const { t } = useI18n();

const props = defineProps({
  existingTypes: {
    type: Array as PropType<UnitValueType[]>,
    required: true,
  },
  unitValue: {
    type: Object as PropType<UnitValue | null>,
    required: false,
  },
  canDelete: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["closed", "confirmed", "deleted"]);

const schema = yup.object({
  type: yup.string().required(t("unit_values.column_modal.validation_type_required")),
  type_duration_factor: yup
    .number()
    .nullable()
    .typeError(t("unit_values.column_modal.invalid_number")),
  type_workforce_delta: yup
    .number()
    .nullable()
    .typeError(t("unit_values.column_modal.invalid_number")),
  type_value_delta: yup.number().nullable().typeError(t("unit_values.column_modal.invalid_number")),
});

const initialValues: Ref<Partial<UnitValueColumnModalForm>> = computed(() => ({
  type: props.unitValue?.type,
  type_duration_factor:
    props.unitValue && props.unitValue.type_duration_factor !== null
      ? props.unitValue?.type_duration_factor * 100
      : null,
  type_workforce_delta: props.unitValue?.type_workforce_delta ?? null,
  type_value_delta: props.unitValue?.type_value_delta ?? null,
}));

const handleSubmit: SubmissionHandler = async (genericObject) => {
  const values = genericObject as UnitValueColumnModalForm;
  const finalValues: UnitValueColumnModalForm = {
    ...values,
    type_duration_factor:
      values.type_duration_factor !== null
        ? numberService.fixToPrecision(values.type_duration_factor / 100, precision + 2)
        : null,
  };
  emit("confirmed", finalValues);
  // Show spinner indefinitely. When parent is ready, it'll close this modal
  await new Promise(() => undefined);
};

const processClasses = useProcessClasses();

const getAllProcess = (value: UnitValueType) => {
  return getProcesses(value, processClasses.value).processes;
};
</script>
