<template>
  <Modal :open="true" @close="emit('close')" customCls="w-full sm:w-max lg:w-1/4">
    <template #title>
      <p v-if="props.nonWorkingDay && props.nonWorkingDay.type">
        {{ t("buttons.edit") }}
        {{
          $t(
            `admin.durations_project_settings.non_working_day_type_labels.${props.nonWorkingDay.type}`,
          )
        }}
      </p>
      <p v-else>
        {{
          showTypeField || !formRef?.values?.type
            ? $t("admin.durations_project_settings.holidays_editor_header")
            : `${$t("admin.durations_project_settings.add")} ${$t(
                `admin.durations_project_settings.non_working_day_type_labels.${formRef?.values?.type}`,
              )}`
        }}
      </p>
    </template>
    <template #content>
      <Form
        @submit="handleSubmit"
        @invalidSubmit="hasSubmitted = true"
        :initialValues="initialValues"
        :validationSchema="schema"
        v-slot="{ values, setFieldValue }"
        ref="formRef"
      >
        <div class="text-left flex flex-col sm:gap-8 gap-4">
          <SelectListField
            v-show="showTypeField"
            name="type"
            :label="$t('admin.durations_project_settings.holidays_field_type')"
            :options="typeOptions"
            :minWidth="250"
            :placeholder="`-- ${$t('form.select_option')} --`"
            @update="showTypeField = false"
          />

          <div
            v-show="!showTypeField && values?.type"
            class="transition-all flex flex-col sm:gap-8 gap-4"
          >
            <Field name="name" :label="t('admin.durations_project_settings.holidays_field_name')" />
            <div v-if="values?.type === 'disturbance'">
              <SelectListField
                name="disturbance_type"
                :label="t('admin.durations_project_settings.holidays_field_disturbance_type')"
                :options="disturbanceOptions"
                :minWidth="250"
                :placeholder="`-- ${$t('form.select_option')} --`"
              />
            </div>
            <DateField
              v-if="values.type === 'public_holiday'"
              name="date"
              :label="t('admin.durations_project_settings.holidays_field_date')"
            />

            <div v-if="values.type && ['disturbance', 'company_holiday'].includes(values.type)">
              <DateRangeField
                name="range"
                v-model="values.range"
                :enable-time-picker="false"
                :label="t('admin.durations_project_settings.holidays_field_date')"
                auto-apply
                range
              />
            </div>

            <div v-if="values.type === 'disturbance'" class="flex flex-col sm:gap-8 gap-4">
              <Field
                as="textarea"
                name="disturbance_description"
                inputClass="max-h-24"
                :label="
                  t('admin.durations_project_settings.holidays_field_disturbance_description')
                "
              />
              <label class="flex items-center gap-2">
                {{ t("working_day.inactive_days") }}?
                <Switch
                  :modelValue="values.is_critical"
                  @update:modelValue="setFieldValue('is_critical', $event)"
                  class="relative inline-flex h-5 w-10 cursor-pointer rounded-full"
                >
                  <span class="sr-only">Use settings</span>
                  <span
                    :class="[
                      values.is_critical ? 'bg-yellow-500' : 'bg-gray-200',
                      'absolute h-5 w-9 rounded-full transition-colors',
                    ]"
                  />
                  <span
                    :class="[
                      values.is_critical ? 'translate-x-5' : 'translate-x-0',
                      'absolute left-0 h-5 w-5 transform rounded-full bg-white transition-transform',
                    ]"
                  />
                </Switch>
              </label>
            </div>
          </div>
          <template class="flex justify-between w-full">
            <button
              :disabled="!values.type"
              v-show="!props.nonWorkingDay.type"
              type="button"
              @click="toggleTypeSelection"
              :class="[
                'inline-flex items-center md:px-4 md:py-1.5 py-1 px-1.5 rounded-md font-bold border shadow transition-colors duration-150',
                showTypeField ? 'ml-auto' : '',
                values.type
                  ? 'text-yellow border-yellow hover:bg-yellow hover:text-white bg-gray-50'
                  : 'border-gray-100 text-gray-300 bg-gray-100 cursor-not-allowed', // Gray when disabled
              ]"
            >
              <ArrowLongRightIcon
                :class="showTypeField ? 'rotate-0' : 'rotate-180'"
                class="shrink-0 h-6 transition-transform duration-100"
              />
            </button>
            <button
              v-show="values.type && !showTypeField"
              type="submit"
              :class="[
                'focus:outline-none w-max rounded-md bg-yellow-500 px-4 py-2 text-sm text-white shadow-sm hover:bg-yellow-600 justify-self-end',
                !showTypeField && 'ml-auto',
              ]"
            >
              {{ t("buttons.apply") }}
            </button>
          </template>
        </div>
      </Form>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { Switch } from "@headlessui/vue";
import { ArrowLongRightIcon } from "@heroicons/vue/24/solid";
import { Form, FormContext, SubmissionHandler } from "vee-validate";
import { computed, PropType, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import SelectListField from "shared/components/forms/SelectListField.vue";
import Modal from "shared/components/modals/Modal.vue";
import { NonWorkingDay } from "shared/types/ProjectDurationSettings";
import DateField from "@/components/forms/DateField.vue";
import Field from "@/components/forms/Field.vue";
import { disturbances } from "@/constants/disturbances";
import { nonWorkingDaysTypes } from "@/constants/workingDays";
import DateRangeField from "@/views/daily_report/components/DateRangeField.vue";

const { t } = useI18n();
const hasSubmitted = ref(false);

const props = defineProps({
  nonWorkingDay: {
    type: Object as PropType<NonWorkingDay>,
    required: false,
    default: () => ({}), // Default to an empty object
  },
});

const schema = computed(() =>
  yup.object({
    name: yup.string().required(t("admin.durations_project_settings.validation_name_empty")),
    date: yup
      .date()
      .nullable()
      .when("type", {
        is: "public_holiday",
        then: (schema) =>
          schema.required(t("admin.durations_project_settings.validation_date_required")),
        otherwise: (schema) => schema.notRequired(),
      }),
    range: yup
      .array()
      .of(yup.date().nullable())
      .when("type", {
        is: (val: string) => val !== "public_holiday",
        then: (schema) =>
          schema
            .required(t("admin.durations_project_settings.validation_date_required"))
            .test(
              "is-valid-range",
              t("admin.durations_project_settings.validation_date_required"),
              (value: (Date | null | undefined)[]) => {
                // Check that range is not empty and has exactly two dates
                return value.length === 2 && value.every((date) => date != null);
              },
            ),
        otherwise: (schema) => schema.notRequired(),
      }),
    type: yup.string().required(),
    disturbance_type: yup
      .string()
      .required()
      .when("type", {
        is: (val: string) => val === "disturbance",
        then: (schema) =>
          schema.required(t("admin.durations_project_settings.validation_disturbance_type_empty")),
        otherwise: (schema) => schema.notRequired(),
      }),
    is_critical: yup.boolean().required(),
    disturbance_description: yup.string().nullable().default(null),
  }),
);

const initialValues = computed(() => {
  if (props.nonWorkingDay && props?.nonWorkingDay?.type) {
    return {
      ...props.nonWorkingDay,
      type: props.nonWorkingDay.type,
      date: props.nonWorkingDay?.type === "public_holiday" ? props.nonWorkingDay?.start_date : null,
      range:
        props.nonWorkingDay?.type === "public_holiday"
          ? [props.nonWorkingDay?.start_date, props.nonWorkingDay?.start_date]
          : [props.nonWorkingDay?.start_date, props.nonWorkingDay?.end_date],
      disturbance_type:
        props.nonWorkingDay?.type === "disturbance" ? props.nonWorkingDay?.disturbance_type : null,
    };
  }
  return { type: "", is_critical: false, disturbance_type: "" };
});

const formRef = ref<FormContext | null>(null);
const showTypeField = ref(!props.nonWorkingDay);

const emit = defineEmits(["commit", "close"]);

const handleSubmit: SubmissionHandler = (values) => {
  const { type, range, date, is_critical, disturbance_type, disturbance_description, ...rest } =
    values;
  const holiday = {
    ...rest,
    is_critical: type !== "disturbance" ? true : is_critical,
    type: type,
    start_date: type === "public_holiday" ? date : range[0],
    end_date: type === "public_holiday" ? date : range[1],
    disturbance_type: disturbance_type || null,
    disturbance_description: disturbance_description || null,
  };
  emit("commit", holiday);
};
const typeOptions = computed(() => {
  return nonWorkingDaysTypes.map((item) => ({
    value: item,
    name: t(`admin.durations_project_settings.non_working_day_type_labels.${item}`),
  }));
});

const disturbanceOptions = computed(() =>
  disturbances.map((disturbance) => ({
    name: t(`disturbances.type.${disturbance}`) || disturbance,
    value: disturbance,
  })),
);

const toggleTypeSelection = () => {
  showTypeField.value = !showTypeField.value;
};

watch(
  () => formRef.value?.values?.type,
  (newValue) => {
    if (!newValue) {
      hasSubmitted.value = false;
      showTypeField.value = true;
    }
  },
);
</script>
