import { EventModel, SchedulerPro } from "@bryntum/schedulerpro";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { isAxiosError } from "axios";
import { addDays, differenceInHours, eachDayOfInterval, format, parse } from "date-fns";
import * as fabric from "fabric";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { OutagesByRange } from "shared/types/Camera";
import CameraRepository from "../repositories/CameraRepository";
import PlannerRepository from "../repositories/PlannerRepository";
import logger from "../services/logger";
import { CriticalPath, CriticalPathLayout, CriticalPathToUpdate } from "../types/CriticalPath";
import {
  ActualEvent,
  ActualEventChanges,
  MergedPlannerItem,
  PlanConfig,
  ProjectPlannerStat,
  ReadyToCloseActualEvent,
} from "../types/Plan";
import { ShortenedProcessWithTags } from "../types/Process";
import { ProjectDurationSettings } from "../types/ProjectDurationSettings";
import { SectionMask } from "../types/SectionMask";
import { Stream } from "../types/Stream";
import {
  createLayout,
  runSimulatedAnnealingOnLayout,
} from "../views/critical_path/criticalPathLayout";
import {
  useIsWorkingDay,
  useNonWorkingDaysByDaySet,
  useTodayMinusOneWorkingDay,
} from "./durations";
import { useHierarchyTags } from "./hierarchyTags";
import { useCurrentCustomerName, useCurrentSiteId } from "./project";
import { useProjectDurationSettings } from "./projectDurationSettings";
import { useCustomToast } from "./toast";

export const useHolidaysInScheduler = () => {
  const { projectDurationSettings } = useProjectDurationSettings();

  return (schedulerPro: SchedulerPro) => {
    const { timeRangeStore } = schedulerPro.project;

    timeRangeStore.remove(
      timeRangeStore.allRecords.filter(
        (item) => typeof item.id === "string" && item.id.startsWith("holiday_"),
      ),
    );

    if (projectDurationSettings.value) {
      const items = projectDurationSettings.value.non_working_days.map((non_working_day) => {
        const id = `holiday_${
          non_working_day.name
        }_${non_working_day.start_date.toISOString()}_${non_working_day.end_date.toISOString()}`;

        let className = `planner-holiday-timerange planner-holiday-timerange-type-${non_working_day.type}`;

        if (non_working_day.is_critical) {
          className += " planner-holiday-timerange-critical";
        }

        return {
          id: id,
          name: non_working_day.name,
          startDate: non_working_day.start_date,
          endDate: addDays(non_working_day.end_date, 1),
          cls: className,
        };
      });
      timeRangeStore.add(items);
    }
  };
};

export const useNonWorkingDaysInScheduler = () => {
  const { projectDurationSettings } = useProjectDurationSettings();

  return (schedulerPro: SchedulerPro) => {
    const { timeRangeStore } = schedulerPro.project;

    timeRangeStore.remove(
      timeRangeStore.allRecords.filter(
        (item) => typeof item.id === "string" && item.id.startsWith("non_working_day_"),
      ),
    );

    if (projectDurationSettings.value) {
      const dayMap: Record<keyof ProjectDurationSettings["working_hours"], string> = {
        Mon: "MO",
        Tue: "TU",
        Wed: "WE",
        Thu: "TH",
        Fri: "FR",
        Sat: "SA",
        Sun: "SU",
      };
      const items = Object.entries(projectDurationSettings.value.working_hours)
        .filter(([, value]) => !value.start_time && !value.end_time)
        .map((entry) => {
          const [key] = entry;
          return {
            id: `non_working_day_${key}`,
            recurrenceRule: `FREQ=WEEKLY;BYDAY=${
              dayMap[key as keyof ProjectDurationSettings["working_hours"]]
            };`,
            startDate: "1970-01-04 00:00",
            endDate: "1970-01-05 00:00",
          };
        });
      timeRangeStore.add(items);
    }
  };
};

const getEarliestStartAndLatestEnd = (events: EventModel[]) => {
  let start: Date | null = null;
  let end: Date | null = null;
  for (const event of events) {
    const eventStart = event.startDate as Date;
    const eventEnd = event.endDate as Date;
    if (!start || eventStart < start) {
      start = eventStart;
    }
    if (!end || eventEnd > end) {
      end = eventEnd;
    }
  }
  return [start, end] as const;
};

export const useOutages = (streams: Ref<Stream[]>, isProjectCompleted: boolean) => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const nonWorkingDaysByDaySet = useNonWorkingDaysByDaySet();
  const isWorkingDay = useIsWorkingDay();
  const todayMinusOneWorkingDay = useTodayMinusOneWorkingDay();

  const streamsByCameraId: Ref<Record<string, Stream>> = computed(() =>
    streams.value.reduce((acc: Record<string, Stream>, stream: Stream) => {
      acc[stream.camera_id.toString()] = stream;
      return acc;
    }, {}),
  );

  const openedEventIds = ref(new Set<string>());

  return (schedulerPro: SchedulerPro, event: EventModel) => {
    const { resourceTimeRangeStore, eventStore } = schedulerPro.project;

    const startDate = event.startDate as Date;
    const endDate = event.endDate as Date;
    const formattedEndDate = format(endDate, "yyyy-MM-dd");
    const resourceId = event.resourceId as string;
    const fullEventId = `${schedulerPro.id}_${event.id}`;

    if (openedEventIds.value.has(fullEventId)) {
      const currentOutageTimeRanges = resourceTimeRangeStore.allRecords.filter(
        (item) =>
          typeof item.id === "string" &&
          item.id.startsWith("outage_") &&
          item.getData("resourceId") === resourceId,
      );
      resourceTimeRangeStore.remove(currentOutageTimeRanges);
      event.resource.set("getOutagesFn", null);
      openedEventIds.value.delete(fullEventId);
      return;
    }

    openedEventIds.value.add(fullEventId);

    const loadOutagesEndDate = isProjectCompleted ? endDate : todayMinusOneWorkingDay.value;
    const promises: Promise<unknown>[] = [
      CameraRepository.loadOutagesByRange(
        currentCustomerName,
        currentSiteId,
        startDate,
        loadOutagesEndDate,
      ),
      PlannerRepository.loadResourceCameraIds(
        currentCustomerName,
        currentSiteId,
        event.resource.getData("sourceId"),
      ),
    ];
    Promise.all(promises)
      .then((promiseResults) => {
        const [outages, cameraIds] = promiseResults as [OutagesByRange, string[]];
        if (!openedEventIds.value.has(fullEventId)) {
          return;
        }
        event.resource.set("getOutagesFn", (date: Date) => {
          if (!isWorkingDay.value(date)) {
            return [];
          }
          const key = format(date, "yyyy-MM-dd");
          const result = (outages[key] || [])
            .filter((item) => cameraIds.includes(item.camera_id))
            .map((item) => {
              const stream = streamsByCameraId.value[item.camera_id];
              return {
                ...item,
                camera_name: stream?.name || item.camera_id,
                aws_stream_id: stream?.aws_stream_id,
              };
            })
            .filter(
              (item) => (key > formattedEndDate && item.aws_stream_id) || key <= formattedEndDate,
            );
          result.sort(
            (a, b) =>
              a.camera_name.localeCompare(b.camera_name) ||
              a.start_time.getTime() - b.start_time.getTime(),
          );
          return result;
        });
        const processEventsByDay = (eventStore.allRecords as EventModel[])
          .filter((record) => record.getData("type")?.startsWith("process_"))
          .reduce((acc, event) => {
            eachDayOfInterval({
              start: event.startDate as Date,
              end: event.endDate as Date,
            }).forEach((day) => {
              const key = format(day, "yyyy-MM-dd");
              if (!(key in acc)) {
                acc[key] = [];
              }
              acc[key].push(event);
            });
            return acc;
          }, {} as Record<string, EventModel[]>);
        const items = Object.entries(outages)
          .map(([key, value]) => [key, value, parse(key, "yyyy-MM-dd", new Date())] as const)
          .filter(([key, value, date]) => {
            if (nonWorkingDaysByDaySet.value?.has(key) || !isWorkingDay.value(date)) {
              return false;
            }
            return value.some(
              (entry: {
                end_time: Date;
                start_time: Date;
                tml_end: Date;
                tml_start: Date;
                camera_id: string;
              }) => {
                const outageHours = differenceInHours(entry.end_time, entry.start_time);
                const tmlHours = differenceInHours(entry.tml_end, entry.tml_start);
                const factor = 0.5;
                const stream = streamsByCameraId.value[entry.camera_id];
                return (
                  cameraIds.includes(entry.camera_id) &&
                  outageHours > tmlHours * factor &&
                  ((key > formattedEndDate && stream?.aws_stream_id) || key <= formattedEndDate)
                );
              },
            );
          })
          .flatMap(([key, value, date]) => {
            const processEvents = processEventsByDay[key] || [];
            if (processEvents.length === 0) {
              return [
                {
                  id: `outage_${resourceId}_${key}`,
                  startDate: date,
                  endDate: addDays(date, 1),
                  resourceId,
                  cls: "planner-outage-timerange",
                },
              ];
            }
            const [startDate, endDate] = getEarliestStartAndLatestEnd(processEvents);
            if (!startDate || !endDate) {
              return [];
            }
            const hasBeforeStart = value
              .filter((entry: { camera_id: string }) => cameraIds.includes(entry.camera_id))
              .some((entry: { start_time: Date }) => entry.start_time < startDate);
            const hasAfterEnd = value
              .filter((entry: { camera_id: string }) => cameraIds.includes(entry.camera_id))
              .some((entry: { end_time: Date }) => entry.end_time > endDate);
            return [
              hasBeforeStart && {
                id: `outage_${resourceId}_${key}_start`,
                startDate: date,
                endDate: startDate,
                resourceId,
                cls: "planner-outage-timerange",
              },
              hasAfterEnd && {
                id: `outage_${resourceId}_${key}_end`,
                startDate: endDate,
                endDate: addDays(date, 1),
                resourceId,
                cls: "planner-outage-timerange",
              },
            ].filter((item) => item);
          });
        resourceTimeRangeStore.add(items);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };
};

export const usePlanConfig = (options?: Ref<{ enabled?: boolean }>) => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const { t } = useI18n();
  const {
    data: planConfig,
    isFetching,
    error,
    refetch,
  } = useQuery({
    queryKey: ["plan-config", customerName, siteId],
    queryFn: () => PlannerRepository.loadPlanConfig(customerName, siteId),
    useErrorBoundary: (error) => {
      if (!isAxiosError(error) || error?.response?.status !== 404) {
        logger.error(error);
        useCustomToast().error(t("analytics.planner.unable_to_load_plan"));
      }
      return false;
    },
    enabled: computed(() => (options?.value.enabled !== undefined ? options.value.enabled : true)),
  });

  return { planConfig, isLoading: isFetching, error, refetchPlanConfig: refetch };
};

export const useCreateNewPlanRevision = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: createNewPlanRevision,
    isLoading: isLoading,
    error,
  } = useMutation<
    PlanConfig,
    Error,
    { plan: Omit<PlanConfig, "planner_comments">; mergedPlannerItems: MergedPlannerItem[] }
  >({
    mutationFn: ({ plan, mergedPlannerItems }) =>
      PlannerRepository.createNewRevision(customerName, siteId, plan, mergedPlannerItems),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.planner.create_revision_error_message"));
      return false;
    },
    onSuccess: (newPlanConfig) => {
      queryClient.setQueryData(["plan-config", customerName, siteId], newPlanConfig);
    },
  });

  return { createNewPlanRevision, isLoading, error };
};

export const useUpdateActualEvents = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const { t } = useI18n();
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateActualEvents,
    isLoading: isLoading,
    error,
  } = useMutation<
    {
      added_ids: {
        _id: string;
        db_id: string;
      }[];
    },
    Error,
    ActualEventChanges
  >({
    mutationFn: (changes) =>
      PlannerRepository.saveActualEventChanges(customerName, siteId, changes),
    onSuccess: (result, changes) => {
      const modifiedById = changes.modified.reduce((acc, item) => {
        acc[item._id] = item;
        return acc;
      }, {} as Record<string, (typeof changes.modified)[0]>);

      const closedIds = new Set<string>([
        ...changes.modified.filter((change) => change.end).map((change) => change._id),
        ...changes.removed.map((change) => change._id),
      ]);

      const removedIds = new Set<string>(changes.removed.map((change) => change._id));

      const addedIdsMap = result.added_ids.reduce((acc, item) => {
        acc[item._id] = item.db_id;
        return acc;
      }, {} as Record<string, string>);

      queryClient.setQueryData(["plan-config", customerName, siteId], (planConfig?: PlanConfig) => {
        if (!planConfig) {
          return undefined;
        }
        return {
          ...planConfig,
          actual_events: [
            ...planConfig.actual_events
              .map((actualEvent) =>
                actualEvent._id in modifiedById
                  ? { ...actualEvent, ...modifiedById[actualEvent._id] }
                  : actualEvent,
              )
              .filter((actualEvent) => !removedIds.has(actualEvent._id)),
            ...changes.added.map(
              (actualEvent) =>
                ({
                  ...actualEvent,
                  _id: addedIdsMap[actualEvent._id],
                } as ActualEvent),
            ),
          ],
        };
      });

      queryClient.setQueryData(
        ["actual-events-ready-to-close", customerName, siteId],
        (actualEventsReadyToClose: ReadyToCloseActualEvent[] | undefined) =>
          actualEventsReadyToClose &&
          actualEventsReadyToClose.filter((event) => !closedIds.has(event._id)),
      );

      queryClient.setQueryData(
        ["project-planner-stats"],
        (stats: ProjectPlannerStat[] | undefined) =>
          stats?.map((stat) =>
            stat.customer_name === customerName && stat.site_id === siteId
              ? {
                  ...stat,
                  ready_to_close: stat.ready_to_close.filter((item) => !closedIds.has(item._id)),
                }
              : stat,
          ),
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.planner.unable_to_save_changes"));
      return false;
    },
  });

  return { updateActualEvents, isLoading, error };
};

export const useCriticalPath = (options?: Ref<{ enabled?: boolean }>) => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const { t } = useI18n();

  const {
    data: criticalPath,
    isLoading,
    error,
  } = useQuery<CriticalPath>({
    queryKey: ["critical-path", customerName, siteId],
    queryFn: () => PlannerRepository.loadCriticalPath(customerName, siteId),
    useErrorBoundary: (error) => {
      if (!isAxiosError(error) || error.response?.status !== 404) {
        logger.error(error);
        useCustomToast().error(t("analytics.critical_path.unable_to_load"));
      }
      return false;
    },
    enabled: computed(() => (options?.value.enabled !== undefined ? options.value.enabled : true)),
  });

  return { criticalPath, isLoading, error };
};

export const useCriticalPathWithLayout = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();

  const { criticalPath, isLoading: isCriticalPathLoading } = useCriticalPath();
  const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();
  const queryClient = useQueryClient();

  const { data: layout } = useQuery<CriticalPathLayout | null>(
    ["critical-path-layout", customerName, siteId],
    {
      initialData: null,
    },
  );

  const finalLayout = computed(() => layout.value ?? null);
  const isLoading = computed(() => isCriticalPathLoading.value || areHierarchyTagsLoading.value);

  const propagateLayoutValue = () => {
    if (layout.value) {
      return;
    }
    if (criticalPath.value && hierarchyTags.value.length > 0) {
      const initialLayout = createLayout(criticalPath.value, hierarchyTags.value);
      const layout = runSimulatedAnnealingOnLayout(initialLayout, criticalPath.value);
      queryClient.setQueryData(["critical-path-layout", customerName, siteId], layout);
    } else {
      queryClient.setQueryData(["critical-path-layout", customerName, siteId], null);
    }
  };

  watch([criticalPath, hierarchyTags], () => {
    propagateLayoutValue();
  });

  onMounted(() => {
    propagateLayoutValue();
  });

  return { criticalPath, isLoading, layout: finalLayout };
};

export const useUpdateCriticalPath = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: updateCriticalPath,
    isLoading: isLoading,
    error,
  } = useMutation<CriticalPath, Error, CriticalPathToUpdate>({
    mutationFn: (payload) => PlannerRepository.updateCriticalPath(customerName, siteId, payload),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.critical_path.unable_to_update"));
      return false;
    },
    onSuccess: (updatedCriticalPath) => {
      queryClient.setQueryData(["critical-path-layout", customerName, siteId], null);
      queryClient.setQueryData(["critical-path", customerName, siteId], updatedCriticalPath);
    },
  });

  return { updateCriticalPath, isLoading, error };
};

export const mountSectionMaskOverlayOnGantt = (
  canvasId: string,
  event: { currentElement?: HTMLDivElement; eventRecord?: EventModel },
  sectionMasks: SectionMask[] = [],
) => {
  const process = event.eventRecord?.getData("processes")?.[0] as ShortenedProcessWithTags;
  if (!process) {
    return null;
  }

  const image = event.currentElement?.querySelector("img");
  let sectionMask = event.eventRecord?.getData("sectionMask") as SectionMask;
  if (!sectionMask && sectionMasks) {
    sectionMask = sectionMasks.find((mask) => {
      return mask._id === process.section_mask_mapping.id;
    }) as SectionMask;
  }

  if (!sectionMask || !image) {
    return null;
  }

  try {
    const canvas = new fabric.StaticCanvas(canvasId);

    image.onload = () => {
      if (canvas.getObjects()?.length) {
        return null;
      }

      canvas.setDimensions({
        width: image.clientWidth,
        height: image.clientHeight,
      });

      const opacity = 0.2;
      const fillColor = `${sectionMask.color.slice(0, -4)}${opacity})`;

      const maskPath = new fabric.Path(
        sectionMask.mask
          .flat()
          .map(([x, y], index) => {
            return `${index === 0 ? "M" : "L"} ${x * canvas.width} ${y * canvas.height}`;
          })
          .join(" ") + " Z",
        {
          fill: fillColor,
          stroke: sectionMask.color,
          strokeWidth: 2,
        },
      );

      const text = new fabric.Text(
        `${process.section_mask_mapping.level_name} - ${process.section_mask_mapping.section_name}`,
        {
          top: 5,
          fontSize: 11,
          strokeWidth: 3,
          stroke: "#000",
          fill: "#fff",
          fontFamily: "arial",
          fontWeight: "bold",
          paintFirst: "stroke",
        },
      );

      text.set({
        left: canvas.width - text.width - 10,
      });

      canvas.add(maskPath);
      canvas.add(text);
      canvas.renderAll();
    };

    return canvas;
  } catch (error) {
    logger.error(error);

    return null;
  }
};
