<template>
  <div
    class="flex-1 flex border-t overflow-auto xl:overflow-hidden flex-col xl:flex-row pt-4 xl:pt-0"
  >
    <div class="xl:hidden md:px-8 px-3">
      <select
        id="tabs"
        name="tabs"
        class="w-auto oai-inputs"
        @change="handleItemClick(($event?.target as HTMLInputElement)?.value as string)"
      >
        <option
          v-for="tab in tabs"
          :key="tab.name"
          :value="tab.name"
          :selected="currentTab.name === tab.name"
        >
          {{ tab.label }}
        </option>
      </select>
    </div>
    <div
      class="border-r w-[250px] min-h-screen bg-white hidden xl:block text-xs text-gray-600 font-medium"
    >
      <div
        :class="[
          'px-5 md:px-8 py-2 lg:p-5 border-l-4 cursor-pointer hover:text-oaiGray-900 border-b text-gray-600 font-medium text-xs flex items-center justify-between break-words',
          currentTab === tab ? 'border-l-yellow text-oaiGray-900 bg-gray-50' : '',
        ]"
        v-for="tab in tabs"
        :key="tab.name"
        @click="handleItemClick(tab.name)"
      >
        {{ tab.label }}
      </div>
    </div>
    <div class="flex-1 md:p-8 p-4 xl:overflow-auto">
      <component
        :is="currentTab.content"
        :organization="organization"
        :organizationsByParentId="organizationsByParentId"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type Component, computed, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useHasOrganizationPermission } from "shared/composables/organization";
import { Organization } from "shared/types/Organization";
import OrganizationTabGeneral from "@/views/organizations/components/OrganizationTabGeneral.vue";
import OrganizationTabProjects from "@/views/organizations/components/OrganizationTabProjects.vue";
import OrganizationTabUsers from "@/views/organizations/components/OrganizationTabUsers.vue";

type Tab = {
  name: string;
  label: string;
  content: Component;
};

const props = defineProps<{
  organization: Organization;
  organizationsByParentId: Record<string, Organization[] | undefined>;
}>();

const { t } = useI18n();

const route = useRoute();
const router = useRouter();

const hasOrganizationBasePermission = useHasOrganizationPermission(
  "organization_base",
  props.organization._id,
);

const tabs = computed(
  () =>
    [
      {
        name: "general",
        label: t("organizations.tab_general"),
        content: OrganizationTabGeneral,
      },
      hasOrganizationBasePermission.value && {
        name: "projects",
        label: t("organizations.projects"),
        content: OrganizationTabProjects,
      },
      hasOrganizationBasePermission.value && {
        name: "users",
        label: t("organizations.users"),
        content: OrganizationTabUsers,
      },
    ].filter((tab) => tab) as Tab[],
);

const currentTab = computed(
  () => tabs.value.find((tab) => tab.name === route.params.tab) || tabs.value[0],
);

const handleItemClick = (tabName: string) => {
  router.push({
    params: { ...route.params, tab: tabName },
    query: route.query,
  });
};
</script>
