<template>
  <div
    class="grid gap-4 items-center hover:bg-gray-100 px-4 relative h-[50px]"
    :class="cls"
    :style="{
      gridTemplateColumns: '1fr 1fr 1fr 1fr 40px',
      paddingLeft: `${calculateIndentation(indentation)}px`,
    }"
  >
    <div
      v-if="companyType && showCompanyTypeBadge"
      class="absolute -top-[9px] left-0 text-[10px] font-semibold bg-white rounded px-2 border truncate"
      :style="{ marginLeft: `${calculateIndentation(indentation)}px` }"
    >
      {{ t(`admin.project_participants.company_types.${companyType}`) }}
    </div>
    <div
      v-if="user.user_status !== 'confirmed'"
      class="inline-flex items-center gap-x-1.5 rounded-full bg-orange-100 px-2 py-1 text-xs font-medium text-orange-600 max-w-max min-w-0"
    >
      <svg class="h-1.5 w-1.5 fill-orange-400" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx="3" cy="3" r="3" />
      </svg>
      <span class="truncate">
        {{ t("admin.project_participants.pending_invite") }}
      </span>
    </div>
    <div v-if="user.user_status === 'confirmed'" class="truncate">
      {{ user.name }}
    </div>
    <div class="text-gray-500 text-xs truncate">{{ user.email }}</div>
    <div class="text-gray-500 text-xs truncate">
      {{ props.user.role ? t(`admin.project_participants.user_roles.${props.user.role}`) : "" }}
    </div>
    <div class="flex items-center justify-end gap-1 min-w-0">
      <div
        v-for="permission in userGroups"
        :key="permission"
        class="rounded-full px-2 text-xs font-semibold truncate"
        :class="permissionLabelsColors[permission] ?? 'bg-yellow-300 text-yellow-800'"
      >
        {{ $t(`permissions.${permission}`) }}
      </div>
    </div>
    <div class="flex gap-2 justify-end">
      <PencilIcon
        v-if="canEdit"
        class="w-4 h-4 shrink-0 text-gray-400 cursor-pointer hover:text-gray-500"
        @click="isEditOrganizationPermissionModalOpen = true"
      />
      <TrashIcon
        v-if="canEdit"
        class="w-4 h-4 shrink-0 text-gray-400 cursor-pointer hover:text-gray-500"
        @click="isDeleteUserModalOpen = true"
      />
    </div>
    <RemoveUserModal
      v-if="isDeleteUserModalOpen"
      :user="user"
      :organizationId="organization._id"
      @close="isDeleteUserModalOpen = false"
    />
    <EditOrganizationPermissionModal
      v-if="isEditOrganizationPermissionModalOpen"
      :user="user"
      :organizationId="organization._id"
      @close="isEditOrganizationPermissionModalOpen = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { TrashIcon, PencilIcon } from "@heroicons/vue/24/solid";
import { computed, defineProps, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Organization } from "shared/types/Organization";
import { User, UserOrganizationPermissionGroup } from "shared/types/User";
import EditOrganizationPermissionModal from "@/components/users/EditOrganizationPermissionModal.vue";
import RemoveUserModal from "@/components/users/RemoveUserModal.vue";
import { calculateIndentation } from "@/views/organizations/organizations";

const permissionLabelsColors: Partial<Record<UserOrganizationPermissionGroup, string>> = {
  organization_admin: "bg-green-100 text-green-800",
};

const permissionSortOrder: Record<UserOrganizationPermissionGroup, number> = {
  organization_base: 1,
  organization_admin: 2,
};

const props = withDefaults(
  defineProps<{
    user: User;
    organization: Organization;
    canEdit: boolean;
    showCompanyTypeBadge: boolean;
    indentation?: number;
    cls?: string;
  }>(),
  { indentation: 0 },
);

const { t } = useI18n();

const isDeleteUserModalOpen = ref(false);
const isEditOrganizationPermissionModalOpen = ref(false);

const userGroups = computed(() => {
  const userOrganization = props.user.organizations.find(
    (user) => user.organization_id === props.organization._id,
  );
  if (!userOrganization) {
    return [];
  }
  return userOrganization.explicit_groups
    .filter((group) => group !== "organization_base")
    .sort((a, b) => permissionSortOrder[a] - permissionSortOrder[b]);
});

const companyType = computed(
  () =>
    props.user.organizations.find(
      (organization) => organization.organization_id === props.organization._id,
    )?.company_type,
);
</script>
