import { useQuery } from "@tanstack/vue-query";
import { computed } from "vue";
import {
  useCurrentCustomerName,
  useCurrentSiteId,
  useHasPermission,
} from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import HierarchyRepository from "shared/repositories/HierarchyRepository";
import logger from "shared/services/logger";
import { HierarchyTagStore } from "shared/types/HierarchyTag";

export const useAllHierarchyTags = () => {
  const { data, isLoading } = useQuery<HierarchyTagStore[]>({
    queryKey: ["hierarchy-tags-all"],
    queryFn: () => HierarchyRepository.loadAllHierarchyData(),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load all hierarchy tags");
      return false;
    },
  });

  return { hierarchyTags: data, areHierarchyTagsLoading: isLoading };
};

export const useHierarchyTags = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const hasPermission = useHasPermission(["pct", "processes_user"]);

  const { data, isLoading } = useQuery<HierarchyTagStore[]>({
    queryKey: ["hierarchy-tags", customerName, siteId],
    queryFn: () => {
      if (hasPermission) {
        return HierarchyRepository.loadHierarchyTags(customerName, siteId);
      }
      return [];
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });

  const hierarchyTags = computed(
    () => data.value?.slice().sort((a, b) => a.number - b.number) || [],
  );

  return { hierarchyTags, isLoading };
};
