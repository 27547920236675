<template>
  <div
    class="flex justify-center items-center h-screen"
    v-if="isLoading && !store.state.isSessionExpired"
  >
    <LoadingSpinner />
  </div>
  <div id="wrapper" v-if="!isLoading && !store.state.isSessionExpired">
    <router-view :key="route.path" />
  </div>
  <ConfirmationModal />
  <SessionExpired v-if="store.state.isSessionExpired" />
  <div id="tooltip"></div>
  <DashboardPreloader
    v-if="authResolved && shouldPreloadDashboard"
    @ready="dashboardPreloaded = true"
  />
</template>

<script lang="ts" setup>
import "video.js/dist/video-js.css";
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import ConfirmationModal from "shared/components/modals/ConfirmationModal.vue";
import SessionExpired from "shared/components/other/SessionExpired.vue";
import { useSaveReturnUrl } from "shared/composables/auth";
import logger from "shared/services/logger";
import "@/assets/styles/custom.css";
import { useRefreshSession } from "@/composables/auth";
import DashboardPreloader from "@/views/dashboard/components/DashboardPreloader.vue";

const authResolved = ref(false);
const dashboardPreloaded = ref(false);
const shouldPreloadDashboard = ref(false);

const router = useRouter();
const route = useRoute();
const store = useStore();

const refreshSession = useRefreshSession();
const saveReturnUrl = useSaveReturnUrl();

const isLoading = computed(() => !authResolved.value || !dashboardPreloaded.value);

onMounted(async () => {
  let session: unknown | undefined = undefined;
  try {
    session = await refreshSession();
  } catch (error) {
    logger.error("Unable to refresh session", error as Error);
  } finally {
    await router.isReady();
    shouldPreloadDashboard.value = !!session && route.name === "ProjectOverview";
    dashboardPreloaded.value = !shouldPreloadDashboard.value;
    if (!session) {
      if (route.meta.requireLogin) {
        saveReturnUrl();

        router.replace("/log-in");
      }
    }
    setTimeout(() => {
      authResolved.value = true;
    }, 0);
  }
});
</script>
