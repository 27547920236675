<template>
  <div class="flex flex-col">
    <div class="oaiCriticalPathGraphNodeHoverItem border-b">
      <div />
      <div
        class="text-white rounded-lg px-2 py-0.5 truncate w-min max-w-full"
        :style="{ backgroundColor: '#bccfcc' }"
      >
        {{ t("analytics.critical_path.planned_end") }}
      </div>
      <div class="text-white rounded-lg px-2 py-0.5 truncate w-min bg-blue-600 max-w-full">
        {{ t("analytics.critical_path.projected_end_abbreviated") }}
      </div>
      <div>{{ t("analytics.critical_path.working_days") }}</div>
    </div>
    <div
      class="oaiCriticalPathGraphNodeHoverItem font-semibold"
      v-if="deltaForNode.plannedEvent && deltaForNode.projectedEndDate"
    >
      <div class="py-0.5">{{ t("time.all_time") }}</div>
      <div class="py-0.5">{{ format(deltaForNode.plannedEvent.end, dateFormat) }}</div>
      <div class="py-0.5">{{ format(deltaForNode.projectedEndDate, dateFormat) }}</div>
      <div
        class="w-min rounded-full px-2 py-0.5 whitespace-nowrap"
        :class="differenceWithBackgroundClass"
      >
        {{ formattedDelta }}
      </div>
    </div>
    <div
      v-if="criticalPathNode.state === 'in_progress'"
      class="flex flex-col w-[80%] mr-6 self-end"
    >
      <ArrowUpIcon class="w-6 h-6 bg-white self-center -mb-[8px] z-[100]" />
      <div
        class="flex-1 flex flex-col items-center gap-1 border-t border-l border-r text-center p-2"
      >
        <div class="mt-4 text-base font-semibold">{{ t("analytics.critical_path.max_from") }}</div>
        <div class="flex gap-[180px]">
          <ArrowUpRightIcon
            class="w-6 h-6"
            :class="isProjectedEndToday ? 'text-gray-600' : 'text-gray-400'"
          />
          <ArrowUpLeftIcon
            class="w-6 h-6"
            :class="
              isProjectedEndActualStartPlusPlannedWorkingDaysDate
                ? 'text-gray-600'
                : 'text-gray-400'
            "
          />
        </div>
      </div>
      <div class="flex">
        <div
          class="border-t border-l border-b p-4 flex-1 flex items-end"
          :class="isProjectedEndToday ? 'text-gray-600' : 'text-gray-400'"
        >
          <div class="w-[100px] truncate">{{ t("calendar.today") }}:</div>
          <div :class="isProjectedEndToday ? 'font-semibold' : ''" class="text-right flex-1">
            {{ format(now, dateFormat) }}
          </div>
        </div>
        <div
          class="flex flex-col gap-2 border p-4 flex-1"
          :class="
            isProjectedEndActualStartPlusPlannedWorkingDaysDate ? 'text-gray-600' : 'text-gray-400'
          "
        >
          <div class="flex">
            <div class="w-[100px] truncate pr-2">
              {{ t("analytics.critical_path.actual_start") }}:
            </div>
            <div class="w-[15px]" />
            <div class="flex-1 text-right">{{ formatDate(deltaForNode.actualStart) }}</div>
          </div>
          <div class="flex">
            <div class="w-[100px] truncate pr-2">
              <span class="capitalize">{{ t("analytics.planner.planned_event_name") }}</span>
              {{ t("working_day.working_day_abbrev") }}:
            </div>
            <div class="w-[15px]">{{ getSign(deltaForNode.plannedWorkingDays) }}</div>
            <div class="flex-1 text-right">
              {{ getAbs(deltaForNode.plannedWorkingDays) }}
              {{ t("working_day.working_day_abbrev") }}
            </div>
          </div>
          <div
            class="flex border-t pt-2"
            :class="isProjectedEndActualStartPlusPlannedWorkingDaysDate ? 'font-semibold' : ''"
          >
            <div class="w-[100px]" />
            <div class="w-[15px]">=</div>
            <div class="flex-1 text-right">
              {{ formatDate(deltaForNode.actualStartPlusPlannedWorkingDaysDate) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="criticalPathNode.state === 'not_started'"
      class="flex flex-col w-[80%] mr-6 self-end"
    >
      <ArrowUpIcon class="w-6 h-6 bg-white self-center -mb-[8px] z-[100]" />
      <div
        class="flex-1 flex flex-col items-center gap-1 border-t border-l border-r text-center p-2"
      >
        <div class="mt-4 text-base font-semibold">{{ t("analytics.critical_path.max_from") }}</div>
        <div class="flex gap-[180px]">
          <ArrowUpRightIcon
            class="w-6 h-6"
            :class="isProjectedEndNowPlusPlannedWorkingDaysDate ? 'text-gray-600' : 'text-gray-400'"
          />
          <ArrowUpLeftIcon
            class="w-6 h-6"
            :class="
              isProjectedEndPlannedEndPlusPreviousDeltaDate ? 'text-gray-600' : 'text-gray-400'
            "
          />
        </div>
      </div>
      <div class="flex">
        <div
          class="flex flex-col gap-2 border-l border-b border-t p-4 flex-1"
          :class="isProjectedEndNowPlusPlannedWorkingDaysDate ? 'text-gray-600' : 'text-gray-400'"
        >
          <div class="flex">
            <div class="w-[100px] truncate pr-2">{{ t("calendar.today") }}:</div>
            <div class="w-[15px]" />
            <div class="flex-1 text-right">{{ formatDate(now) }}</div>
          </div>
          <div class="flex">
            <div class="w-[100px] truncate pr-2">
              <span class="capitalize">{{ t("analytics.planner.planned_event_name") }}</span>
              {{ t("working_day.working_day_abbrev") }}:
            </div>
            <div class="w-[15px]">{{ getSign(deltaForNode.plannedWorkingDays) }}</div>
            <div class="flex-1 text-right">
              {{ getAbs(deltaForNode.plannedWorkingDays) }}
              {{ t("working_day.working_day_abbrev") }}
            </div>
          </div>
          <div
            class="flex border-t pt-2"
            :class="isProjectedEndNowPlusPlannedWorkingDaysDate ? 'font-semibold' : ''"
          >
            <div class="w-[100px]" />
            <div class="w-[15px]">=</div>
            <div class="flex-1 text-right">
              {{ formatDate(deltaForNode.nowPlusPlannedWorkingDaysDate) }}
            </div>
          </div>
        </div>
        <div
          class="flex flex-col gap-2 border p-4 flex-1"
          :class="isProjectedEndPlannedEndPlusPreviousDeltaDate ? 'text-gray-600' : 'text-gray-400'"
        >
          <div class="flex">
            <div class="w-[100px] truncate pr-2">
              {{ t("analytics.critical_path.planned_end") }}:
            </div>
            <div class="w-[15px]" />
            <div class="flex-1 text-right">{{ formatDate(deltaForNode.plannedEvent?.end) }}</div>
          </div>
          <div class="flex">
            <div class="w-[100px] truncate pr-2">
              {{ t("analytics.critical_path.previous_delta") }}:
            </div>
            <div class="w-[15px]">
              {{ getInvertedSign(deltaForNode.previousNodeDelta?.workingDays ?? null) }}
            </div>
            <div :class="previousNodeDeltaClass" class="flex-1 text-right">
              {{ getAbs(deltaForNode.previousNodeDelta?.workingDays ?? null) }}
              {{ t("working_day.working_day_abbrev") }}
            </div>
          </div>
          <div
            class="flex border-t pt-2"
            :class="isProjectedEndPlannedEndPlusPreviousDeltaDate ? 'font-semibold' : ''"
          >
            <div class="w-[100px]" />
            <div class="w-[15px]">=</div>
            <div class="flex-1 text-right">
              {{ formatDate(deltaForNode.plannedEndPlusPreviousDeltaDate) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ArrowUpIcon, ArrowUpLeftIcon, ArrowUpRightIcon } from "@heroicons/vue/24/outline";
import { format, isSameDay } from "date-fns";
import { computed, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { CriticalPathDelta, CriticalPathNodeEx } from "shared/types/CriticalPath";
import {
  useDeltaTextClass,
  useDeltaTextWithBackgroundClass,
  useFormattedDelta,
} from "../composables";

const dateFormat = "dd.MM.yyyy";

const props = defineProps<{
  criticalPathNode: CriticalPathNodeEx;
  deltaForNode: CriticalPathDelta;
}>();

const { t } = useI18n();

const now = new Date();

const delta = computed(() => props.deltaForNode.workingDays);
const differenceWithBackgroundClass = useDeltaTextWithBackgroundClass(delta);
const formattedDelta = useFormattedDelta(delta);

const formatDate = (date: Date | null | undefined) => {
  if (!date) {
    return "-";
  }
  return format(date, dateFormat);
};

const isProjectedEndToday = computed(
  () => props.deltaForNode.projectedEndDate && isSameDay(props.deltaForNode.projectedEndDate, now),
);

const isProjectedEndActualStartPlusPlannedWorkingDaysDate = computed(
  () =>
    props.deltaForNode.projectedEndDate &&
    props.deltaForNode.actualStartPlusPlannedWorkingDaysDate &&
    isSameDay(
      props.deltaForNode.projectedEndDate,
      props.deltaForNode.actualStartPlusPlannedWorkingDaysDate,
    ),
);

const isProjectedEndNowPlusPlannedWorkingDaysDate = computed(
  () =>
    props.deltaForNode.projectedEndDate &&
    props.deltaForNode.nowPlusPlannedWorkingDaysDate &&
    isSameDay(
      props.deltaForNode.projectedEndDate,
      props.deltaForNode.nowPlusPlannedWorkingDaysDate,
    ),
);

const isProjectedEndPlannedEndPlusPreviousDeltaDate = computed(
  () =>
    props.deltaForNode.projectedEndDate &&
    props.deltaForNode.plannedEndPlusPreviousDeltaDate &&
    isSameDay(
      props.deltaForNode.projectedEndDate,
      props.deltaForNode.plannedEndPlusPreviousDeltaDate,
    ),
);

const getAbs = (number: number | null) => (number !== null ? Math.abs(number) : "-");

const getSign = (number: number | null) => {
  if (number === null) {
    return "";
  }
  if (number < 0) {
    return "-";
  }
  return "+";
};

const getInvertedSign = (number: number | null) => {
  if (number === null) {
    return "";
  }
  return getSign(-number);
};

const previousNodeDeltaClass = useDeltaTextClass(
  computed(() => props.deltaForNode?.previousNodeDelta?.workingDays ?? null),
);
</script>
