<template>
  <Modal :open="true" @close="emit('close')" custom-cls="sm:w-full lg:w-[650px]">
    <template #title>
      {{ t("admin.project_participants.modal_invite_user.invite_users") }}
      <hr class="mt-2" />
    </template>
    <template #content>
      <Form
        @submit="handleSubmitInviteUsers"
        :initialValues="initialValues"
        :validationSchema="inviteSchema"
        class="flex flex-col gap-2 w-full"
        v-slot="{ isSubmitting, errors }"
      >
        <FieldArray name="users" v-slot="{ fields, push, remove }">
          <div v-for="(field, idx) in fields" :key="idx">
            <div class="flex items-center gap-4">
              <InputField
                :name="`users[${idx}].email`"
                :label="idx === 0 ? t('admin.project_participants.email') : ''"
                class="w-[250px]"
                :class="{
                  'mb-[19px]': errors[`users[${idx}].company_type`],
                }"
              />
              <SelectListField
                :name="`users[${idx}].company_type`"
                :label="idx === 0 ? t('admin.project_participants.company_type') : ''"
                :options="companyTypesList"
                :minWidth="250"
                :placeholder="`--
              ${t('form.select_option')} --`"
                :class="{
                  'mb-[19px]': errors[`users[${idx}].email`],
                }"
              />
              <button
                v-if="fields.length > 1"
                type="button"
                :class="{
                  'mt-5': idx === 0,
                  'mb-[19px]':
                    errors[`users[${idx}].email`] || errors[`users[${idx}].company_type`],
                }"
              >
                <XMarkIcon class="h-5 text-red-900 hover:text-red" @click="remove(idx)" />
              </button>
            </div>
          </div>
          <div>
            <button
              type="button"
              class="inline-flex items-center justify-center text-xs mt-3"
              @click="push({ email: '', company_type: '' })"
            >
              <PlusIcon class="h-4 w-4" aria-hidden="true" />
              {{ t("admin.project_participants.modal_invite_user.add_user") }}
            </button>
          </div>
        </FieldArray>
        <button
          type="submit"
          class="w-min self-end focus:outline-none flex justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-0 disabled:cursor-default disabled:bg-gray-300"
          :disabled="isSubmitting"
        >
          <LoadingSpinner v-if="isSubmitting" size="w-5 h-5" color="white" class="mr-2" />
          {{ t("admin.project_participants.modal_invite_user.invite") }}
        </button>
      </Form>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { PlusIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import { FieldArray, Form, SubmissionHandler } from "vee-validate";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import SelectListField from "shared/components/forms/SelectListField.vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { companyTypes } from "shared/constants/user";
import { User, UserCompanyType } from "shared/types/User";
import InputField from "@/components/forms/InputField.vue";
import { useBulkInviteUsersToProject, useBulkInviteUsersToOrganization } from "@/composables/user";

type InviteUsersFormValues = {
  users: { email: string; company_type: UserCompanyType }[];
};

const props = defineProps<{ existingUsers: User[]; organizationId?: string }>();
const emit = defineEmits<{
  (eventName: "close"): void;
}>();

const { t } = useI18n();
const { bulkInviteUsersToProject } = useBulkInviteUsersToProject();
const { bulkInviteUsersToOrganization } = useBulkInviteUsersToOrganization();

const companyTypesList = companyTypes.map((type) => ({
  value: type,
  name: t(`admin.project_participants.company_types.${type}`),
}));

const inviteSchema = yup.object().shape({
  users: yup.array().of(
    yup.object().shape({
      email: yup
        .string()
        .email(t("admin.project_participants.modal_invite_user.error_mail_invalid"))
        .required(t("admin.project_participants.modal_invite_user.error_mail_required")),
      company_type: yup.string().required(t("authentication.validation.validation_field_required")),
    }),
  ),
});

const initialValues = computed(() => ({
  users: [{ email: "", company_type: "" }],
}));

const handleSubmitInviteUsers: SubmissionHandler = async (genericObject) => {
  const existingUserEmails = new Set(props.existingUsers.map((user) => user.email));
  const values = genericObject as InviteUsersFormValues;
  const usersToInvite = values.users
    .map((item) => ({
      email: item.email.replace(/\s/g, "").toLowerCase(), // sanitize
      company_type: item.company_type,
    }))
    .filter((user) => !existingUserEmails.has(user.email));

  if (usersToInvite.length > 0) {
    if (props.organizationId) {
      await bulkInviteUsersToOrganization({
        usersToInvite,
        organizationId: props.organizationId,
      })
        .then(() => {
          emit("close");
        })
        .catch(() => undefined);
    } else {
      await bulkInviteUsersToProject(usersToInvite)
        .then(() => {
          emit("close");
        })
        .catch(() => undefined);
    }
  } else {
    emit("close");
  }
};
</script>
