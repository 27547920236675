<template>
  <div ref="containerRef" class="flex flex-col relative overflow-hidden">
    <div
      class="flex-1 whitespace-nowrap flex items-center justify-center text-transparent overflow-hidden font-semibold xl:mx-4 xl:mb-4"
      ref="valueRef"
    >
      {{ formattedValue }}<span class="oaiQueryValueUnit">{{ valueUnit }}</span>
    </div>
    <div
      ref="previousPeriodChangeRef"
      class="absolute text-gray-600 font-semibold left-0 right-0 flex justify-center"
      v-if="formattedPreviousPeriodChange"
    >
      <div
        class="grid grid-cols-1 text-center justify-items-center bg-white rounded-xl overflow-hidden oaiQueryValuePreviousPeriodValueContainer"
      >
        <div
          class="flex items-center gap-3 overflow-hidden row-start-1 col-start-1"
          :class="
            data.previous_period_value_invert_shown_info
              ? 'oaiQueryValuePreviousPeriodValue'
              : 'oaiQueryValuePreviousPeriodValueChange'
          "
        >
          <div
            ref="arrowRef"
            v-if="data && previousPeriodChange !== 0 && !data.previous_period_value_use_dot"
          />
          <div
            ref="dotRef"
            v-if="data && (previousPeriodChange === 0 || data.previous_period_value_use_dot)"
            class="bg-gray-400 rounded-full"
          />
          <div class="truncate">
            {{ formattedPreviousPeriodChange
            }}{{ data.previous_period_value_percentage_sign || "%" }}
          </div>
        </div>
        <div
          class="truncate row-start-1 col-start-1"
          :class="
            data.previous_period_value_invert_shown_info
              ? 'oaiQueryValuePreviousPeriodValueChange'
              : 'oaiQueryValuePreviousPeriodValue'
          "
        >
          <span v-if="data.previous_period_value_prefix" class="font-extralight">{{
            data.previous_period_value_prefix
          }}</span
          >{{ formattedPreviousPeriodValue }}{{ valueUnit }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import textFit from "textfit";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  QueryValueReportConfig,
  QueryValueReportFilters,
  QueryValueSingleValue,
} from "@/types/reports/PlotQueryValue";
import {
  calculatePreviousPeriodChange,
  precisionByMetric,
  valueUnitByMetric,
} from "@/views/reports/plots/query_value/queryValue";

const containerBaseHeight = 200;

const props = defineProps<{
  data: QueryValueSingleValue;
  config: QueryValueReportConfig;
  filters: QueryValueReportFilters;
  textColor?: string;
  showFullUnit?: boolean;
}>();

const { t, locale } = useI18n();

const containerRef = ref<HTMLDivElement | null>(null);
const valueRef = ref<HTMLDivElement | null>(null);
const previousPeriodChangeRef = ref<HTMLDivElement | null>(null);
const arrowRef = ref<HTMLDivElement | null>(null);
const dotRef = ref<HTMLDivElement | null>(null);

const valueUnit = computed(() =>
  valueUnitByMetric[props.config.metric](t, props.filters, props.config, props.showFullUnit),
);

const formatValue = (value: number | string | null) => {
  if (value === null || !Number.isFinite(value)) {
    return "-";
  }
  return value.toLocaleString(locale.value, {
    minimumFractionDigits: precisionByMetric[props.config.metric],
    maximumFractionDigits: precisionByMetric[props.config.metric],
    useGrouping: false,
  });
};

const formattedValue = computed(() => props.data.value_text || formatValue(props.data.value));

const formattedPreviousPeriodValue = computed(() => formatValue(props.data.previous_period_value));

const previousPeriodChange = computed(() => {
  if (typeof props.data.value !== "number") {
    return null;
  }
  return calculatePreviousPeriodChange(props.data.value, props.data.previous_period_value);
});

const formattedPreviousPeriodChange = computed(() => {
  if (!props.config.show_previous_period) {
    return null;
  }
  if (previousPeriodChange.value === null) {
    return "-";
  }
  return (previousPeriodChange.value * 100).toLocaleString(locale.value, {
    minimumFractionDigits: precisionByMetric[props.config.metric],
    maximumFractionDigits: precisionByMetric[props.config.metric],
    useGrouping: false,
  });
});

const getValueFontSize = () => {
  if (!valueRef.value) {
    return null;
  }
  const valueSpan = valueRef.value.children[0] as HTMLSpanElement | null;
  if (!valueSpan) {
    return null;
  }
  const fontSize = parseInt(valueSpan.style.fontSize.replace("px", ""));
  if (!Number.isFinite(fontSize)) {
    return null;
  }
  return fontSize;
};

const updateTextFitted = () => {
  const textFittedElement = valueRef.value?.querySelector(".textFitted") as HTMLSpanElement | null;
  const valueFontSize = getValueFontSize();
  if (!textFittedElement || valueFontSize === null) {
    return;
  }
  if (formattedPreviousPeriodChange.value) {
    textFittedElement.style.marginTop = `-${textFittedElement.clientHeight * 0.15}px`;
  }
  textFittedElement.style.lineHeight = `${valueFontSize}px`;
};

const updateUnitFontSize = () => {
  const unitElement = valueRef.value?.querySelector(".oaiQueryValueUnit") as HTMLDivElement | null;
  const valueFontSize = getValueFontSize();
  if (!unitElement || valueFontSize === null) {
    return;
  }
  unitElement.style.fontSize = `${valueFontSize * 0.5}px`;
};

const updatePreviousPeriodChangeDiv = () => {
  const valueFontSize = getValueFontSize();
  if (!valueRef.value || !previousPeriodChangeRef.value || valueFontSize === null) {
    return;
  }

  const textFittedElement = valueRef.value.querySelector(".textFitted") as HTMLSpanElement | null;
  if (!textFittedElement || !containerRef.value) {
    return;
  }

  const containerRect = containerRef.value.getBoundingClientRect();

  const fontSize = Math.max(
    Math.min((containerRect.height / containerBaseHeight) * 21, valueFontSize * 0.75),
    9,
  );
  previousPeriodChangeRef.value.style.fontSize = `${fontSize}px`;

  const textFittedElementRect = textFittedElement.getBoundingClientRect();

  previousPeriodChangeRef.value.style.bottom = `${containerRect.height * 0.05}px`;
  const child = previousPeriodChangeRef.value.firstChild as HTMLElement | null;
  if (child) {
    child.style.padding = `1px ${textFittedElementRect.width * 0.1}px`;
  }
};

const updateArrowRef = () => {
  const valueFontSize = getValueFontSize();
  if (
    !arrowRef.value ||
    valueFontSize === null ||
    !props.data ||
    previousPeriodChange.value === null ||
    !containerRef.value
  ) {
    return;
  }

  const containerRect = containerRef.value.getBoundingClientRect();

  const isGreen = props.data.previous_period_value_smaller_is_better
    ? previousPeriodChange.value < 0
    : previousPeriodChange.value > 0;

  const size = Math.max((containerRect.height / containerBaseHeight) * 15, 8);
  const color = isGreen ? "#56AF9C" : "#F87171";

  arrowRef.value.style.borderLeft = `${size - size * 0.3}px solid transparent`;
  arrowRef.value.style.borderRight = `${size - size * 0.3}px solid transparent`;

  const borderStyle = `${size}px solid ${color}`;
  if (isGreen) {
    arrowRef.value.style.borderBottom = borderStyle;
  } else {
    arrowRef.value.style.borderTop = borderStyle;
  }
};

const updateDotRef = () => {
  if (!dotRef.value || !containerRef.value || previousPeriodChange.value === null) {
    return;
  }

  const containerRect = containerRef.value.getBoundingClientRect();

  const size = Math.min(Math.max((containerRect.height / containerBaseHeight) * 15, 8), 20);
  dotRef.value.style.width = `${size}px`;
  dotRef.value.style.height = `${size}px`;
  dotRef.value.style.marginTop = size > 15 ? "2px" : "";

  if (props.data.previous_period_value !== 0) {
    const isGreen = props.data.previous_period_value_smaller_is_better
      ? previousPeriodChange.value < 0
      : previousPeriodChange.value > 0;
    dotRef.value.style.backgroundColor = isGreen ? "#56AF9C" : "#F87171";
  }
};

const updateTextFit = () => {
  if (!valueRef.value) {
    return;
  }
  textFit(valueRef.value, {
    maxFontSize: 120,
  });
  updateTextFitted();
  updateUnitFontSize();
  updatePreviousPeriodChangeDiv();
  updateArrowRef();
  updateDotRef();
};

const textColor = computed(() => props.textColor || "#000");

onMounted(() => {
  updateTextFit();
});
</script>

<style>
.textFitted {
  color: v-bind(textColor);
}

.oaiQueryValuePreviousPeriodValueChange {
  transition: transform 300ms, opacity 300ms;
}

.oaiQueryValuePreviousPeriodValue {
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 300ms, opacity 300ms;
}

.oaiQueryValuePreviousPeriodValueContainer:hover .oaiQueryValuePreviousPeriodValue {
  opacity: 1;
  transform: none;
}

.oaiQueryValuePreviousPeriodValueContainer:hover .oaiQueryValuePreviousPeriodValueChange {
  opacity: 0;
  transform: translateX(100%);
}
</style>
