<template>
  <Disclosure v-slot="{ open }" :defaultOpen="true">
    <DisclosureButton class="flex items-center py-1 pl-2 pr-1.5">
      <ChevronRightIcon :class="{ 'rotate-90': open }" class="w-5 h-5 shrink-0" />
      <div class="font-bold text-gray-900 px-1 truncate">
        {{ organization.name }}
      </div>
    </DisclosureButton>
    <DisclosurePanel>
      <div v-if="projectKeys.length > 0" class="ml-6">
        <OrganizationTabProjectsItem
          v-for="projectKey in projectKeys"
          :key="projectKey"
          :project="projectsByCustomerNameSiteId[projectKey] as Project"
          :organization="organization"
        />
      </div>
      <div
        v-if="children.length === 0 && projectKeys.length === 0"
        class="px-2 text-gray-400 text-xs py-1 ml-6"
      >
        {{ t("organizations.no_projects") }}
      </div>
      <div class="flex flex-col ml-4" v-if="children.length > 0">
        <OrganizationTabProjectsHierarchyItem
          v-for="childOrganization in children"
          :key="childOrganization._id"
          :organization="childOrganization"
          :organizationsByParentId="organizationsByParentId"
          :projectsByCustomerNameSiteId="projectsByCustomerNameSiteId"
        />
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { computed, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { Organization } from "shared/types/Organization";
import { Project } from "shared/types/Project";
import OrganizationTabProjectsHierarchyItem from "@/views/organizations/components/OrganizationTabProjectsHierarchyItem.vue";
import OrganizationTabProjectsItem from "@/views/organizations/components/OrganizationTabProjectsItem.vue";

const props = defineProps<{
  organization: Organization;
  organizationsByParentId: Record<string, Organization[] | undefined>;
  projectsByCustomerNameSiteId: Record<string, Project | undefined>;
}>();

const { t } = useI18n();

const children = computed(() => {
  const items = props.organizationsByParentId[props.organization._id] || [];
  return items.slice().sort((a, b) => a.name.localeCompare(b.name));
});

const projectKeys = computed(() =>
  props.organization.explicit_projects
    .map((project) => `${project.customer_name}_${project.site_id}`)
    .filter((projectKey) => props.projectsByCustomerNameSiteId[projectKey]),
);
</script>
