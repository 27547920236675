import { endOfDay, subDays } from "date-fns";
import durationService from "shared/services/durationService";
import {
  CriticalPathContext,
  CriticalPathDelta,
  CriticalPathDeltaForPath,
  CriticalPathEdgesBySourceAndTargetId,
  CriticalPathEx,
  CriticalPathNodeEx,
} from "shared/types/CriticalPath";
import { ActualEvent, PlannedEvent } from "shared/types/Plan";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import {
  calculateIsLastInCriticalPath,
  getEdgesBySourceAndTargetId,
} from "shared/views/critical_path/criticalPath";

type BaseCriticalPathDelta = Omit<
  CriticalPathDelta,
  "workingDays" | "projectedEndDate" | "endDate"
>;

const getNow = () => subDays(endOfDay(new Date()), 1);

const calculateDeltaForDates = (
  date1: Date,
  date2: Date,
  projectDurationSettings: ProjectDurationSettings,
) => {
  const { settings } = projectDurationSettings;
  const duration = durationService.calculateDuration(settings, date1, date2, {
    excludeStartDay: true,
  });
  return duration.workingDays;
};

export const calculateDeltaForPlannedAndActualEvent = (
  plannedEvent: PlannedEvent | null,
  actualEvent: ActualEvent | null,
  projectDurationSettings: ProjectDurationSettings,
) => {
  if (!plannedEvent) {
    return null;
  }
  if (!actualEvent || !actualEvent.end) {
    const now = getNow();
    if (plannedEvent.end <= now) {
      return calculateDeltaForDates(plannedEvent.end, now, projectDurationSettings);
    }
    return null;
  }
  return calculateDeltaForDates(plannedEvent.end, actualEvent.end, projectDurationSettings);
};

const getEarliestItem = <T>(
  items: T[],
  getValue: (item: T) => Date | number | null | undefined,
): T | null =>
  items.reduce((acc, item) => {
    const accValue = acc ? getValue(acc) : null;
    if (!accValue) {
      return item;
    }
    const currentValue = getValue(item);
    if (!currentValue) {
      return acc;
    }
    if (typeof currentValue === "number" || typeof accValue === "number") {
      return currentValue < accValue ? item : acc;
    }
    return currentValue.getTime() <= accValue.getTime() ? item : acc;
  }, null as T | null);

const getLatestItem = <T>(
  items: T[],
  getValue: (item: T) => Date | number | null | undefined,
): T | null =>
  items.reduce((acc, item) => {
    const accValue = acc ? getValue(acc) : null;
    if (!accValue) {
      return item;
    }
    const currentValue = getValue(item);
    if (!currentValue) {
      return acc;
    }
    if (typeof currentValue === "number" || typeof accValue === "number") {
      return currentValue > accValue ? item : acc;
    }
    return currentValue.getTime() > accValue.getTime() ? item : acc;
  }, null as T | null);

const getPlannedEventForCalculation = (node: CriticalPathNodeEx, context: CriticalPathContext) => {
  const { plannedEventsBySourceId } = context;

  const plannedEvents = node.component_source_ids
    .map((sourceId) => plannedEventsBySourceId[sourceId])
    .filter((event) => event) as PlannedEvent[];

  return getLatestItem(plannedEvents, (item) => item.end);
};

const getActualEventForCalculation = (node: CriticalPathNodeEx, context: CriticalPathContext) => {
  const { actualEventsBySourceId } = context;

  const finishedActualEvents = node.component_source_ids
    .map((sourceId) => actualEventsBySourceId[sourceId])
    .filter((event) => event?.end) as ActualEvent[];

  return getLatestItem(finishedActualEvents, (actualEvent) => actualEvent.end);
};

const getActualStartForNode = (node: CriticalPathNodeEx, context: CriticalPathContext) => {
  const { actualEventsBySourceId } = context;

  const actualEvents = node.component_source_ids
    .map((sourceId) => actualEventsBySourceId[sourceId])
    .filter((event) => event) as ActualEvent[];

  const earliestActualEvent = getEarliestItem(actualEvents, (actualEvent) => actualEvent.start);
  return earliestActualEvent?.start ?? null;
};

const getPlannedWorkingDaysForNode = (node: CriticalPathNodeEx, context: CriticalPathContext) => {
  const plannedEvents = node.component_source_ids
    .map((sourceId) => context.plannedEventsBySourceId[sourceId])
    .filter((event) => event) as PlannedEvent[];

  const earliestPlannedEvent = getEarliestItem(plannedEvents, (plannedEvent) => plannedEvent.start);
  const latestPlannedEvent = getLatestItem(plannedEvents, (plannedEvent) => plannedEvent.end);

  if (!earliestPlannedEvent || !latestPlannedEvent) {
    return null;
  }
  const duration = durationService.calculateDuration(
    context.projectDurationSettings.settings,
    earliestPlannedEvent.start,
    latestPlannedEvent.end,
  );
  return duration.workingDays;
};

const getActualWorkingDaysForNode = (node: CriticalPathNodeEx, context: CriticalPathContext) => {
  const actualEvents = node.component_source_ids
    .map((sourceId) => context.actualEventsBySourceId[sourceId])
    .filter((event) => event) as ActualEvent[];

  const earliestActualEvent = getEarliestItem(actualEvents, (actualEvent) => actualEvent.start);
  const latestActualEvent = getLatestItem(actualEvents, (actualEvent) => actualEvent.end);

  if (!earliestActualEvent) {
    return null;
  }
  const duration = durationService.calculateDuration(
    context.projectDurationSettings.settings,
    earliestActualEvent.start,
    latestActualEvent?.end || new Date(),
  );
  return duration.workingDays;
};

const getActiveNodeProjectedEndDate = (
  baseDelta: BaseCriticalPathDelta,
  context: CriticalPathContext,
) => {
  const now = new Date();
  if (!baseDelta.actualStart || baseDelta.plannedWorkingDays === null) {
    return { actualStartPlusPlannedWorkingDaysDate: null, projectedEndDate: now };
  }
  const actualStartPlusPlannedWorkingDaysDate =
    durationService.calculateEndDate(
      context.projectDurationSettings.settings,
      baseDelta.actualStart,
      baseDelta.plannedWorkingDays,
      { excludeStartDay: true },
    ) ?? null;
  if (actualStartPlusPlannedWorkingDaysDate && actualStartPlusPlannedWorkingDaysDate > now) {
    return {
      actualStartPlusPlannedWorkingDaysDate,
      projectedEndDate: actualStartPlusPlannedWorkingDaysDate,
    };
  }
  return { actualStartPlusPlannedWorkingDaysDate, projectedEndDate: now };
};

const getNotStartedNodeProjectedEndDate = (
  baseDelta: BaseCriticalPathDelta,
  previousNodeDelta: CriticalPathDelta | null,
  context: CriticalPathContext,
) => {
  const now = new Date();
  if (!baseDelta.plannedEvent || baseDelta.plannedWorkingDays === null) {
    return {
      nowPlusPlannedWorkingDaysDate: null,
      plannedEndPlusPreviousDeltaDate: null,
      projectedEndDate: now,
    };
  }
  const nowPlusPlannedWorkingDaysDate =
    durationService.calculateEndDate(
      context.projectDurationSettings.settings,
      now,
      baseDelta.plannedWorkingDays,
      { excludeStartDay: true },
    ) ?? null;
  const plannedEndPlusPreviousDeltaDate =
    durationService.calculateEndDate(
      context.projectDurationSettings.settings,
      baseDelta.plannedEvent.end,
      previousNodeDelta?.workingDays ?? 0,
      { excludeStartDay: true },
    ) ?? null;
  if (!nowPlusPlannedWorkingDaysDate && plannedEndPlusPreviousDeltaDate) {
    return {
      nowPlusPlannedWorkingDaysDate,
      plannedEndPlusPreviousDeltaDate,
      projectedEndDate: plannedEndPlusPreviousDeltaDate,
    };
  }
  if (nowPlusPlannedWorkingDaysDate && !plannedEndPlusPreviousDeltaDate) {
    return {
      nowPlusPlannedWorkingDaysDate,
      plannedEndPlusPreviousDeltaDate,
      projectedEndDate: nowPlusPlannedWorkingDaysDate,
    };
  }
  if (!nowPlusPlannedWorkingDaysDate || !plannedEndPlusPreviousDeltaDate) {
    return {
      nowPlusPlannedWorkingDaysDate,
      plannedEndPlusPreviousDeltaDate,
      projectedEndDate: now,
    };
  }
  if (nowPlusPlannedWorkingDaysDate > plannedEndPlusPreviousDeltaDate) {
    return {
      nowPlusPlannedWorkingDaysDate,
      plannedEndPlusPreviousDeltaDate,
      projectedEndDate: nowPlusPlannedWorkingDaysDate,
    };
  }
  return {
    nowPlusPlannedWorkingDaysDate,
    plannedEndPlusPreviousDeltaDate,
    projectedEndDate: plannedEndPlusPreviousDeltaDate,
  };
};

const calculateDeltaForNode = (
  node: CriticalPathNodeEx,
  previousNodeDeltas: CriticalPathDelta[],
  context: CriticalPathContext,
): CriticalPathDelta => {
  const plannedEvent = getPlannedEventForCalculation(node, context);
  const actualEvent = getActualEventForCalculation(node, context);
  const actualStart = getActualStartForNode(node, context);
  const plannedWorkingDays = getPlannedWorkingDaysForNode(node, context);
  const actualWorkingDays = getActualWorkingDaysForNode(node, context);

  const previousNodeDelta =
    getLatestItem(previousNodeDeltas, (nodeDelta) => nodeDelta.endDate) ??
    previousNodeDeltas[0] ??
    null;

  const baseDelta: Omit<CriticalPathDelta, "workingDays" | "projectedEndDate" | "endDate"> = {
    node,
    plannedEvent,
    actualEvent,
    actualStart,
    plannedWorkingDays,
    actualWorkingDays,
    previousNodeDelta,
    actualStartPlusPlannedWorkingDaysDate: null,
    nowPlusPlannedWorkingDaysDate: null,
    plannedEndPlusPreviousDeltaDate: null,
  };

  if (node.state === "finished") {
    const workingDays = calculateDeltaForPlannedAndActualEvent(
      plannedEvent,
      actualEvent,
      context.projectDurationSettings,
    );
    return {
      ...baseDelta,
      workingDays,
      projectedEndDate: null,
      endDate: actualEvent?.end ?? null,
    };
  }

  if (node.state === "in_progress") {
    const { actualStartPlusPlannedWorkingDaysDate, projectedEndDate } =
      getActiveNodeProjectedEndDate(baseDelta, context);
    const workingDays = plannedEvent
      ? calculateDeltaForDates(plannedEvent.end, projectedEndDate, context.projectDurationSettings)
      : 0;

    return {
      ...baseDelta,
      workingDays,
      projectedEndDate,
      endDate: projectedEndDate,
      actualStartPlusPlannedWorkingDaysDate,
    };
  }

  const { projectedEndDate, nowPlusPlannedWorkingDaysDate, plannedEndPlusPreviousDeltaDate } =
    getNotStartedNodeProjectedEndDate(baseDelta, previousNodeDelta, context);
  const workingDays = plannedEvent
    ? calculateDeltaForDates(plannedEvent.end, projectedEndDate, context.projectDurationSettings)
    : 0;

  return {
    ...baseDelta,
    workingDays:
      plannedEndPlusPreviousDeltaDate?.getTime() === projectedEndDate.getTime()
        ? previousNodeDelta?.workingDays ?? 0
        : workingDays,
    projectedEndDate,
    endDate: projectedEndDate,
    nowPlusPlannedWorkingDaysDate,
    plannedEndPlusPreviousDeltaDate,
  };
};

const getLastFinishedNodeId = (
  lastNodeId: string,
  nodeDeltas: CriticalPathDelta[],
  context: CriticalPathContext,
  edgesBySourceAndTargetId: CriticalPathEdgesBySourceAndTargetId,
) => {
  const { criticalPathNodesById } = context;
  const { edgesByTargetId } = edgesBySourceAndTargetId;
  const lastFinishedNodeIds = new Set<string>();

  const traverseNodes = (nodeIds: string[]) => {
    for (const nodeId of nodeIds) {
      const node = criticalPathNodesById[nodeId];
      if (!node) {
        throw new Error("Missing node in critical path");
      }
      const sourceIds = edgesByTargetId[nodeId] || [];
      if (node.sub_state === "at_least_one_part_finished" || sourceIds.length === 0) {
        lastFinishedNodeIds.add(node._id);
        continue;
      }
      traverseNodes(sourceIds);
    }
  };
  traverseNodes([lastNodeId]);

  const lastFinishedNodeDeltas = nodeDeltas.filter((nodeDelta) =>
    lastFinishedNodeIds.has(nodeDelta.node._id),
  );
  const lastFinishedNodeDelta = getEarliestItem(
    lastFinishedNodeDeltas,
    (delta) => delta.workingDays,
  );
  if (!lastFinishedNodeDelta) {
    return lastNodeId;
  }
  return lastFinishedNodeDelta.node._id;
};

const calculateDeltaForPath = (
  lastNode: CriticalPathNodeEx,
  context: CriticalPathContext,
  edgesBySourceAndTargetId: CriticalPathEdgesBySourceAndTargetId,
): CriticalPathDeltaForPath => {
  const { criticalPathNodesById } = context;
  const { edgesByTargetId } = edgesBySourceAndTargetId;
  const nodeDeltas: CriticalPathDelta[] = [];

  const traverseNodes = (nodeIds: string[]): CriticalPathDelta[] => {
    const createdNodeDeltas: CriticalPathDelta[] = [];
    for (const nodeId of nodeIds) {
      const node = criticalPathNodesById[nodeId];
      if (!node) {
        throw new Error("Missing node in critical path");
      }
      const sourceIds = edgesByTargetId[nodeId] || [];

      const previousNodeDeltas = traverseNodes(sourceIds);

      const nodeDelta = calculateDeltaForNode(node, previousNodeDeltas, context);
      nodeDeltas.push(nodeDelta);
      createdNodeDeltas.push(nodeDelta);
    }
    return createdNodeDeltas;
  };

  traverseNodes([lastNode._id]);

  const delta = nodeDeltas.find((nodeDelta) => nodeDelta.node._id === lastNode._id);
  if (!delta) {
    throw new Error("Last node missing from node deltas");
  }
  const lastFinishedNodeId = getLastFinishedNodeId(
    lastNode._id,
    nodeDeltas,
    context,
    edgesBySourceAndTargetId,
  );
  return {
    lastNodeId: lastNode._id,
    lastFinishedNodeId,
    delta,
    nodeDeltas,
  };
};

export const calculatePathDeltas = (
  criticalPath: CriticalPathEx,
  context: CriticalPathContext,
): CriticalPathDeltaForPath[] => {
  const edgesBySourceAndTargetId = getEdgesBySourceAndTargetId(criticalPath);
  return criticalPath.nodes
    .filter((node) => calculateIsLastInCriticalPath(node, edgesBySourceAndTargetId))
    .map((node) => calculateDeltaForPath(node, context, edgesBySourceAndTargetId));
};

export const getLastPlannedPath = (paths: CriticalPathDeltaForPath[]) =>
  getLatestItem(paths, (path) => path.delta.plannedEvent?.end);

export const calculateDeltaByPaths = (
  pathDeltas: CriticalPathDeltaForPath[],
  context: CriticalPathContext,
): CriticalPathDeltaForPath | null => {
  const lastPlannedPathDelta = getLastPlannedPath(pathDeltas);
  if (!lastPlannedPathDelta?.delta.plannedEvent?.end) {
    return null;
  }

  const lastEndPathDelta = getLatestItem(pathDeltas, (pathDelta) => pathDelta.delta.endDate);
  if (!lastEndPathDelta?.delta.endDate) {
    return null;
  }

  return {
    ...lastEndPathDelta,
    delta: {
      ...lastEndPathDelta.delta,
      plannedEvent: lastPlannedPathDelta.delta.plannedEvent,
      workingDays:
        lastPlannedPathDelta.lastNodeId !== lastEndPathDelta.lastNodeId
          ? calculateDeltaForDates(
              lastPlannedPathDelta.delta.plannedEvent.end,
              lastEndPathDelta.delta.endDate,
              context.projectDurationSettings,
            )
          : lastEndPathDelta.delta.workingDays,
    },
  };
};

export const calculateDelta = (criticalPath: CriticalPathEx, context: CriticalPathContext) => {
  const pathDeltas = calculatePathDeltas(criticalPath, context);
  return calculateDeltaByPaths(pathDeltas, context);
};
