import { eachDayOfInterval } from "date-fns";
import processDurationService from "@/services/processDurationService";
import { QueryValueContext, QueryValueSingleValue } from "@/types/reports/PlotQueryValue";
import { formatValueTextWithTotalDays } from "@/views/reports/plots/query_value/queryValue";

const getValueDateInterval = (context: QueryValueContext) => {
  const { reportSummaryContext } = context;
  return reportSummaryContext.start_date && reportSummaryContext.end_date
    ? eachDayOfInterval({
        start: reportSummaryContext.start_date,
        end: reportSummaryContext.end_date,
      })
    : undefined;
};

const getPreviousPeriodValue = (context: QueryValueContext) => {
  const { reportSummaryContext, previousPeriodProcesses, projectDurationSettings, outagesByRange } =
    context;

  if (
    !previousPeriodProcesses ||
    !projectDurationSettings ||
    !outagesByRange ||
    !reportSummaryContext.previous_period_start_date ||
    !reportSummaryContext.previous_period_end_date
  ) {
    return null;
  }

  return processDurationService.calculateProcessDetailSummary(
    previousPeriodProcesses,
    projectDurationSettings,
    outagesByRange,
    eachDayOfInterval({
      start: reportSummaryContext.previous_period_start_date,
      end: reportSummaryContext.previous_period_end_date,
    }),
  ).inactive;
};

const calculateInactiveDaysMetric = (context: QueryValueContext): QueryValueSingleValue | null => {
  const { processes, projectDurationSettings, outagesByRange } = context;

  if (!processes || !projectDurationSettings || !outagesByRange) {
    return null;
  }

  const valueDateInterval = getValueDateInterval(context);
  const value = processDurationService.calculateProcessDetailSummary(
    processes,
    projectDurationSettings,
    outagesByRange,
    valueDateInterval,
  ).inactive;
  const previousPeriodValue = getPreviousPeriodValue(context);
  const totalDuration = processDurationService.getTotalDuration(
    processDurationService.calculateProcessDetailSummary(
      processes,
      projectDurationSettings,
      outagesByRange,
      valueDateInterval,
    ),
  );
  return {
    value,
    value_text: formatValueTextWithTotalDays(value, totalDuration, "inactive_days"),
    previous_period_value: previousPeriodValue,
    previous_period_value_smaller_is_better: true,
  };
};

export default calculateInactiveDaysMetric;
