<template>
  <div class="w-full flex justify-center items-center h-full" v-if="isLogoUrlFetching">
    <LoadingSpinner />
  </div>
  <div v-else class="flex flex-col gap-12">
    <div class="flex flex-col gap-4">
      <h1 class="text-xl font-bold text-gray-900 px-1">
        {{ $t("organizations.tab_general") }}
      </h1>
      <div>
        <Form
          @submit="handleSubmit"
          :initialValues="initialValues"
          :validationSchema="schema"
          class="text-left flex flex-col gap-4"
          v-slot="{ isSubmitting, meta }"
        >
          <InputField
            name="name"
            :label="t('organizations.organization_name')"
            :readonly="!hasOrganizationAdminPermission"
          />
          <button
            v-if="hasOrganizationAdminPermission"
            type="submit"
            :disabled="isSubmitting || !meta.dirty"
            class="flex items-center gap-2 rounded-md self-end w-min bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600 disabled:bg-gray-300 disabled:cursor-default"
          >
            <span>{{ t("buttons.save") }}</span>
            <LoadingSpinner v-if="isSubmitting && meta.valid" size="w-4 h-4" />
          </button>
        </Form>
      </div>
    </div>
    <div class="flex flex-col gap-4" v-if="hasOrganizationBasePermission">
      <h1 class="text-xl font-bold text-gray-900 px-1">
        {{ $t("admin.general_project_settings.logo") }}
      </h1>
      <div class="w-full">
        <LogoUpload
          @updateFile="uploadLogo($event)"
          @deleteFile="deleteLogo"
          :savedLogo="logoUrl"
          :canEdit="hasOrganizationAdminPermission"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Form, SubmissionHandler } from "vee-validate";
import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import {
  useDeleteLogo,
  useLogoUrl,
  useUploadLogo,
  useHasOrganizationPermission,
  useUpdateOrganizationBase,
} from "shared/composables/organization";
import { Organization, OrganizationToUpdateBase } from "shared/types/Organization";
import InputField from "@/components/forms/InputField.vue";
import LogoUpload from "@/views/settings_project/components/LogoUpload.vue";

type OrganizationForm = {
  name: string;
};

const props = defineProps<{
  organization: Organization;
  organizationsByParentId: Record<string, Organization[] | undefined>;
}>();

const { t } = useI18n();

const organizationId = computed(() => props.organization._id);

const hasOrganizationBasePermission = useHasOrganizationPermission(
  "organization_base",
  organizationId.value,
);
const hasOrganizationAdminPermission = useHasOrganizationPermission(
  "organization_admin",
  organizationId.value,
);

const { updateOrganization } = useUpdateOrganizationBase();
const { logoUrl, isLogoUrlFetching } = useLogoUrl(organizationId);
const { deleteLogo } = useDeleteLogo(organizationId);
const { uploadLogo } = useUploadLogo(organizationId);

const schema = yup.object({
  name: yup.string().required(t("organizations.validation_field_required")),
});

const initialValues: Ref<OrganizationForm> = computed(() => ({
  name: props.organization?.name ?? "",
}));

const handleSubmit: SubmissionHandler = async (genericObject, context) => {
  const values = genericObject as OrganizationForm;
  const payload: OrganizationToUpdateBase = {
    _id: props.organization._id,
    name: values.name,
  };
  await updateOrganization(payload)
    .then(() => context.resetForm({ values: initialValues.value }))
    .catch(() => undefined);
};
</script>
