import { DependencyModel, EventModel, ResourceModel, SchedulerPro } from "@bryntum/schedulerpro";
// Using the react package here, because it has more up-to-date types
// See https://forum.bryntum.com/viewtopic.php?t=24760 and https://github.com/bryntum/support/issues/6753
import { BryntumSchedulerProProps } from "@bryntum/schedulerpro-react";
import { format, subDays } from "date-fns";
import { PlannerMode } from "shared/types/Plan";
import {
  SchedulerProBaseEvent,
  SchedulerProDropEvent,
  SchedulerProResourceItem,
} from "shared/types/SchedulerPro";
import {
  baseSchedulerProConfig,
  editPlannedEvent,
  getEventType,
  isModeCompatible,
  isParentResource,
  resetLag,
  RevisionEventModel,
  setDefaultStartAndEnd,
  shiftChildrenEvents,
  showInfoMenuItem,
  VisitorAndTrackerEventModel,
} from "shared/views/planner/SchedulerProConfig";
import {
  updateParentEventsForDependencies,
  updateParentEventsForResourceAndItsParents,
} from "shared/views/planner/SchedulerProParentEvents";
import i18n from "@/i18n";
import textService from "@/services/textService";

const { t } = i18n.global;

export const schedulerProConfigMode = (
  mode: PlannerMode,
  now: Date,
): Partial<BryntumSchedulerProProps> => {
  const baseConfig = baseSchedulerProConfig(mode);
  return {
    ...baseConfig,
    emptyText: t("analytics.planner.no_search_results"),
    columns: baseConfig.columns.map((column) => ({ ...column, readOnly: true })),
    readOnly: mode === "visitor",
    scheduleMenuFeature: {
      ...baseConfig.scheduleMenuFeature,
      disabled: false,
      items: {
        addEvent: false,
        removeRow: false,
        showProcesses:
          mode === "visitor"
            ? {
                text: t("analytics.planner.show_processes_menu_item"),
                icon: "b-fa b-fa-magnifying-glass-plus",
              }
            : false,
        hideProcesses:
          mode === "visitor"
            ? {
                text: t("analytics.planner.hide_processes_menu_item"),
                icon: "b-fa b-fa-magnifying-glass-minus",
              }
            : false,
        info:
          mode === "visitor"
            ? {
                text: t("analytics.planner.more_info_menu_item"),
                icon: "b-fa b-fa-info",
                onItem: (item: SchedulerProResourceItem) => {
                  if (item.record) {
                    showInfoMenuItem(item.source, item.record.id as string);
                  }
                },
              }
            : false,
        comment: {
          text: t("analytics.planner.comments.add_comment_menu_item"),
          icon: "b-fa b-fa-plus",
          onItem: async (item: SchedulerProResourceItem) => {
            if (!item.record) {
              return;
            }

            if (mode === "visitor") {
              showInfoMenuItem(item.source, item.record.id as string);
            } else {
              await editPlannedEvent(item.source, item.record.id as string);
            }
          },
        },

        edit:
          mode !== "visitor"
            ? {
                icon: "b-fa b-fa-pencil",
                text: t("analytics.planner.edit_event_menu_item"),
                onItem: async (item: SchedulerProResourceItem) => {
                  if (item.record) {
                    await editPlannedEvent(item.source, item.record.id as string);
                  }
                },
              }
            : false,
        addActual: {
          text: t("analytics.planner.add_actual_event"),
          icon: "b-fa b-fa-plus",
        },
        editActual: {
          text: t("analytics.planner.edit_actual_event"),
          icon: "b-fa b-fa-edit",
        },
      },
      processItems: ({ items, resourceRecord }) => {
        const isActualEventExists = resourceRecord.events.length > 1;

        if (isActualEventExists) {
          items.addActual = false;
        } else {
          items.editActual = false;
        }

        return items;
      },
    },
    cellMenuFeature: {
      ...baseConfig.cellMenuFeature,
      disabled: false,
      items: {
        removeRow: false,
        addEvent: false,
        showProcesses:
          mode === "visitor"
            ? {
                text: t("analytics.planner.show_processes_menu_item"),
                icon: "b-fa b-fa-magnifying-glass-plus",
              }
            : false,
        hideProcesses:
          mode === "visitor"
            ? {
                text: t("analytics.planner.hide_processes_menu_item"),
                icon: "b-fa b-fa-magnifying-glass-minus",
              }
            : false,
        info:
          mode === "visitor"
            ? {
                text: t("analytics.planner.more_info_menu_item"),
                icon: "b-fa b-fa-info",
                onItem: (item: SchedulerProResourceItem) => {
                  if (item.record) {
                    showInfoMenuItem(item.source, item.record.id as string);
                  }
                },
              }
            : false,
        comment: {
          text: t("analytics.planner.comments.add_comment_menu_item"),
          icon: "b-fa b-fa-plus",
          onItem: async (item: SchedulerProResourceItem) => {
            if (!item.record) {
              return;
            }

            if (mode === "visitor") {
              showInfoMenuItem(item.source, item.record.id as string);
            } else {
              await editPlannedEvent(item.source, item.record.id as string);
            }
          },
        },

        edit:
          mode !== "visitor"
            ? {
                icon: "b-fa b-fa-pencil",
                text: t("analytics.planner.edit_event_menu_item"),
                onItem: async (item: SchedulerProResourceItem) => {
                  if (item.record) {
                    await editPlannedEvent(item.source, item.record.id as string);
                  }
                },
              }
            : false,
      },
    },
    regionResizeFeature: {
      showSplitterButtons: false,
    },
    eventMenuFeature: {
      ...baseConfig.eventMenuFeature,
      disabled: false,
      items: {
        addEvent: false,
        cutEvent: false,
        splitEvent: false,
        copyEvent: false,
        editEvent: false,
        deleteEvent: false,
        showProcesses:
          mode === "visitor"
            ? {
                text: t("analytics.planner.show_processes_menu_item"),
                icon: "b-fa b-fa-magnifying-glass-plus",
              }
            : false,
        hideProcesses:
          mode === "visitor"
            ? {
                text: t("analytics.planner.hide_processes_menu_item"),
                icon: "b-fa b-fa-magnifying-glass-minus",
              }
            : false,
        info:
          mode === "visitor"
            ? {
                text: t("analytics.planner.more_info_menu_item"),
                icon: "b-fa b-fa-info",
                onItem: (item: SchedulerProBaseEvent) => {
                  if (item.eventRecord?.resourceId) {
                    showInfoMenuItem(item.source, item.eventRecord.resourceId as string);
                  }
                },
              }
            : false,
        comment: {
          text: t("analytics.planner.comments.add_comment_menu_item"),
          icon: "b-fa b-fa-plus",
          onItem: async (item: SchedulerProBaseEvent) => {
            if (!item.eventRecord?.resourceId) {
              return;
            }

            if (mode === "visitor") {
              showInfoMenuItem(item.source, item.eventRecord.resourceId as string);
            } else {
              await editPlannedEvent(item.source, item.eventRecord.resourceId as string);
            }
          },
        },
        edit:
          mode !== "visitor"
            ? {
                icon: "b-fa b-fa-pencil",
                text: t("analytics.planner.edit_event_menu_item"),
                onItem: async (item: SchedulerProBaseEvent) => {
                  if (item.eventRecord?.resourceId) {
                    await editPlannedEvent(item.source, item.eventRecord.resourceId as string);
                  }
                },
              }
            : false,
        addActual: {
          text: t("analytics.planner.add_actual_event"),
          icon: "b-fa b-fa-plus",
        },
        editActual: {
          text: t("analytics.planner.edit_actual_event"),
          icon: "b-fa b-fa-edit",
        },
      },
      processItems: ({ items, resourceRecord }) => {
        const isActualEventExists = resourceRecord.events.length > 1;

        if (isActualEventExists) {
          items.addActual = false;
        } else {
          items.editActual = false;
        }

        return items;
      },
    },
    dependenciesFeature: {
      ...baseConfig.dependenciesFeature,
      disabled: mode !== "revision",
      allowCreate: mode === "revision",
    },
    timeRangesFeature: {
      ...baseConfig.timeRangesFeature,
      headerRenderer({
        timeRange,
      }: {
        timeRange: { id: string | number; name: string; startDate: Date; endDate: Date };
      }) {
        if (typeof timeRange.id === "string" && timeRange.id.startsWith("holiday_")) {
          const startDate = timeRange.startDate;
          const endDate = subDays(timeRange.endDate, 1);
          const rangeLabel =
            startDate.getTime() === endDate.getTime()
              ? format(startDate, "dd.MM.yyyy")
              : `${format(startDate, "dd.MM.yyyy")}-${format(endDate, "dd.MM.yyyy")}`;
          return `
                <div class="planner-holiday-timerange-label-container tooltip">
                  <span class="planner-holiday-timerange-label">
                    ${timeRange.name}
                  </span>
                  <span class="tooltiptext tooltip-top">${timeRange.name} (${rangeLabel})</span>
                </div>
            `;
        }
        return `<span style="font-size: 12px">${timeRange.name}</span>`;
      },
    },
    eventTooltipFeature: {
      template: (data: { startDate: Date; endDate: Date; eventRecord: EventModel }) => {
        const html = baseConfig.eventTooltipFeature?.template(data);
        const isEvent = data.eventRecord.getData("type")?.startsWith("process_");
        if (!isEvent) {
          return html;
        }

        return null;
      },
    },
    pdfExportFeature: {
      disabled: false,
      sendAsBinary: true,
      showErrorToast: false,
      fileFormat: "pdf",
      filterStyles(styles: string[]): string[] {
        return window.location.hostname === "localhost"
          ? styles.filter((style) => !style.includes("tailwindcss"))
          : styles;
      },
      paperFormat: "A4",
      orientation: "portrait",
      alignRows: false,
      repeatHeader: false,
      exporterType: "singlepage",
      fileName: `oculai_${textService.toNormalizedFileName(
        t("analytics.planner.export_name"),
      )}.pdf`,
    },
    filterBarFeature: mode === "visitor",
    eventEditFeature: true,
    timeAxisHeaderMenuFeature: false,
    project: {
      ...baseConfig.project,
      eventModelClass: mode === "revision" ? RevisionEventModel : VisitorAndTrackerEventModel,
    },
    listeners: {
      beforeEventDrag(this: SchedulerPro, event: SchedulerProBaseEvent) {
        resetLag(this, event.eventRecord.id);
        // Prevents switching lines
        const lengthEventsForMode = event.eventRecord.resource.events.filter((obj) =>
          isModeCompatible(obj, mode),
        ).length;
        this.features.eventDrag.constrainDragToResource = lengthEventsForMode >= 1;
      },
      async eventDrop(event: SchedulerProDropEvent) {
        if (event.eventRecords.length > 0) {
          setDefaultStartAndEnd(
            event.source,
            event.eventRecords[0],
            now,
            event.context.origStart,
            event.context.origEnd,
          );

          await updateParentEventsForResourceAndItsParents(
            event.source,
            event.resourceRecord.parent,
          );
          await updateParentEventsForDependencies(event.source, event.resourceRecord);
        }
        shiftChildrenEvents(event, getEventType(mode));
      },
      async eventResizeEnd(event: SchedulerProBaseEvent) {
        setDefaultStartAndEnd(event.source, event.eventRecord, now);
        await updateParentEventsForResourceAndItsParents(event.source, event.resourceRecord.parent);
        await updateParentEventsForDependencies(event.source, event.resourceRecord);
      },
      beforeEventResize(event: SchedulerProBaseEvent) {
        const isParent = isParentResource(event.resourceRecord);
        if (!isParent) {
          resetLag(event.source, event.eventRecord.id);
        }

        return !isParent;
      },
      beforeShowTerminals(e: { source: EventModel }) {
        return e.source.getData("type") === "planned";
      },
      dependencyDblClick(this: SchedulerPro, event: { dependency: DependencyModel }) {
        if (mode === "revision") {
          this.dependencyStore.remove(event.dependency);
        }
      },
      beforeDependencyCreateFinalize(e: { target: EventModel; fromSide: string; toSide: string }) {
        e.fromSide = "end";
        e.toSide = "top";

        return e.target.getData("type") === "planned";
      },
      afterDependencyCreateDrop(
        this: SchedulerPro,
        event: { sourceResource: ResourceModel; targetResource?: ResourceModel },
      ) {
        setTimeout(async () => {
          await updateParentEventsForResourceAndItsParents(this, event.sourceResource.parent);
          await updateParentEventsForDependencies(this, event.sourceResource);
          if (event.targetResource) {
            await updateParentEventsForResourceAndItsParents(this, event.targetResource.parent);
            await updateParentEventsForDependencies(this, event.targetResource);
          }
        }, 100);
      },
    },
  } as Partial<BryntumSchedulerProProps>;
};
