<template>
  <Chart :options="options" :class="cls" v-if="hasData" />
  <div class="flex items-center justify-center h-full" v-else>
    <div class="flex flex-col gap-1 items-center text-center">
      <NoSymbolIcon class="w-8 h-8" />
      <div class="text-sm">{{ t("analytics.reports.no_data_for_report") }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { NoSymbolIcon } from "@heroicons/vue/24/outline";
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{ options: Highcharts.Options | null; cls?: string }>();

const { t } = useI18n();

const hasData = computed(
  () =>
    props.options &&
    props.options.series &&
    props.options.series.length > 0 &&
    props.options.series.some((series) => "data" in series && series.data?.length),
);
</script>
