<template>
  <Modal
    class="flex flex-1 items-stretch"
    :open="open"
    custom-cls="w-full lg:w-max"
    @close="$emit('closeUserSettingsModal')"
  >
    <template #title>
      {{ $t("admin.settings.user.title") }}<span>&#8203;</span>
      <div class="space-y-1">
        <p class="max-w-2xl text-sm text-gray-500 font-normal">
          {{ $t("admin.settings.user.sub_title") }}
        </p>
      </div></template
    >
    <template #content>
      <div class="mt-6">
        <Form
          @submit="handleSubmitUpdateUserProperties"
          :initialValues="initializeUserProperties()"
          :validationSchema="updatePropertiesSchema"
          class="flex flex-col gap-2 w-full"
        >
          <InputField name="name" :label="$t('admin.settings.user.name')" />
          <InputField name="company" :label="$t('admin.settings.user.company')" />
          <SelectListField
            name="role"
            :label="$t('admin.settings.user.role')"
            :options="roleTypesList"
            :minWidth="250"
            :placeholder="`--
              ${$t('form.select_option')} --`"
          />

          <div class="pt-4 flex items-center justify-end">
            <button
              type="submit"
              class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-yellow-600 px-5 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            >
              <div v-if="isLoading">
                <LoadingSpinner v-if="isLoading" size="w-4 h-4" color="white" />
              </div>
              {{ $t("form.update") }}
            </button>
          </div>
        </Form>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { Form, SubmissionHandler } from "vee-validate";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import * as yup from "yup";
import SelectListField from "shared/components/forms/SelectListField.vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useLoadAppUser } from "shared/composables/auth";
import { userRoles } from "shared/constants/user";
import InputField from "@/components/forms/InputField.vue";
import UserRepository from "@/repositories/UserRepository";

const { t } = useI18n();
const store = useStore();
const loadAppUser = useLoadAppUser();

defineProps<{ open: boolean }>();
const emit = defineEmits(["closeUserSettingsModal"]);

const updatePropertiesSchema = yup.object().shape({
  name: yup.string().required(t("authentication.validation.validation_field_required")),
  company: yup.string().required(t("authentication.validation.validation_field_required")),
  role: yup.string().required(t("authentication.validation.validation_field_required")),
});

const roleTypesList = userRoles.map((role) => ({
  value: role,
  name: t(`admin.project_participants.user_roles.${role}`),
}));

const isLoading = ref(false);

const initializeUserProperties = () => {
  const user = store.state.user;
  return {
    name: user.name,
    company: user.company,
    role: user.role || "",
  };
};

const handleSubmitUpdateUserProperties: SubmissionHandler = async (genericObject) => {
  isLoading.value = true;
  await UserRepository.updateOwnUserProperties(
    genericObject.name,
    genericObject.company,
    genericObject.role,
  );
  await loadAppUser();
  isLoading.value = false;
  emit("closeUserSettingsModal");
};
</script>
