import * as fabric from "fabric";
import { SectionMask } from "shared/types/SectionMask";

export const drawMaskOnCanvas = (
  canvas: fabric.StaticCanvas,
  sectionMask: SectionMask,
  name: string,
) => {
  const maskPath = new fabric.Path(
    sectionMask.mask
      .flat()
      .map(([x, y], index) => {
        return `${index === 0 ? "M" : "L"} ${x * canvas.width} ${y * canvas.height}`;
      })
      .join(" ") + " Z",
  );

  const opacity = 0.2;
  const fillColor = `${sectionMask.color.slice(0, -4)}${opacity})`;

  maskPath.set({
    fill: fillColor,
    stroke: sectionMask.color,
    strokeWidth: 2,
  });

  canvas.add(maskPath);

  if (name) {
    const text = new fabric.Text(name, {
      fontSize: 14,
      strokeWidth: 3,
      stroke: "#000",
      fill: "#fff",
      fontFamily: "arial",
      fontWeight: "bold",
      paintFirst: "stroke",
      top: 5,
    });
    text.set({
      left: canvas.width - text.width - 10,
    });

    canvas.add(text);
  }

  canvas.renderAll();
};
