import { computed, Ref } from "vue";
import i18n from "../../i18n";
import { CriticalPath } from "../../types/CriticalPath";

const { localeText } = i18n.global;

export const getDeltaTextClass = (delta: number | null) => {
  if (delta === null) {
    return undefined;
  }
  if (delta === 0) {
    return "text-gray-600";
  }
  return delta < 0 ? "text-green-400" : "text-red-400";
};

export const useDeltaTextClass = (delta: Ref<number | null>) =>
  computed(() => getDeltaTextClass(delta.value));

export const getDeltaTextWithBackgroundClass = (delta: number | null) => {
  if (delta === null) {
    return undefined;
  }
  if (delta === 0) {
    return "bg-gray-400 text-white";
  }
  return delta < 0 ? "bg-green-50 text-green-600" : "bg-red-200 text-red-800";
};

export const useDeltaTextWithBackgroundClass = (delta: Ref<number | null>) =>
  computed(() => getDeltaTextWithBackgroundClass(delta.value));

export const getFormattedDelta = (delta: number | null) => {
  if (delta === null) {
    return "-";
  }
  const sign = delta > 0 ? "+" : "";
  return `${sign}${delta.toLocaleString(localeText, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    useGrouping: false,
  })}`;
};

export const useFormattedDelta = (delta: Ref<number | null>) =>
  computed(() => getFormattedDelta(delta.value));

export const useCriticalPathTagLetterById = (criticalPath: Ref<CriticalPath>) => {
  const abc = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return computed(() =>
    criticalPath.value.tags.reduce((acc, tag, index) => {
      acc[tag._id] = abc[index % abc.length];
      return acc;
    }, {} as Record<string, string>),
  );
};
