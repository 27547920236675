import * as Sentry from "@sentry/vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import UserRepository from "../repositories/UserRepository";
import { analytics } from "./tracking";

export const useSaveReturnUrl = () => {
  const route = useRoute();

  return () => {
    const returnUrl = route.fullPath;
    window.sessionStorage.setItem("returnUrl", returnUrl);
  };
};

export const useGetReturnUrl = () => () => {
  const returnUrl = window.sessionStorage.getItem("returnUrl") || "/";
  window.sessionStorage.removeItem("returnUrl");
  return returnUrl;
};

export const useLoadAppUser = () => {
  const store = useStore();
  return async () => {
    const user = await UserRepository.loadAppUser();

    store.commit("setUser", user);
    analytics?.identify(user.username, {
      email: user.email,
      name: user.name,
      customer: user.company,
    });
    // this should use logger.setUser()
    // but for some reason importing logger in this file breaks the full app
    // so far unclear why
    if (process.env.NODE_ENV === "production") {
      Sentry.setUser({ email: user.email });
    }
  };
};
