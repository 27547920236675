import { AxiosResponse } from "axios";
import { apiClient } from "shared/repositories/clients";
import {
  User,
  UserProject,
  InviteUser,
  UserRole,
  UserOrganizationToUpdate,
} from "shared/types/User";

const loadAppUser = (): Promise<User> =>
  apiClient.get<void, AxiosResponse<User>>(`/user/app`).then((response) => response.data);

const loadHubSpotToken = (): Promise<string> =>
  apiClient
    .get<void, AxiosResponse<{ token: string }>>(`/user/hubspot/identification`)
    .then((response) => response.data.token);

const loadUsersForProject = (customerName: string, siteId: string): Promise<User[]> =>
  apiClient
    .get<void, AxiosResponse<User[]>>(`/user/project/${customerName}/${siteId}`)
    .then((response) => response.data);

const loadAllUsersForProject = (customerName: string, siteId: string): Promise<User[]> =>
  apiClient
    .get<void, AxiosResponse<User[]>>(`/user/project/${customerName}/${siteId}/all`)
    .then((response) => response.data);

const updateUserProjectPermission = (
  username: string,
  customerName: string,
  siteId: string,
  projectPermission: UserProject,
): Promise<User> => {
  const { groups, ...rest } = projectPermission;
  return apiClient
    .patch<void, AxiosResponse<User>>(
      `/user/${username}/${customerName}/${siteId}/project-permission`,
      rest,
    )
    .then((response) => response.data);
};

const updateUserOrganizationPermission = (
  username: string,
  organizationId: string,
  organizationPermission: UserOrganizationToUpdate,
): Promise<User> =>
  apiClient
    .patch<void, AxiosResponse<User>>(
      `/user/${username}/${organizationId}/organization-permission`,
      organizationPermission,
    )
    .then((response) => response.data);

const bulkInviteUsersToProject = (
  customerName: string,
  siteId: string,
  usersToInvite: InviteUser[],
): Promise<User[]> =>
  apiClient
    .post<void, AxiosResponse<User[]>>(`/user/bulk-invite/${customerName}/${siteId}/app`, {
      users: usersToInvite,
    })
    .then((response) => response.data);

const bulkInviteUsersToOrganization = (
  organizationId: string,
  usersToInvite: InviteUser[],
): Promise<User[]> =>
  apiClient
    .post<void, AxiosResponse<User[]>>(`/user/bulk-invite/${organizationId}/app`, {
      users: usersToInvite,
    })
    .then((response) => response.data);

const deleteUser = (username: string): Promise<void> =>
  apiClient
    .delete<void, AxiosResponse<void>>(`/user/${username}`)
    .then((response) => response.data);

const completeSignUp = (
  email: string,
  name: string,
  company: string | null,
  role: UserRole | null,
  method: "default" | "sso" = "default",
) =>
  apiClient
    .post<void, AxiosResponse<User[]>>(`/user/${email}/${method}/complete-sign-up`, {
      name,
      role,
      company,
    })
    .then((response) => response.data);

const updateOwnUserProperties = (name: string, company: string, role: UserRole) =>
  apiClient
    .post<void, AxiosResponse<User>>(`/user/user-properties`, {
      name,
      role,
      company,
    })
    .then((response) => response.data);

const adminUpdateUserProperties = (
  username: string,
  name: string,
  company: string,
  role: UserRole,
) =>
  apiClient
    .post<void, AxiosResponse<User>>(`/user/${username}/user-properties`, {
      name,
      role,
      company,
    })
    .then((response) => response.data);

const loadUsersForOrganization = (organizationId: string): Promise<User[]> =>
  apiClient
    .get<void, AxiosResponse<User[]>>(`/user/organization/${organizationId}`)
    .then((response) => response.data);

const loadBaseUsersForOrganization = (organizationId: string): Promise<User[]> =>
  apiClient
    .get<void, AxiosResponse<User[]>>(`/user/organization/${organizationId}/base`)
    .then((response) => response.data);

export default {
  loadAppUser,
  loadHubSpotToken,
  loadUsersForProject,
  loadAllUsersForProject,
  updateUserProjectPermission,
  updateUserOrganizationPermission,
  bulkInviteUsersToProject,
  bulkInviteUsersToOrganization,
  deleteUser,
  completeSignUp,
  adminUpdateUserProperties,
  updateOwnUserProperties,
  loadUsersForOrganization,
  loadBaseUsersForOrganization,
};
