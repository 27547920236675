<template>
  <div
    v-if="progress !== null"
    id="planner-progress-badge"
    class="font-bold text-sm hover:underline flex items-center gap-1 cursor-pointer rounded-full bg-gray-300 text-gray-700 px-3 py-1 self-center h-min"
    @click="
      isProjectProgressModalOpen = true;
      trackEvent('progress_detail_click', { origin });
    "
  >
    {{ formattedProgress }} %
    <MagnifyingGlassIcon class="w-4 h-4" />
  </div>
  <ProjectProgressModal
    v-if="isProjectProgressModalOpen && planConfig && shortenedProcessesWithTags"
    :planConfig="planConfig"
    :processes="shortenedProcessesWithTags"
    @close="isProjectProgressModalOpen = false"
  />
</template>

<script setup lang="ts">
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import { computed, ref, watch } from "vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { usePlanConfig } from "shared/composables/planner";
import { useShortenedProcessesWithTags } from "shared/composables/process";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { useTrackEvent } from "shared/composables/tracking";
import projectProgressService from "@/services/projectProgressService";
import ProjectProgressModal from "@/views/reports/plots/query_value/ProjectProgressModal.vue";

defineProps<{ origin: string }>();

const isProjectProgressModalOpen = ref(false);

const trackEvent = useTrackEvent();
const { planConfig } = usePlanConfig();
const { shortenedProcessesWithTags } = useShortenedProcessesWithTags();
const { projectDurationSettings } = useProjectDurationSettings();
const { hierarchyTags } = useHierarchyTags();

const precision = 1;

const progress = computed(() => {
  if (
    !planConfig.value ||
    !shortenedProcessesWithTags.value ||
    !projectDurationSettings.value ||
    hierarchyTags.value.length === 0
  ) {
    return null;
  }
  return projectProgressService.calculateProjectProgressPercentage(
    planConfig.value,
    shortenedProcessesWithTags.value,
    projectDurationSettings.value,
    hierarchyTags.value,
  );
});

const formatNumber = (value: number | null) => {
  if (value === null || !Number.isFinite(value)) {
    return "";
  }
  return value.toLocaleString(window.navigator.language, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
};

const formattedProgress = computed(() => {
  if (progress.value === null) {
    return null;
  }
  return formatNumber(progress.value * 100);
});

watch(progress, (newValue) => {
  // If progress > 10%, show jimo tip
  if (newValue && newValue > 0.1) {
    window.jimo?.push([
      "do",
      "boosted:trigger",
      [{ evolutionId: "469aa1d8-3b81-4be0-b1df-cd3901f51101" }],
    ]);
  }
});
</script>
