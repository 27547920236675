<template>
  <div class="flex flex-col gap-12">
    <div class="flex flex-col gap-4">
      <div class="text-xl font-bold text-gray-900 px-1 truncate">
        {{ t("organizations.projects") }}
      </div>
      <div class="bg-white rounded border flex flex-col min-h-[200px]">
        <div v-if="projectKeys.length > 0">
          <OrganizationTabProjectsItem
            v-for="projectKey in projectKeys"
            :key="projectKey"
            :project="projectsByCustomerNameSiteId[projectKey] as Project"
            :organization="organization"
          />
        </div>
        <div
          v-if="childOrganizations.length === 0 && projectKeys.length === 0"
          class="px-2 text-gray-400 text-xs py-1"
        >
          {{ t("organizations.no_projects") }}
        </div>
        <OrganizationTabProjectsHierarchyItem
          v-for="organizationInHierarchy in childOrganizations"
          :key="organizationInHierarchy._id"
          :organization="organizationInHierarchy"
          :organizationsByParentId="organizationsByParentId"
          :projectsByCustomerNameSiteId="projectsByCustomerNameSiteId"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Organization } from "shared/types/Organization";
import { Project } from "shared/types/Project";
import OrganizationTabProjectsHierarchyItem from "@/views/organizations/components/OrganizationTabProjectsHierarchyItem.vue";
import OrganizationTabProjectsItem from "@/views/organizations/components/OrganizationTabProjectsItem.vue";

const props = defineProps<{
  organization: Organization;
  organizationsByParentId: Record<string, Organization[] | undefined>;
}>();

const { t } = useI18n();
const store = useStore();

const childOrganizations = computed(
  () => props.organizationsByParentId[props.organization._id] || [],
);

const projectsByCustomerNameSiteId = computed(() => {
  const projects: Project[] = store.state.projects || [];
  return projects.reduce((acc, project) => {
    acc[`${project.customer_name}_${project.site_id}`] = project;
    return acc;
  }, {} as Record<string, Project | undefined>);
});

const projectKeys = computed(() =>
  props.organization.explicit_projects
    .map((project) => `${project.customer_name}_${project.site_id}`)
    .filter((projectKey) => projectsByCustomerNameSiteId.value[projectKey]),
);
</script>
