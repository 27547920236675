import { AxiosResponse } from "axios";
import { apiClient } from "shared/repositories/clients";
import { User } from "shared/types/User";

const loadAppUser = (): Promise<User> =>
  apiClient.get<void, AxiosResponse<User>>(`/user/app`).then((response) => response.data);

export default {
  loadAppUser,
};
