<template>
  <div
    v-if="delta !== null"
    id="planner-delta-badge"
    class="font-bold text-sm hover:underline flex items-center gap-1 cursor-pointer rounded-full px-3 py-1 self-center h-min"
    :class="deltaClass"
    @click="
      isCriticalPathModalOpen = true;
      trackEvent('planner_delta_click', { origin });
    "
  >
    {{ formattedDelta }}
    {{ t("working_day.working_day_abbrev") }}
    <MagnifyingGlassIcon class="w-4 h-4" />
  </div>
  <CriticalPathModal
    v-if="isCriticalPathModalOpen"
    @close="isCriticalPathModalOpen = false"
    :origin="origin"
  />
</template>

<script setup lang="ts">
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { useCriticalPath, usePlanConfig } from "shared/composables/planner";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { useTrackEvent } from "shared/composables/tracking";
import CriticalPathModal from "shared/views/critical_path/components/CriticalPathModal.vue";
import {
  useDeltaTextWithBackgroundClass,
  useFormattedDelta,
} from "shared/views/critical_path/composables";
import { calculateDelta } from "shared/views/critical_path/criticalPath";

defineProps<{ origin: string }>();

const { t } = useI18n();

const isCriticalPathModalOpen = ref(false);

const { criticalPath } = useCriticalPath();
const { planConfig } = usePlanConfig();
const { projectDurationSettings } = useProjectDurationSettings();
const { hierarchyTags } = useHierarchyTags();
const trackEvent = useTrackEvent();

const delta = computed(() => {
  if (
    !criticalPath.value ||
    !planConfig.value ||
    !projectDurationSettings.value ||
    hierarchyTags.value.length === 0
  ) {
    return null;
  }
  return calculateDelta(
    criticalPath.value,
    planConfig.value,
    hierarchyTags.value,
    projectDurationSettings.value,
  );
});

const deltaClass = useDeltaTextWithBackgroundClass(delta);
const formattedDelta = useFormattedDelta(delta);
</script>
