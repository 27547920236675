<template>
  <div v-if="loading" class="absolute inset-0 bg-white opacity-60" style="z-index: 9999">
    <LoadingSection :loading="loading" />
  </div>
  <SlideOver
    :open="open"
    @closed="!isConfirmationModalOpen && !galleryDownloadModalOpen && $emit('closed')"
    maxWidth="1000px"
  >
    <template #title> {{ resourceFullName }} </template>
    <template #content>
      <div class="border-b pt-2 pb-4 my-3 border-gray-500">
        <div
          class="py-1 flex items-center gap-1 justify-between flex-wrap"
          :style="actualEvent && 'margin-right: 1.75rem'"
          v-if="plannedEvent"
        >
          <div
            class="whitespace-nowrap rounded-lg px-2 py-0.5 my-1 text-sm text-white font-normal plannedBg"
          >
            {{ $t("analytics.planner.target_period") }}
          </div>
          <div class="flex text-sm text-gray-600 font-normal">
            <span>
              {{ format(plannedEvent.getData("startDate"), "dd.MM.yyyy") }}
            </span>
            <span class="px-1">-</span>
            <span>
              {{ format(plannedEvent.getData("endDate"), "dd.MM.yyyy") }}
            </span>
            <span class="ml-1">
              ({{
                `${formatNumber(getWorkingDays(plannedEvent))} ${$t(
                  "analytics.planner.working_days_label",
                  getWorkingDays(plannedEvent),
                )}`
              }})
            </span>
          </div>
        </div>
        <div class="py-1 flex items-center gap-1 justify-between flex-wrap" v-if="actualEvent">
          <div
            class="whitespace-nowrap rounded-lg px-2 py-0.5 my-1 text-sm text-white font-normal bg-green"
          >
            {{ $t("analytics.planner.actual_period") }}
          </div>
          <div class="flex gap-3 items-center">
            <div class="flex text-sm text-gray-600 font-normal" v-if="!editMode">
              <span>
                {{
                  actualEvent.getData("startDate")
                    ? format(actualEvent.getData("startDate"), "dd.MM.yyyy")
                    : ""
                }}
              </span>

              <span class="px-1">-</span>
              <span>
                {{
                  actualEvent.getData("active")
                    ? $t("analytics.planner.present")
                    : actualEvent.getData("endDate")
                    ? format(actualEvent.getData("endDate"), "dd.MM.yyyy")
                    : ""
                }}
              </span>

              <span class="ml-1">
                ({{
                  `${formatNumber(getWorkingDays(actualEvent))} ${$t(
                    "analytics.planner.working_days_label",
                    getWorkingDays(actualEvent),
                  )}`
                }})
              </span>
            </div>

            <div class="flex text-sm text-gray-600 font-normal items-center" v-if="editMode">
              <VueDatePicker
                input-class-name="!text-xs"
                class="w-36"
                v-model="newStartTime"
                :enable-time-picker="false"
                format="dd.MM.yyyy"
                :locale="$i18n.locale"
                auto-apply
                reverse-years
                :clearable="false"
              />
              <span class="px-2">-</span>
              <VueDatePicker
                input-class-name="!text-xs"
                class="w-36"
                v-model="newEndTime"
                :enable-time-picker="false"
                format="dd.MM.yyyy"
                :locale="$i18n.locale"
                auto-apply
                reverse-years
                :disabled="!isActualCompleted"
                :clearable="false"
              />
            </div>

            <div class="flex gap-2">
              <PencilIcon
                :class="[
                  'h-4 w-4 cursor-pointer transition-colors duration-200',
                  isActualProcessing
                    ? 'text-gray-300 hover:text-gray-300'
                    : 'text-gray-400 hover:text-gray-600',
                ]"
                v-if="!editMode"
                @click="setEditMode"
              />
              <CheckIcon
                :class="[
                  'h-4 -600 cursor-pointer transition-colors duration-200',
                  isActualProcessing
                    ? 'text-gray-300 hover:text-gray-300'
                    : '-4 text-gray-400 hover:text-green',
                ]"
                v-if="editMode"
                @click="handleActualUpdate"
              />
              <XMarkIcon
                :class="[
                  'h-4 w-4 cursor-pointer transition-colors duration-200',
                  isActualProcessing
                    ? 'text-gray-300 hover:text-gray-300'
                    : 'text-gray-400 hover:text-gray-600',
                ]"
                v-if="editMode"
                @click="disableEditMode"
              />
              <TrashIcon
                :class="[
                  'h-4 w-4 cursor-pointer transition-colors duration-200',
                  isActualProcessing
                    ? 'text-gray-300 hover:text-gray-300'
                    : 'text-gray-400 hover:text-red-600',
                ]"
                v-if="editMode"
                @click="handleActualDelete"
              />
            </div>
          </div>
        </div>
        <div v-else class="flex justify-center mt-2">
          <button
            type="button"
            @click="handleAddActual"
            class="flex items-center gap-1 rounded bg-yellow-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
          >
            <PlusIcon class="h-5 w-5" />
            {{ $t("analytics.planner.add_actual_event") }}
          </button>
        </div>
        <div class="flex justify-end gap-2 items-center mt-2" v-if="editMode">
          <span class="text-xs">{{
            isActualCompleted
              ? $t("analytics.planner.actual_finished")
              : $t("analytics.planner.actual_active")
          }}</span>
          <OaiSwitch v-model="isActualCompleted" />
        </div>
      </div>

      <div class="py-4">
        <PlannerComments
          :sourceId="sourceId"
          :key="plannerCommentsKey"
          @confirmationModalIsOpen="isConfirmationModalOpen = true"
          @confirmationModalIsClosed="isConfirmationModalOpen = false"
          @commentAdded="$emit('commentAdded', $event)"
          @commentDeleted="$emit('commentDeleted', $event)"
        />
      </div>
      <div v-if="cameraList.length && actualEvent?.getData('id')">
        <div v-for="camera in cameraList" :key="camera" class="my-6 border-t py-4">
          <div class="flex justify-between items-center">
            <h2 class="font-bold text-md">{{ $t("app_features.compare") }}</h2>
            <button
              @click="
                galleryDownloadStreamsToInitialze = [camera];
                galleryDownloadModalOpen = true;
              "
            >
              <ArrowDownTrayIcon class="h-6" />
            </button>
          </div>
          <ImagesComparison
            :camera="camera"
            :initialAfterDate="initialAfterDate"
            :initialBeforeDate="initialBeforeDate"
            hideNoImageToast
          />
        </div>
      </div>
      <GalleryDownload
        v-if="galleryDownloadModalOpen"
        :streamsToInitialize="galleryDownloadStreamsToInitialze"
        :dateRangeToInitialize="
          initialBeforeDate && initialAfterDate ? [initialBeforeDate, initialAfterDate] : undefined
        "
        @close="galleryDownloadModalOpen = false"
      ></GalleryDownload>
      <ConfirmationModal ref="confirmationModal" />
    </template>
  </SlideOver>
</template>

<script lang="ts">
import { EventModel } from "@bryntum/schedulerpro";
import { ArrowDownTrayIcon } from "@heroicons/vue/20/solid";
import { PlusIcon } from "@heroicons/vue/24/outline";
import { PencilIcon, TrashIcon, XMarkIcon, CheckIcon } from "@heroicons/vue/24/solid";
import VueDatePicker from "@vuepic/vue-datepicker";
import { format, set } from "date-fns";
import { defineComponent, PropType } from "vue";
import LoadingSection from "shared/components/loading_state/LoadingSection.vue";
import ConfirmationModal from "shared/components/modals/ConfirmationModal.vue";
import OaiSwitch from "shared/components/other/OaiSwitch.vue";
import { useUpdateActualEvents } from "shared/composables/planner";
import durationService from "shared/services/durationService";
import { ActualEventChanges } from "shared/types/Plan";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import ImagesComparison from "@/components/other/ImagesComparison.vue";
import { useProcessClasses } from "@/composables/process";
import IssueReportRepository from "@/repositories/IssueReportRepository";
import GalleryDownload from "@/views/camera/components/GalleryDownload.vue";
import PlannerComments from "@/views/planner/components/PlannerComments.vue";
import SlideOver from "@/views/planner/components/SlideOver.vue";

export default defineComponent({
  name: "ActualSideBar",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    cameraList: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    resourceFullName: {
      type: String,
      required: true,
    },
    events: {
      type: Array as PropType<Array<EventModel>>,
    },
    sourceId: {
      type: String as PropType<string>,
      required: true,
    },
    projectDurationSettings: {
      type: Object as PropType<ProjectDurationSettings>,
      required: false,
    },
  },
  emits: ["closed", "commentAdded", "commentDeleted", "actualUpdated"],
  components: {
    PlusIcon,
    ConfirmationModal,
    VueDatePicker,
    PlannerComments,
    LoadingSection,
    ImagesComparison,
    SlideOver,
    ArrowDownTrayIcon,
    GalleryDownload,
    PencilIcon,
    TrashIcon,
    XMarkIcon,
    CheckIcon,
    OaiSwitch,
  },
  data() {
    return {
      plannerEvents: [] as EventModel[],
      videoUrls: {} as Record<string, string>,
      videoUrlLoading: false as boolean,
      selectedProcessIndex: 0 as number,
      processTabSkip: 0 as number,
      hasDailyReportPermission: false as boolean,
      loading: false as boolean,
      galleryDownloadModalOpen: false as boolean,
      galleryDownloadStreamsToInitialze: [] as string[],
      isConfirmationModalOpen: false as boolean,
      editMode: false,
      newStartTime: null as Date | null,
      newEndTime: null as Date | null,
      isActualCompleted: true,
      isActualProcessing: false,
    };
  },
  setup() {
    const processClasses = useProcessClasses();
    const { updateActualEvents } = useUpdateActualEvents();

    return {
      processClasses,
      updateActualEvents,
    };
  },
  watch: {
    isActualCompleted(value) {
      if (!value) {
        this.newEndTime = null;
      } else {
        this.newEndTime = this.actualEvent?.getData("endDate");
      }
    },
    events(value) {
      this.plannerEvents = value;
    },
    open(isOpen) {
      if (!isOpen) {
        this.editMode = false;
        this.plannerEvents = [];
        this.isActualCompleted = true;
      }
    },
    actualEvent(event) {
      this.newStartTime = event?.getData("startDate");
      this.newEndTime = event?.active ? null : event?.getData("endDate");

      this.isActualCompleted = !event?.active;
    },
  },
  computed: {
    encodedLabelsToDecodedLabel() {
      return this.processClasses.reduce((acc, processClass) => {
        acc[processClass.encodedLabel] = processClass.decodedLabel;

        return acc;
      }, {} as Record<string, string>);
    },
    initialBeforeDate() {
      return this.actualEvent?.getData("startDate")
        ? format(this.actualEvent.getData("startDate"), "yyyy-MM-dd")
        : "";
    },
    initialAfterDate() {
      return this.actualEvent?.getData("endDate")
        ? format(this.actualEvent.getData("endDate"), "yyyy-MM-dd")
        : "";
    },
    plannedEvent() {
      return this.plannerEvents?.find((item) => item.getData("type") === "planned") as EventModel;
    },
    actualEvent() {
      return this.plannerEvents?.find((item) => item.getData("type") === "actual") as EventModel;
    },
    plannerCommentsKey() {
      return `${this.currentCustomerName}-${this.currentSiteId}-${this.sourceId},
      )}`;
    },
  },
  methods: {
    format,
    getWorkingDays(event: EventModel) {
      if (!this.projectDurationSettings) {
        return 0;
      }

      let endDate = event.endDate as Date;

      if (!endDate) {
        endDate = new Date();
      }

      return durationService.calculateDuration(
        this.projectDurationSettings.settings,
        event.startDate as Date,
        endDate,
      ).workingDays;
    },
    formatNumber(value: number) {
      return value.toLocaleString(this.$i18n.locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      });
    },
    handleAddActual() {
      const newActualEvent = new EventModel({
        id: "",
        startDate: null,
        endDate: undefined,
        type: "actual",
        active: true,
        source_id: this.plannedEvent.resource.getData("sourceId"),
      });

      this.plannerEvents = [...this.plannerEvents, newActualEvent];
      this.setEditMode();
    },
    async handleActualDelete() {
      if (!this.actualEvent || !this.$refs.confirmationModal) {
        return;
      }

      if (!this.actualEvent.id) {
        this.plannerEvents = this.plannerEvents.filter((item) => item.getData("type") !== "actual");
        this.editMode = false;
        return;
      }

      // @ts-expect-error core vue types issue with Composition API
      const isConfirmed = await this.$refs.confirmationModal.showConfirmationModal({
        color: "yellow",
        header: this.$t("analytics.planner.delete_actual_confirmation_title"),
        confirmAction: this.$t("analytics.planner.delete_actual_confirmation_confirm"),
        cancelAction: this.$t("analytics.planner.delete_actual_confirmation_cancel"),
        message: this.$t("analytics.planner.delete_actual_confirmation_message"),
      });

      if (!isConfirmed) {
        return;
      }

      this.isActualProcessing = true;

      try {
        await this.updateActualEvents({
          added: [],
          modified: [],
          removed: [{ _id: this.actualEvent.getData("id") }],
        });

        const reportMessage = `Deleted actual event for: ${this.resourceFullName}`;
        await this.createSelfResolvedIssueReport(this.actualEvent.getData("id"), reportMessage);

        this.$emit("actualUpdated");
      } catch (_) {
        this.showToast("error", this.$t("analytics.planner.actual_period_event_error"));
      }

      this.isActualProcessing = false;
    },
    async handleActualUpdate() {
      if (!this.newStartTime || (!this.newEndTime && this.isActualCompleted)) {
        this.showToast("warning", this.$t("analytics.planner.actual_not_filled"));
        return;
      }

      if (this.newEndTime && this.newStartTime > this.newEndTime) {
        this.showToast("warning", this.$t("analytics.planner.actual_period_error"));
        return;
      }

      const payload: ActualEventChanges = { added: [], modified: [], removed: [] };
      if (this.actualEvent.getData("id")) {
        payload.modified.push({
          _id: this.actualEvent.getData("id"),
          start: set(this.newStartTime, { hours: 7, minutes: 0, seconds: 0 }),
          end:
            this.isActualCompleted && this.newEndTime
              ? set(this.newEndTime, { hours: 17, minutes: 0, seconds: 0 })
              : null,
        });
      } else {
        payload.added.push({
          _id: this.actualEvent.getData("id"),
          start: set(this.newStartTime, { hours: 7, minutes: 0, seconds: 0 }),
          end:
            this.isActualCompleted && this.newEndTime
              ? set(this.newEndTime, { hours: 17, minutes: 0, seconds: 0 })
              : null,
          source_id: this.actualEvent.getData("source_id"),
        });
      }

      this.isActualProcessing = true;

      try {
        const { added_ids } = await this.updateActualEvents(payload);

        let itemId = this.actualEvent.getData("id");
        let reportMessage: string;

        if (itemId) {
          const previousStartDate = format(this.actualEvent.getData("startDate"), "dd.MM.yyyy");
          const previousEndDate = this.actualEvent.getData("active")
            ? this.$t("analytics.planner.present")
            : format(this.actualEvent.getData("endDate"), "dd.MM.yyyy");
          const newStartDate = format(this.newStartTime, "dd.MM.yyyy");
          const newEndDate =
            this.newEndTime && this.isActualCompleted
              ? format(this.newEndTime, "dd.MM.yyyy")
              : this.$t("analytics.planner.present");

          reportMessage = `Updated actual event for: ${this.resourceFullName}\nPrevious: ${previousStartDate} - ${previousEndDate}\nNew: ${newStartDate} - ${newEndDate}`;
        } else {
          itemId = added_ids[0]?.db_id;
          reportMessage = `Added actual event for: ${this.resourceFullName}`;
        }

        this.plannerEvents = this.plannerEvents.map((item) => {
          if (item.getData("type") === "actual") {
            return new EventModel({
              id: itemId,
              startDate: this.newStartTime,
              endDate: this.isActualCompleted ? this.newEndTime : null,
              type: "actual",
              active: !this.isActualCompleted,
              sourceId: this.sourceId,
            });
          }

          return item;
        });

        await this.createSelfResolvedIssueReport(itemId, reportMessage);

        this.$emit("actualUpdated", itemId);
      } catch (_) {
        this.showToast("error", this.$t("analytics.planner.actual_period_event_error"));
      }

      this.isActualProcessing = false;

      this.editMode = false;
    },
    async createSelfResolvedIssueReport(id: string, message: string) {
      await IssueReportRepository.createSelfResolvedIssueReport(
        this.currentCustomerName,
        this.currentSiteId,
        {
          type: "planner",
          entry_id: id,
          report_message: "",
          resolve_message: message,
        },
      );
    },
    setEditMode() {
      this.editMode = true;
    },
    disableEditMode() {
      this.editMode = false;
      if (this.actualEvent.getData("id")) {
        this.newStartTime = this.actualEvent.getData("startDate");
        this.newEndTime = this.actualEvent.getData("active")
          ? null
          : this.actualEvent.getData("endDate");
      } else {
        this.plannerEvents = this.plannerEvents.filter((item) => item.getData("type") !== "actual");
      }
    },
  },
  expose: ["setEditMode"],
});
</script>

<style scoped>
#headlessui-dialog-panel-10 {
  @apply max-w-2xl;
}
</style>
