import { v4 as uuid } from "uuid";
import numberService from "shared/services/numberService";
import { HierarchyTagContext, HierarchyTagStore } from "shared/types/HierarchyTag";
import { createHierarchyTagContext } from "shared/views/planner/hierarchyTags";
import {
  UnitValue,
  UnitValueAggregate,
  UnitValueRowTag,
  UnitValueTag,
  UnitValueType,
  UnitValueTypeAdjustmentFields,
} from "@/types/UnitValue";

export const precision = 2;

export const createFormatNumber =
  (locale: string) => (value: number | null, options?: { disableGrouping?: boolean }) => {
    if (value === null || !Number.isFinite(value)) {
      return null;
    }
    return value.toLocaleString(locale, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
      useGrouping: !options?.disableGrouping,
    });
  };

export const getFullUnitValueId = (unitValue: UnitValue) =>
  [unitValue.type, getTagId(unitValue.tags)].join("_");

export const getTagId = (tags: UnitValueTag[]) =>
  tags
    .map((tag) =>
      [tag.building_id, tag.level_id, tag.section_id].map((id) => id || "null").join("_"),
    )
    .join("_");

export const calculateValue = (
  duration: number | null,
  workforce: number | null,
  quantity: number | null,
) => {
  if (
    duration === null ||
    workforce === null ||
    quantity === null ||
    duration === 0 ||
    workforce === 0 ||
    Number.isNaN(duration) ||
    Number.isNaN(workforce) ||
    Number.isNaN(quantity)
  ) {
    return null;
  }
  if (quantity === 0) {
    return 0;
  }
  return (duration * workforce) / quantity;
};

const fixToPrecision = (value: number | null) => {
  if (value === null) {
    return null;
  }
  return numberService.fixToPrecision(value, precision);
};

export const calculateFields = (unitValue: UnitValue): Partial<UnitValue> => {
  const oaiWorkforceOrOverride =
    unitValue.oai_adjusted_workforce !== null
      ? unitValue.oai_adjusted_workforce
      : unitValue.oai_workforce;
  const oaiValue = fixToPrecision(
    calculateValue(unitValue.oai_duration, oaiWorkforceOrOverride, unitValue.quantity),
  );
  const typeAdjustedDuration = fixToPrecision(
    unitValue.type_duration_factor !== null && unitValue.oai_duration !== null
      ? unitValue.oai_duration + unitValue.oai_duration * unitValue.type_duration_factor
      : null,
  );
  const typeAdjustedWorkforce = fixToPrecision(
    oaiWorkforceOrOverride !== null && unitValue.type_workforce_delta !== null
      ? oaiWorkforceOrOverride + unitValue.type_workforce_delta
      : null,
  );
  const durationWorkforce = {
    type_adjusted_duration: typeAdjustedDuration,
    type_adjusted_workforce: typeAdjustedWorkforce,
    duration: unitValue.custom_adjusted_duration ?? typeAdjustedDuration ?? unitValue.oai_duration,
    workforce:
      unitValue.custom_adjusted_workforce ??
      typeAdjustedWorkforce ??
      unitValue.oai_adjusted_workforce ??
      unitValue.oai_workforce,
  };

  const value = fixToPrecision(
    calculateValue(durationWorkforce.duration, durationWorkforce.workforce, unitValue.quantity),
  );
  const typeAdjustedValue = fixToPrecision(
    value !== null && unitValue.type_value_delta !== null
      ? value + unitValue.type_value_delta
      : null,
  );
  return {
    ...durationWorkforce,
    oai_value: oaiValue,
    type_adjusted_value: typeAdjustedValue,
    value: unitValue.custom_adjusted_value ?? typeAdjustedValue ?? value,
  };
};

const getNullCalculatedValues = (): Partial<UnitValue> => ({
  oai_duration: null,
  oai_workforce: null,
  oai_value: null,
  type_adjusted_duration: null,
  type_adjusted_workforce: null,
  type_adjusted_value: null,
  duration: null,
  workforce: null,
  value: null,
});

export const calculateTypes = (unitValues: UnitValue[] | undefined) => {
  if (!unitValues || unitValues.length === 0) {
    return [];
  }
  const typeOrderByType = unitValues.reduce((acc, unitValue) => {
    acc[unitValue.type] = unitValue.type_order;
    return acc;
  }, {} as Record<UnitValueType, number>);
  const types = [...new Set(unitValues.map((unitValue) => unitValue.type))];
  const typesWithOrder = types.map((type) => ({
    type,
    order: typeOrderByType[type],
  }));
  typesWithOrder.sort((a, b) => a.order - b.order);
  return typesWithOrder.map((item) => item.type);
};

const dedupe = (items: string[]) => {
  const set = new Set<string>();
  const result: string[] = [];
  for (const item of items) {
    if (!set.has(item)) {
      result.push(item);
      set.add(item);
    }
  }
  return result;
};

const calculateSortScore = (
  unitValue: UnitValue,
  hierarchyTagsById: Record<string, HierarchyTagStore | undefined>,
) => {
  const buildingNumber = Math.min(
    ...(unitValue.tags
      .map((tag) => tag.building_id && hierarchyTagsById[tag.building_id]?.number)
      .filter((value) => typeof value === "number") as number[]),
  );
  const levelNumber = Math.min(
    ...(unitValue.tags
      .map((tag) => tag.level_id && hierarchyTagsById[tag.level_id]?.number)
      .filter((value) => typeof value === "number") as number[]),
  );
  const sectionNumber = Math.min(
    ...(unitValue.tags
      .map((tag) => hierarchyTagsById[tag.section_id]?.number)
      .filter((value) => typeof value === "number") as number[]),
  );
  const buildingScore = buildingNumber === Infinity ? 0 : buildingNumber;
  const levelScore = levelNumber === Infinity ? 0 : levelNumber;
  const sectionScore = sectionNumber === Infinity ? 0 : sectionNumber;
  return buildingScore * 1_000_000 + levelScore * 1000 + sectionScore;
};

const getFixedHierarchyTagNumbers = (hierarchyTags: HierarchyTagStore[]) => {
  const buildingTags = hierarchyTags.filter((tag) => tag.type === "building");
  buildingTags.sort((a, b) => a.name.localeCompare(b.name));
  const reorderedBuildingTags = buildingTags.map((tag, index) => ({
    ...tag,
    number: index,
  }));
  const sectionTags = hierarchyTags.filter((tag) => tag.type === "section");
  sectionTags.sort((a, b) => a.name.localeCompare(b.name));
  const reorderedSectionTags = sectionTags.map((tag, index) => ({
    ...tag,
    number: index,
  }));
  return [
    ...hierarchyTags.filter((tag) => !["building", "section"].includes(tag.type)),
    ...reorderedBuildingTags,
    ...reorderedSectionTags,
  ];
};

export const calculateRowTag = (
  unitValue: UnitValue,
  hierarchyTagsContext: HierarchyTagContext,
): UnitValueRowTag => {
  const { tagsById } = hierarchyTagsContext;
  return {
    id: getTagId(unitValue.tags),
    tags: unitValue.tags,
    building: dedupe(
      unitValue.tags
        .map((tag) => tag.building_id && tagsById[tag.building_id]?.name)
        .filter((name) => name) as string[],
    ).join(", "),
    level: dedupe(
      unitValue.tags
        .map((tag) => tag.level_id && tagsById[tag.level_id]?.name)
        .filter((name) => name) as string[],
    ).join(", "),
    section: dedupe(
      unitValue.tags
        .map((tag) => tagsById[tag.section_id]?.name)
        .filter((name) => name) as string[],
    ).join(", "),
    last: false,
    state: unitValue.state,
    actual_start: unitValue.actual_start,
    actual_end: unitValue.actual_end,
  };
};

export const calculateRowTags = (
  unitValues: UnitValue[] | undefined,
  hierarchyTags: HierarchyTagStore[] | undefined,
): UnitValueRowTag[] => {
  if (!unitValues || unitValues.length === 0) {
    return [];
  }

  const fixedNumberHierarchyTags = getFixedHierarchyTagNumbers(hierarchyTags || []);
  const hierarchyTagContext = createHierarchyTagContext(fixedNumberHierarchyTags);

  const unitValuesOneType = unitValues
    .filter((unitValue) => unitValue.type === unitValues[0].type)
    .map((unitValue) => ({
      ...unitValue,
      sortScore: calculateSortScore(unitValue, hierarchyTagContext.tagsById),
    }));

  unitValuesOneType.sort((a, b) => a.sortScore - b.sortScore);

  return unitValuesOneType.map((unitValue) => ({
    ...calculateRowTag(unitValue, hierarchyTagContext),
    last: unitValue._id === unitValuesOneType[unitValuesOneType.length - 1]?._id,
  }));
};

export const mergeRows = (currentUnitValues: UnitValue[], tagsToMerge: UnitValueTag[][]) => {
  const selectedTagIds = tagsToMerge.map((tags) => getTagId(tags));

  const unitValuesToMerge = currentUnitValues.filter((unitValue) =>
    selectedTagIds.includes(getTagId(unitValue.tags)),
  );

  const typesToMerge = [...new Set(unitValuesToMerge.map((unitValue) => unitValue.type))];

  const unitValueByType = currentUnitValues.reduce((acc, unitValue) => {
    acc[unitValue.type] = unitValue;
    return acc;
  }, {} as Record<string, UnitValue>);

  const states = [...new Set(unitValuesToMerge.map((unitValue) => unitValue.state))];

  const mergedUnitValues: UnitValue[] = typesToMerge.map((type) => ({
    ...unitValuesToMerge[0],
    ...getNullCalculatedValues(),
    _id: uuid(),
    type,
    type_order: unitValueByType[type].type_order,
    type_duration_factor: unitValueByType[type].type_duration_factor,
    type_workforce_delta: unitValueByType[type].type_workforce_delta,
    type_value_delta: unitValueByType[type].type_value_delta,
    custom_adjusted_duration: null,
    custom_adjusted_workforce: null,
    custom_adjusted_value: null,
    quantity: null,
    state: states.length === 1 ? states[0] : "not_started",
    tags: tagsToMerge.flatMap((tags) => tags),
  }));

  return [
    ...currentUnitValues.filter((unitValue) => !selectedTagIds.includes(getTagId(unitValue.tags))),
    ...mergedUnitValues,
  ];
};

export const splitRows = (currentUnitValues: UnitValue[], tagsToSplit: UnitValueTag[][]) => {
  const selectedTagIds = tagsToSplit.map((tags) => getTagId(tags));

  const unitValuesToSplit = currentUnitValues.filter((unitValue) =>
    selectedTagIds.includes(getTagId(unitValue.tags)),
  );

  const typesToSplit = [...new Set(unitValuesToSplit.map((unitValue) => unitValue.type))];

  const unitValueByType = currentUnitValues.reduce((acc, unitValue) => {
    acc[unitValue.type] = unitValue;
    return acc;
  }, {} as Record<string, UnitValue>);

  const splitUnitValues: UnitValue[] = typesToSplit.flatMap((type) =>
    tagsToSplit.flatMap((tags) =>
      tags.map((tag) => ({
        ...unitValuesToSplit[0],
        ...getNullCalculatedValues(),
        _id: uuid(),
        type,
        type_order: unitValueByType[type].type_order,
        type_duration_factor: unitValueByType[type].type_duration_factor,
        type_workforce_delta: unitValueByType[type].type_workforce_delta,
        type_value_delta: unitValueByType[type].type_value_delta,
        custom_adjusted_duration: null,
        custom_adjusted_workforce: null,
        custom_adjusted_value: null,
        quantity: null,
        tags: [tag],
      })),
    ),
  );

  return [
    ...currentUnitValues.filter((unitValue) => !selectedTagIds.includes(getTagId(unitValue.tags))),
    ...splitUnitValues,
  ];
};

export const deleteUnitValuesByType = (unitValues: UnitValue[], type: UnitValueType) => {
  const newUnitValues = unitValues.filter((unitValue) => unitValue.type !== type);
  const types = calculateTypes(newUnitValues);
  return newUnitValues.map((unitValue) => ({
    ...unitValue,
    type_order: types.indexOf(unitValue.type),
  }));
};

export const modifyUnitValuesType = (
  unitValues: UnitValue[],
  oldType: UnitValueType,
  newType: UnitValueType,
) =>
  unitValues.map((unitValue) =>
    unitValue.type === oldType
      ? {
          ...unitValue,
          ...getNullCalculatedValues(),
          type: newType,
        }
      : unitValue,
  );

export const addUnitValuesType = (unitValues: UnitValue[], type: UnitValueType) => {
  const unitValuesByTag = unitValues.reduce((acc, unitValue) => {
    acc[getTagId(unitValue.tags)] = unitValue;
    return acc;
  }, {} as Record<string, UnitValue>);
  const rowTags = calculateRowTags(unitValues, undefined);
  const types = calculateTypes(unitValues);
  return [
    ...unitValues,
    ...rowTags.map(
      (rowTag) =>
        ({
          ...getNullCalculatedValues(),
          _id: uuid(),
          type,
          type_order: types.length,
          state: unitValuesByTag[getTagId(rowTag.tags)].state,
          type_duration_factor: null,
          type_workforce_delta: null,
          type_value_delta: null,
          custom_adjusted_duration: null,
          custom_adjusted_workforce: null,
          custom_adjusted_value: null,
          quantity: null,
          oai_adjusted_workforce: null,
          ignored: false,
          approved: unitValues[0].approved,
          tags: rowTag.tags,
        } as UnitValue),
    ),
  ];
};

export const calculateDurationSum = (unitValues: UnitValue[], type: UnitValueType) => {
  const finishedUnitValuesForType = unitValues.filter(
    (unitValue) =>
      unitValue.state === "finished" && unitValue.duration !== null && unitValue.type === type,
  );
  if (finishedUnitValuesForType.length === 0) {
    return null;
  }
  return finishedUnitValuesForType.reduce(
    (acc, unitValue) => acc + (unitValue.duration as number),
    0,
  );
};

export const calculateWorkforceSum = (unitValues: UnitValue[], type: UnitValueType) => {
  const finishedUnitValuesForType = unitValues.filter(
    (unitValue) =>
      unitValue.state === "finished" &&
      unitValue.duration !== null &&
      unitValue.workforce !== null &&
      unitValue.type === type,
  );
  if (finishedUnitValuesForType.length === 0) {
    return null;
  }
  const durationSum = finishedUnitValuesForType.reduce(
    (acc, unitValue) => acc + (unitValue.duration as number),
    0,
  );
  if (durationSum === 0) {
    return 0;
  }
  return (
    finishedUnitValuesForType.reduce(
      (acc, unitValue) => acc + (unitValue.duration as number) * (unitValue.workforce as number),
      0,
    ) / durationSum
  );
};

export const calculateQuantitySum = (unitValues: UnitValue[], type: UnitValueType) => {
  const finishedUnitValuesForType = unitValues.filter(
    (unitValue) =>
      unitValue.state === "finished" && unitValue.quantity !== null && unitValue.type === type,
  );
  if (finishedUnitValuesForType.length === 0) {
    return null;
  }
  return finishedUnitValuesForType.reduce(
    (acc, unitValue) => acc + (unitValue.quantity as number),
    0,
  );
};

const median = (values: number[]): number => {
  if (values.length === 0) {
    throw new Error("Input array is empty");
  }
  values = [...values].sort((a, b) => a - b);
  const half = Math.floor(values.length / 2);
  return values.length % 2 ? values[half] : (values[half - 1] + values[half]) / 2;
};

export const calculateUnitValueSum = (unitValue: UnitValue[], type: UnitValueType) => {
  const values = unitValue
    .filter((unitValue) => unitValue.state === "finished" && unitValue.type === type)
    .map((unitValue) => unitValue.value)
    .filter((value) => value !== null) as number[];
  if (values.length === 0) {
    return null;
  }
  return median(values);
};

export const setTypeAdjustmentFields = (
  unitValues: UnitValue[],
  type: UnitValueType,
  typeAdjustmentFields: UnitValueTypeAdjustmentFields,
) =>
  unitValues.map((unitValue) => {
    const newUnitValue = {
      ...unitValue,
      ...typeAdjustmentFields,
    };
    return unitValue.type === type
      ? {
          ...newUnitValue,
          ...calculateFields(newUnitValue),
        }
      : unitValue;
  });

export const getApproved = (unitValues: UnitValue[]) => unitValues[0].approved;

export const setApproved = (unitValues: UnitValue[], approved: boolean) =>
  unitValues.map((unitValue) => ({
    ...unitValue,
    approved,
  }));

export const calculateUnitValueAggregatesByType = (unitValueAggregates: UnitValueAggregate[]) =>
  unitValueAggregates.reduce((acc, unitValueAggregate) => {
    acc[unitValueAggregate.type] = unitValueAggregate;
    return acc;
  }, {} as Record<UnitValueType, UnitValueAggregate>);
