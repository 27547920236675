import { AxiosResponse } from "axios";
import { apiClient } from "shared/repositories/clients";
import {
  CreateOrganizationResponse,
  Organization,
  OrganizationToCreate,
  OrganizationToUpdate,
  OrganizationToUpdateBase,
} from "shared/types/Organization";

const loadOrganizations = (): Promise<Organization[]> =>
  apiClient.get<Organization[]>(`/organizations/`).then((response) => response.data);

const createOrganization = (
  organizationToCreate: OrganizationToCreate,
): Promise<CreateOrganizationResponse> =>
  apiClient
    .post<CreateOrganizationResponse>(`/organizations/`, organizationToCreate)
    .then((response) => response.data);

const updateOrganization = (
  organizationToUpdate: OrganizationToUpdate,
): Promise<Organization[]> => {
  const { _id, ...payload } = organizationToUpdate;
  return apiClient
    .patch<Organization[]>(`/organizations/${_id}`, payload)
    .then((response) => response.data);
};

const updateOrganizationBase = (
  organizationToUpdate: OrganizationToUpdateBase,
): Promise<Organization[]> => {
  const { _id, ...payload } = organizationToUpdate;
  return apiClient
    .patch<Organization[]>(`/organizations/${_id}/base`, payload)
    .then((response) => response.data);
};

const deleteOrganization = (organizationId: string): Promise<Organization[]> =>
  apiClient
    .delete<Organization[]>(`/organizations/${organizationId}`)
    .then((response) => response.data);

const loadLogoUrl = (organizationId: string): Promise<string | null> =>
  apiClient
    .get<void, AxiosResponse<{ url: string }>>(`/organizations/${organizationId}/logo`)
    .then((response) => response.data.url)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

const deleteLogo = (organizationId: string): Promise<void> =>
  apiClient.delete(`/organizations/${organizationId}/logo`).then((response) => response.data);

const uploadLogo = (organizationId: string, fileString: string): Promise<string> =>
  apiClient
    .post<void, AxiosResponse<{ url: string }>>(`/organizations/${organizationId}/logo`, {
      file: fileString,
    })
    .then((response) => response.data.url);

export default {
  loadOrganizations,
  createOrganization,
  updateOrganization,
  updateOrganizationBase,
  deleteOrganization,
  loadLogoUrl,
  deleteLogo,
  uploadLogo,
};
