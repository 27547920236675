<template>
  <div class="flex flex-col gap-12">
    <div class="flex flex-col gap-4">
      <div class="flex items-center pr-1.5">
        <h1 class="text-xl font-bold text-gray-900 px-1 truncate">
          {{ $t("organizations.users") }}
        </h1>
        <div class="flex-1" />
        <PlusIcon
          class="w-5 h-5 cursor-pointer text-gray-600 shrink-0"
          v-if="hasOrganizationAdminPermission"
          @click.stop="isInviteUsersModalOpen = true"
        />
      </div>
      <div v-if="isFetching" class="bg-white border rounded loadingEl h-[200px]" />
      <div v-if="!isFetching" class="bg-white border rounded flex flex-col min-h-[200px]">
        <OrganizationTabUsersList
          :users="usersForOrganization"
          :organization="organization"
          :canEdit="hasOrganizationAdminPermission"
          :hideNoUsersMessage="childOrganizations.length > 0"
        />
        <OrganizationTabUsersHierarchyItem
          v-for="(childOrganization, index) in childOrganizations"
          :key="childOrganization._id"
          :organization="childOrganization"
          :organizationsByParentId="organizationsByParentId"
          :usersByOrganizationId="usersByOrganizationId"
          :canEdit="hasOrganizationAdminPermission"
          :hideFirstTopBorder="index === 0 && usersForOrganization.length === 0"
        />
      </div>
    </div>
    <InviteUsersModal
      v-if="isInviteUsersModalOpen"
      :existingUsers="usersForOrganization"
      :organizationId="organization._id"
      @close="isInviteUsersModalOpen = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { PlusIcon } from "@heroicons/vue/24/solid";
import { computed, defineProps, ref } from "vue";
import { useHasOrganizationPermission } from "shared/composables/organization";
import { Organization } from "shared/types/Organization";
import { User } from "shared/types/User";
import InviteUsersModal from "@/components/users/InviteUsersModal.vue";
import { useUsersForOrganization } from "@/composables/user";
import OrganizationTabUsersHierarchyItem from "@/views/organizations/components/OrganizationTabUsersHierarchyItem.vue";
import OrganizationTabUsersList from "@/views/organizations/components/OrganizationTabUsersList.vue";

const props = defineProps<{
  organization: Organization;
  organizationsByParentId: Record<string, Organization[] | undefined>;
}>();

const isInviteUsersModalOpen = ref(false);

const hasOrganizationAdminPermission = useHasOrganizationPermission(
  "organization_admin",
  props.organization._id,
);

const { users, isFetching } = useUsersForOrganization(computed(() => props.organization._id));

const childOrganizations = computed(
  () => props.organizationsByParentId[props.organization._id] || [],
);

const usersByOrganizationId = computed(() =>
  users.value.reduce((acc, user) => {
    for (const organization of user.organizations) {
      if (organization.explicit_groups.length > 0) {
        if (!acc[organization.organization_id]) {
          acc[organization.organization_id] = [];
        }
        (acc[organization.organization_id] as User[]).push(user);
      }
    }
    return acc;
  }, {} as Record<string, User[] | undefined>),
);

const usersForOrganization = computed(
  () => usersByOrganizationId.value[props.organization._id] || [],
);
</script>
