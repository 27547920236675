import {
  CollectionFilter,
  DateHelper,
  DomClassList,
  EventModelConfig,
  ResourceInfoColumn,
  ResourceModel,
  SchedulerEventModel,
  SchedulerPro,
  SchedulerProBase,
  SchedulerProConfig,
} from "@bryntum/schedulerpro";
import { BryntumSchedulerProProps } from "@bryntum/schedulerpro-react";
import { format, subDays } from "date-fns";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { SimplifiedPlannerProcess } from "shared/types/Plan";
import { baseSchedulerProConfig } from "shared/views/planner/SchedulerProConfig";
import { processElementColor } from "@/constants/processClasses";
import i18n from "@/i18n";
import textService from "@/services/textService";
import { TagGroupedProcess } from "@/views/process_gantt/groupProcesses";

const { t } = i18n.global;

export type ProcessResourceModel = Partial<ResourceModel> & {
  component?: TagGroupedProcess["tags"];
  component_ids?: TagGroupedProcess["tag_ids"];
  index?: number;
  process?: string;
  processElement?: string;
  getHolidayFn?: CallableFunction;
};

export type ProcessEventModel = Partial<EventModelConfig> & {
  processElement: string;
  processes: SimplifiedPlannerProcess[];
};

const escapeRegExp = (text: string) => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const generateOutagesTooltip = (
  outages: { start_time: Date; end_time: Date; camera_name: string }[] | null,
  t: (key: string) => string,
) => {
  if (!outages || outages.length === 0) {
    return "";
  }
  return `
    <div class="flex flex-col mt-4">
      <h4 class="border-b pb-1 text-base flex gap-2 justify-between items-center">
        ${t("analytics.planner.outages")}
      </h4>
      <div class="flex gap-2 flex-wrap mt-2" style="max-width: 400px">
            ${outages
              .map(
                (outage) => `
              <div class="flex text-sm text-white px-1 py-0.5 rounded w-min whitespace-nowrap bg-red-300">
                <span>${outage.camera_name}:&nbsp;</span>
                <span>${format(outage.start_time, "HH:mm")}-${format(
                  outage.end_time,
                  "HH:mm",
                )}</span>
              </div>
            `,
              )
              .join("")}
    </div>
  `;
};

const generateHolidayTooltip = (holidayInfo: {
  name: string;
  start_date: Date;
  end_date: Date;
  type: string;
}) => {
  if (holidayInfo) {
    const timeRange =
      holidayInfo.start_date.getTime() === holidayInfo.end_date.getTime()
        ? format(holidayInfo.start_date, "dd.MM.yyyy")
        : `${format(holidayInfo.start_date, "dd.MM.yyyy")}-${format(
            holidayInfo.end_date,
            "dd.MM.yyyy",
          )}`;

    return `
      <div style="${
        holidayInfo.type === "disturbance"
          ? "background-color: #f5e3be; color: #9c7018"
          : "background-color: #bfdbfe4c; color: #1d4ed8"
      }; border-radius: 4px; width: min-content; padding: 2px 4px; font-size: 14px; white-space: nowrap;">
        ${holidayInfo.name} (${timeRange})
      </div>
    `;
  }
  return "";
};

export const getSchedulerProConfig = (): Partial<BryntumSchedulerProProps> => {
  const colors = {
    building: {
      text: "text-gray-100",
      txtDark: "text-gray-100",
      bgLight: "bg-gray-400",
      bgDark: "bg-yellow-500",
    },
    level: {
      text: "text-gray-700",
      txtDark: "text-gray-100",
      bgLight: "bg-gray-300",
      bgDark: "bg-yellow-400",
    },
    section: {
      text: "text-gray-700",
      txtDark: "text-gray-100",
      bgLight: "bg-gray-200",
      bgDark: "bg-yellow-300",
    },
    component: {
      text: "text-gray-700",
      txtDark: "text-gray-100",
      bgLight: "bg-gray-100",
      bgDark: "bg-yellow-100",
    },
  } as const;

  return {
    ...(baseSchedulerProConfig("process") as unknown as Partial<SchedulerProConfig>),
    taskEditFeature: false,
    columns: [
      {
        text: "#",
        field: "index",
        width: 30,
        htmlEncode: false,
        renderer({ record }: { record: ProcessResourceModel }) {
          return `<span class="text-sm">${record.index}</span>`;
        },
        filterable: false,
      },
      {
        text: t("analytics.processes.tags"),
        field: "component",
        autoWidth: true,
        autoHeight: true,
        minWidth: 220,
        htmlEncode: false,
        cls: "plannerColFilter",
        filterable: {
          id: "component",
          filterFn({
            record,
            property,
            value,
          }: {
            property: string;
            record: Record<string, TagGroupedProcess["tags"]>;
            value: string;
          }) {
            const tags = record[property as keyof typeof record];

            const inputs = value.split(" ").filter(Boolean);

            return inputs.every((input) => {
              return Object.values(tags || {}).some((tag) =>
                (tag?.toLowerCase() || "").includes(input?.toLowerCase()),
              );
            });
          },
        },
        renderer({
          value,
          column,
        }: {
          value: TagGroupedProcess["tags"];
          column: ResourceInfoColumn;
        }) {
          const filterValues: string[] =
            (column as ResourceInfoColumn & { $filter: CollectionFilter }).$filter?.value
              ?.split(" ")
              ?.filter(Boolean) || [];

          const checkForFullMatch = (name: string, filterValues: string[], i: number) => {
            const wordsCount = name.split(" ").length;

            if (wordsCount === 1) {
              return name.toLowerCase() === filterValues[i].toLowerCase();
            }

            return name.split(" ").every((word, j) => {
              return word.toLowerCase() === filterValues.at(i + j)?.toLowerCase();
            });
          };

          const html = `
          <div style="display:flex;gap:6px">
            ${(Object.entries(value || {}) as [keyof typeof value, string][])
              .map(([type, name]) => {
                let isFullMatch = false;
                let modifiedName = name;

                filterValues.forEach((filterValue, i) => {
                  const index = modifiedName.toLowerCase().indexOf(filterValue.toLowerCase());
                  const tagCloseIndex = modifiedName.lastIndexOf(">", index);
                  const openTagIndex = modifiedName.lastIndexOf("<", index);
                  isFullMatch = isFullMatch || checkForFullMatch(name, filterValues, i);

                  if (tagCloseIndex >= openTagIndex && !isFullMatch) {
                    if (["*", "+", "?"].includes(filterValue)) {
                      filterValue = `\\${filterValue}`;
                    }

                    modifiedName = modifiedName.replace(
                      new RegExp(`(${escapeRegExp(filterValue)})`, "gi"),
                      '<span class="bg-yellow-300">$1</span>',
                    );
                  }
                });

                const triggerInput = `(()=>{
                  const input = document.querySelector('.plannerColFilter input');
                  input.value?.includes('${name}') ? 
                    input.value = input.value.replace('${name}', '').split(' ').filter(Boolean).join(' ') :
                    input.value += ' ${name}';
                  input.dispatchEvent(new Event('input'));
                })()`;

                return `<div class="px-2.5 py-1 my-1 cursor-pointer rounded-full align-middle text-xs whitespace-nowrap
                  ${isFullMatch ? colors[type].bgDark : colors[type].bgLight} ${colors[type].text}"
                  ondblclick="${triggerInput}">
                    ${isFullMatch ? name : modifiedName}
                  </div>`;
              })
              .join("")}
          </div>`;
          return html;
        },
      },
      {
        text: t("analytics.processes.processes"),
        field: "process",
        autoWidth: true,
        autoHeight: true,
        hidden: true,
        cls: "plannerColFilter",
        minWidth: 220,
        htmlEncode: false,
        renderer({ record }: { record: ProcessResourceModel }) {
          return `<span class="font-normal text-sm">${record.getData?.("process")}</span>`;
        },
        filterable: {
          id: "process",
          filterFn({
            record: {
              originalData: { process },
            },
            value,
          }: {
            record: { originalData: { process: string } };
            value: string;
          }) {
            const inputs = value.split(" ").filter(Boolean);

            return inputs.some((input) => {
              return process?.toLowerCase().includes(input?.toLowerCase());
            });
          },
        },
      },
    ],
    eventRenderer({ eventRecord, renderData }) {
      const classList = renderData.cls as DomClassList;

      if (eventRecord.getData("type")?.startsWith("process_")) {
        classList.add(
          `plannerProcessEvent plannerProcessEvent_${eventRecord.getData("processElement")}`,
        );
      }
    },
    timeAxisHeaderMenuFeature: false,
    treeFeature: false,
    filterBarFeature: true,
    headerMenuFeature: false,
    viewPreset: "customWeekAndDay",
    pdfExportFeature: {
      disabled: false,
      sendAsBinary: true,
      showErrorToast: false,
      fileFormat: "pdf",
      filterStyles(styles: string[]): string[] {
        return window.location.hostname === "localhost"
          ? styles.filter((style) => !style.includes("tailwindcss"))
          : styles;
      },
      paperFormat: "A4",
      orientation: "portrait",
      alignRows: false,
      repeatHeader: false,
      exporterType: "singlepage",
      fileName: `oculai_${textService.toNormalizedFileName(
        t("analytics.processes.process_data_gantt"),
      )}.pdf`,
    },
    timeRangesFeature: {
      showCurrentTimeLine: {
        name: DateHelper.format(new Date(), "DD.MM.YYYY"),
        disabled: false,
      },
      headerRenderer({
        timeRange,
      }: {
        timeRange: { id: string | number; name: string; startDate: Date; endDate: Date };
      }) {
        if (typeof timeRange.id === "string" && timeRange.id.startsWith("holiday_")) {
          const startDate = timeRange.startDate;
          const endDate = subDays(timeRange.endDate, 1);
          const rangeLabel =
            startDate.getTime() === endDate.getTime()
              ? format(startDate, "dd.MM.yyyy")
              : `${format(startDate, "dd.MM.yyyy")}-${format(endDate, "dd.MM.yyyy")}`;
          return `
                <div class="planner-holiday-timerange-label-container tooltip">
                  <span class="planner-holiday-timerange-label">
                    ${timeRange.name}
                  </span>
                  <span class="tooltiptext tooltip-top">${timeRange.name} (${rangeLabel})</span>
                </div>`;
        }
        return `<span style="font-size: 12px">${timeRange.name}</span>`;
      },
    },
  };
};

export const getSchedulerTooltipConfig = (
  t: (key: string) => string,
): Partial<BryntumSchedulerProProps> => {
  return {
    scheduleTooltipFeature: {
      async generateTipContent(context: { date: Date; resourceRecord: ResourceModel }) {
        const name = context.resourceRecord.name;
        const formattedDate = format(context.date, "dd.MM.yyyy");
        const getOutages = context.resourceRecord.getData("getOutagesFn");
        const getHolidays = context.resourceRecord.getData("getHolidayFn");

        const outages = getOutages && getOutages(context.date);
        const holidays = getHolidays && getHolidays(context.date);

        const outagesTooltip = generateOutagesTooltip(outages, t);
        const holidaysTooltip = generateHolidayTooltip(holidays);

        return `
        <div class="flex flex-col gap-3">
          <h4 class="flex items-center ${(outagesTooltip || holidaysTooltip) && "border-b pb-1"}">
            <p class="mr-8">${name}</p>
            <p class="text-xs">${formattedDate}</p>
          </h4>
          ${
            outagesTooltip || holidaysTooltip
              ? `<div class="flex flex-col gap-3 mt-2>"
                ${outagesTooltip}
                ${holidaysTooltip}
              </div>`
              : ""
          }
        </div>`;
      },
    },
  };
};

export const useSchedulerSelection = () => {
  const router = useRouter();

  const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.replace("#", ""), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  };

  const rgbToHex = (r: number, g: number, b: number) => {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  };

  const lightenColor = (hex: string, factor: number) => {
    const { r, g, b } = hexToRgb(hex);

    const newR = Math.round(r + factor * (255 - r));
    const newG = Math.round(g + factor * (255 - g));
    const newB = Math.round(b + factor * (255 - b));

    return rgbToHex(newR, newG, newB);
  };

  const lightenProcessEventColors = computed(() => {
    const lightenFactor = 0.7;

    return Object.entries(processElementColor).reduce((acc, [key, value]) => {
      const element = key as keyof typeof processElementColor;
      acc[element] = lightenColor(value, lightenFactor);
      return acc;
    }, {} as Record<keyof typeof processElementColor, string>);
  });

  const selectionEvents = {
    domElement: null as HTMLElement | null,
    domClassList: null as DOMTokenList | null,
  };

  const removeSelectionBox = () => {
    selectionEvents.domElement?.remove();
    selectionEvents.domClassList?.remove("b-dragselecting");
    selectionEvents.domElement = null;
    selectionEvents.domClassList = null;
  };

  const handleKeyPress =
    (getInstance: () => SchedulerPro, trackEvent?: (event: string) => void) =>
    (e: KeyboardEvent) => {
      const schedulerInstance = getInstance();
      if (e.key === "Shift" && schedulerInstance.features) {
        if (trackEvent) {
          trackEvent("process-gantt_selector-shift_apply");
        }
        schedulerInstance.features.pan.disabled = true;
        schedulerInstance.features.eventDragSelect.disabled = false;
      }

      if (e.key === "Escape") {
        removeSelectionBox();
        schedulerInstance?.deselectEvents?.(schedulerInstance.selectedEvents);
      }
    };

  const handleKeyRelease = (getInstance: () => SchedulerPro) => (e: KeyboardEvent) => {
    const schedulerInstance = getInstance();

    if (e.key === "Shift" && schedulerInstance.features) {
      schedulerInstance.features.pan.disabled = false;
      schedulerInstance.features.eventDragSelect.disabled = true;
    }
  };

  const mountSelection = (getInstance: () => SchedulerPro, trackEvent: (event: string) => void) => {
    document.body.addEventListener("keydown", handleKeyPress(getInstance, trackEvent));
    document.body.addEventListener("keyup", handleKeyRelease(getInstance));
  };

  const unmountSelection = (getInstance: () => SchedulerPro) => {
    document.body.removeEventListener("keydown", handleKeyPress(getInstance));
    document.body.removeEventListener("keyup", handleKeyRelease(getInstance));
  };

  const updateSelectionBoxPosition = (
    schedulerInstance: SchedulerPro,
    events: SchedulerEventModel[],
  ) => {
    const element = selectionEvents.domElement;
    const borderCoordinates = {
      top: Number.MAX_SAFE_INTEGER,
      left: Number.MAX_SAFE_INTEGER,
      right: 0,
      bottom: 0,
    };

    if (!events.length || !element) {
      return;
    }

    events.forEach((event) => {
      const eventId = event.id;
      const baseEvent = schedulerInstance.eventStore.getById(eventId) as SchedulerEventModel;

      if (!baseEvent) {
        return;
      }

      const eventBox = schedulerInstance.getResourceEventBox(baseEvent, baseEvent.resource);

      if (eventBox) {
        borderCoordinates.top = Math.min(borderCoordinates.top, eventBox.top);
        borderCoordinates.left = Math.min(borderCoordinates.left, eventBox.left);
        borderCoordinates.right = Math.max(borderCoordinates.right, eventBox.right);
        borderCoordinates.bottom = Math.max(borderCoordinates.bottom, eventBox.bottom);
      }
    });

    const width = borderCoordinates.right - borderCoordinates.left;
    const height = borderCoordinates.bottom - borderCoordinates.top;

    element.style.transform = `matrix(1, 0, 0, 1, ${borderCoordinates.left}, ${borderCoordinates.top})`;
    element.style.width = `${width}px`;
    element.style.height = `${height}px`;
  };

  const config: Partial<SchedulerProConfig> & Record<string, unknown> = {
    deselectAllOnScheduleClick: false,
    multiEventSelect: true,
    eventDragSelectFeature: {
      disabled: true,
      onDocumentMouseUp(event: MouseEvent) {
        removeSelectionBox();

        const me = this as unknown as Record<string, unknown> & {
          mouseUpDetacher: CallableFunction;
        };
        const client = me.client as SchedulerProBase & Record<string, unknown>;
        const selectedAssignments = client.selectedAssignments;
        const navigator = client.navigator as { activeItem: HTMLElement } & Record<string, unknown>;
        const scheduler = me.owner as SchedulerPro & { currentElement: HTMLElement };

        scheduler.currentElement.classList.remove("b-dragselecting");
        client.disableScrollingCloseToEdges(client.timeAxisSubGrid);
        me.startX = me.startY = null;

        navigator.skipNextClick = client.timeAxisSubGridElement.contains(event.target as Node);
        navigator.disabled = false;

        if (selectedAssignments.length) {
          navigator.skipScrollIntoView = true;
          client.activeAssignment = selectedAssignments[selectedAssignments.length - 1];
          navigator.activeItem?.focus();
          navigator.skipScrollIntoView = false;
        }

        me.mouseUpDetacher();

        selectionEvents.domElement = me.element as HTMLElement;
        selectionEvents.domClassList = client.element.classList;

        if (!selectedAssignments.length) {
          removeSelectionBox();
        }
      },
    },
    eventSelectionDisabled: true,
    cellMenuFeature: {
      items: {
        selectRow: {
          icon: "b-fa-regular b-fa-object-ungroup",
          text: t("analytics.processes.sidebar.select_row"),
          onItem({ record, source }: { record: ResourceModel; source: SchedulerPro }) {
            const events = record.events;

            source.deselectEvents(source.selectedEvents);
            source.selectEvents(events);
            removeSelectionBox();
          },
        },
        viewEvents: {
          icon: "b-fa-regular b-fa-arrow-up-right-from-square",
          text: t("analytics.processes.sidebar.open_in_activity_log"),
          onItem({ record }: { record: ResourceModel }) {
            const component_ids = (record.getData("component_ids") || {}) as NonNullable<
              ProcessResourceModel["component_ids"]
            >;
            const routeData = router.resolve({
              name: "ProcessesTable",
              query: {
                location: [component_ids.building, component_ids.level, component_ids.section]
                  .filter((tagId) => tagId)
                  .join(","),
              },
            });

            window.open(routeData.href, "_blank");
          },
        },
      },
    },
  };

  return {
    lightenProcessEventColors,
    schedulerSelectionConfig: config,
    mountSelection,
    unmountSelection,
    removeSelectionBox,
    updateSelectionBoxPosition,
  };
};
