<template>
  <Sidebar>
    <div class="flex items-center justify-center oaiMainContentFullScreen" v-if="isLoading">
      <LoadingSpinner />
    </div>
    <div
      v-if="!isLoading && organizations"
      class="flex flex-col gap-4 h-[calc(100vh-48px)] lg:h-screen"
    >
      <PageHeader>
        <div class="md:px-6 px-4 flex-1 flex gap-2 lg:gap-4 items-center">
          <div class="truncate">{{ $t("organizations.title") }}</div>
          <div class="text-gray-400 text-base font-normal">|</div>
          <OrganizationSelector
            :rootOrganizations="rootOrganizations"
            :organizationsByParentId="organizationsByParentId"
            :selectedOrganization="selectedOrganization"
            @selectOrganization="selectOrganization"
          />
        </div>
        <template #content>
          <div class="flex gap-1 mr-4 min-w-0" v-if="hasAdminPermission">
            <button
              @click="isAddOrganizationModalOpen = true"
              type="button"
              class="min-w-0 flex items-center gap-1 rounded bg-yellow-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
            >
              <PlusIcon class="h-5 w-5" />
              <span class="truncate">{{ $t("organizations.add_organization") }}</span>
            </button>
            <OrganizationsViewMenu :selectedOrganization="selectedOrganization" />
          </div>
        </template>
      </PageHeader>
      <OrganizationDetail
        :key="selectedOrganization?._id"
        v-if="selectedOrganization"
        :organization="selectedOrganization"
        :organizationsByParentId="organizationsByParentId"
        class="lg:border-t flex-1 overflow-auto mt-4 lg:mt-0"
      />
    </div>
  </Sidebar>
  <AddOrganizationModal
    v-if="isAddOrganizationModalOpen"
    :organization="selectedOrganization"
    @close="isAddOrganizationModalOpen = false"
    @selectOrganization="selectOrganization"
  />
</template>

<script setup lang="ts">
import { PlusIcon } from "@heroicons/vue/24/outline";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useOrganizations } from "shared/composables/organization";
import { useHasPermission } from "shared/composables/project";
import { Organization } from "shared/types/Organization";
import PageHeader from "@/components/layout/PageHeader.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import AddOrganizationModal from "@/views/organizations/components/AddOrganizationModal.vue";
import OrganizationDetail from "@/views/organizations/components/OrganizationDetail.vue";
import OrganizationSelector from "@/views/organizations/components/OrganizationSelector.vue";
import OrganizationsViewMenu from "@/views/organizations/components/OrganizationsViewMenu.vue";

const isAddOrganizationModalOpen = ref(false);

const route = useRoute();
const router = useRouter();
const { organizations, isLoading } = useOrganizations();

const hasAdminPermission = useHasPermission("app_admin");

const rootOrganizations = computed(() =>
  organizations.value.filter((organization) => !organization.parent_id),
);

const organizationsByParentId = computed(() =>
  organizations.value.reduce((acc, organization) => {
    if (!organization.parent_id) {
      return acc;
    }
    if (!acc[organization.parent_id]) {
      acc[organization.parent_id] = [];
    }
    (acc[organization.parent_id] as Organization[]).push(organization);
    return acc;
  }, {} as Record<string, Organization[] | undefined>),
);

const selectedOrganization = computed<Organization | undefined>(
  () =>
    organizations.value.find((organization) => organization._id === route.query.id) ||
    organizations.value.filter(
      (organization) => (organizationsByParentId.value[organization._id] || []).length === 0,
    )[0] ||
    organizations.value[0],
);

const selectOrganization = (organizationId: string | undefined) => {
  router.push({ query: { ...route.query, id: organizationId } });
};
</script>
