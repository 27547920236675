<template>
  <Modal :open="open" @close="emit('close')" customCls="w-full md:w-3/4 xl:w-1/2">
    <template #title>
      {{ $t(`project.add.title`) }}
      <div class="flex justify-center">
        <div class="text-xs font-normal text-gray-500" style="max-width: 400px">
          {{ $t(`project.add.subtitle`) }}
        </div>
      </div>
    </template>
    <template #content>
      <Form
        @submit="handleSubmit"
        :validationSchema="schema"
        :initialValues="initializeForm()"
        class="flex flex-col gap-4 px-4 overflow-auto"
        v-slot="{ submitCount, isSubmitting }"
      >
        <InputField name="name" :minWidth="50" :label="$t(`project.add.name`)" />

        <div class="flex justify-between gap-4">
          <SearchListField
            class="w-full"
            name="country_code"
            :label="$t(`project.add.country_code`)"
            :options="countryOptions"
          ></SearchListField>
          <InputField
            class="w-full"
            :minWidth="50"
            name="street"
            :label="$t(`project.add.street`)"
          />
          <InputField
            class="w-full"
            :minWidth="50"
            name="zip_code"
            :label="$t(`project.add.zip_code`)"
          />
        </div>
        <DateField name="estimated_start" :label="$t(`project.add.estimated_start`)" />
        <div class="flex justify-between gap-4">
          <IntegerField
            class="w-full"
            name="estimated_duration"
            :label="$t(`project.add.estimated_duration`)"
          />
          <CurrencyField
            class="w-full"
            name="estimated_shell_volume"
            :label="$t(`project.add.estimated_shell_volume`)"
          />
          <IntegerField
            class="w-full"
            name="estimated_cameras"
            :label="$t(`project.add.estimated_cameras`)"
          />
        </div>

        <FieldArray name="goals" v-slot="{ fields }">
          <div class="flex items-center gap-3 mt-1">
            <div class="block text-sm font-medium leading-6 text-gray-900">
              {{ $t(`project.add.project_goals`) }}
            </div>
            <ErrorMessage v-if="submitCount > 0" class="text-xs text-red" name="goals" />
          </div>
          <div class="-mt-4 grid grid-cols-2 gap-2">
            <div
              v-for="(field, idx) in fields as FieldEntry<GoalEntry>[]"
              :key="idx"
              class="inline-block max-w-fit"
            >
              <CheckboxField
                :name="`goals[${idx}].enabled`"
                :label="$t(`project.add.goals.${field.value.type}`)"
                :labelFontWeight="400"
              />
            </div>
          </div>
        </FieldArray>
        <FileInputAddProjectField name="files" />

        <div class="flex items-center justify-center pb-1">
          <button
            :disabled="isSubmitting"
            type="submit"
            class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-yellow-600 px-5 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          >
            <div class="-ml-1 mr-2">
              <PlusIcon v-if="!isSubmitting" class="h-5 w-5" aria-hidden="true" />
              <LoadingSpinner v-else size="w-5 h-5" color="white" />
            </div>
            {{ $t(`project.add.add_project`) }}
          </button>
        </div>
      </Form>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { PlusIcon } from "@heroicons/vue/24/solid";
import { format } from "date-fns";
import { FieldArray, FieldEntry, Form, SubmissionHandler, ErrorMessage } from "vee-validate";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useCustomToast } from "shared/composables/toast";
import { countries } from "shared/services/countries";
import { ProjectGoalTypes, ProjectRequest } from "shared/types/Project";
import CheckboxField from "@/components/forms/CheckboxField.vue";
import CurrencyField from "@/components/forms/CurrencyField.vue";
import DateField from "@/components/forms/DateField.vue";
import InputField from "@/components/forms/InputField.vue";
import IntegerField from "@/components/forms/IntegerField.vue";
import SearchListField from "@/components/forms/SearchListField.vue";
import ProjectRepository from "@/repositories/ProjectRepository";
import FileInputAddProjectField from "@/views/dashboard/components/FileInputAddProjectField.vue";

const { t } = useI18n();
const emit = defineEmits(["close"]);

defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

type GoalEntry = {
  type: ProjectGoalTypes;
  enabled: boolean;
};

type FileEntry = {
  size: number;
  name: string;
  file_base64: string;
};

type FormValues = {
  name: string;
  country_code: string;
  zip_code: string;
  street: string;
  estimated_start: string;
  estimated_duration: number;
  estimated_shell_volume: number;
  estimated_cameras: number;
  goals: GoalEntry[];
  files: FileEntry[];
};

const defaultSelectedCountryCode = "DE";
const countryOptions = countries.map((country) => {
  return {
    value: country.code,
    name: `${country.name} (${country.code})`,
  };
});

const fileSizeThresholdBytes = 49000000; // 50 MB

const schema = yup.object().shape({
  name: yup.string().required(t("authentication.validation.validation_field_required")),
  country_code: yup.string().required(t("authentication.validation.validation_field_required")),
  street: yup.string().required(t("authentication.validation.validation_field_required")),
  zip_code: yup.string().required(t("authentication.validation.validation_field_required")),
  estimated_duration: yup
    .number()
    .required(t("authentication.validation.validation_field_required")),
  estimated_cameras: yup
    .number()
    .required(t("authentication.validation.validation_field_required")),
  estimated_shell_volume: yup
    .number()
    .required(t("authentication.validation.validation_field_required")),
  estimated_start: yup.date().required(t("authentication.validation.validation_field_required")),
  goals: yup
    .array()
    .of(
      yup.object().shape({
        enabled: yup.boolean().required(),
      }),
    )
    .test(
      "at-least-one-enabled",
      t("project.add.validation.at_least_one_enabled"),
      function (goals) {
        return goals?.some((goal) => goal.enabled === true);
      },
    ),
  files: yup
    .array()
    .test("aggregated-file-size", t("project.add.validation.file_size_exceeded"), function (files) {
      return files?.reduce((sum, obj) => sum + obj.size, 0) < fileSizeThresholdBytes;
    }),
});

const initializeForm = () => {
  return {
    country_code: defaultSelectedCountryCode,
    goals: [
      { type: "cameras", enabled: false },
      { type: "shorten_construction_time", enabled: false },
      { type: "target_actual_schedule", enabled: false },
      { type: "unit_values", enabled: false },
      { type: "claims", enabled: false },
      { type: "site_management_assistant", enabled: false },
    ],
    files: [],
  };
};

const handleSubmit: SubmissionHandler = async (genericObject) => {
  const values = genericObject as FormValues;

  const payload = {
    ...values,
    estimated_start: format(values.estimated_start, "yyyy-MM-dd"),
    goals: values.goals.filter((item) => item.enabled).map((item) => item.type),
    files: values.files.map((item) => {
      return {
        name: item.name,
        file_base64: item.file_base64,
      };
    }),
  } as ProjectRequest;

  return ProjectRepository.requestProject(payload).then((data) => {
    if (data.status === "OK") {
      useCustomToast().success(t("project.add.success_message"));
      emit("close");
    }
  });
};
</script>
