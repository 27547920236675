<template>
  <div v-if="users.length > 0">
    <OrganizationTabUsersItem
      v-for="(user, index) in sortedUsers"
      :key="user._id"
      :user="user"
      :organization="organization"
      :canEdit="canEdit"
      :showCompanyTypeBadge="getShouldShowCompanyTypeBadge(user, index)"
      :indentation="indentation"
      :cls="index === sortedUsers.length - 1 ? '' : 'border-b'"
    />
  </div>
  <div
    v-if="!hideNoUsersMessage && sortedUsers.length === 0"
    class="px-2 py-2 truncate text-gray-400 text-xs"
    :style="{ paddingLeft: `${calculateIndentation(indentation)}px` }"
  >
    {{ t("organizations.no_users") }}
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { Organization } from "shared/types/Organization";
import {
  User,
  UserCompanyType,
  UserOrganization,
  UserOrganizationPermissionGroup,
} from "shared/types/User";
import textService from "@/services/textService";
import OrganizationTabUsersItem from "@/views/organizations/components/OrganizationTabUsersItem.vue";
import { calculateIndentation } from "@/views/organizations/organizations";

const props = withDefaults(
  defineProps<{
    users: User[];
    organization: Organization;
    hideNoUsersMessage?: boolean;
    canEdit: boolean;
    indentation?: number;
  }>(),
  { indentation: 0 },
);

const { t } = useI18n();

const organizationByUsers = computed(() =>
  props.users.reduce((acc, user) => {
    acc[user._id] = user.organizations.find(
      (organization) => organization.organization_id === props.organization._id,
    );
    return acc;
  }, {} as Record<string, UserOrganization | undefined>),
);

const groupWeight: Record<UserOrganizationPermissionGroup, number> = {
  organization_base: 0,
  organization_admin: 100,
};

const getGroupWeight = (groups?: UserOrganizationPermissionGroup[]) =>
  (groups || []).map((group) => groupWeight[group] || 0).reduce((acc, item) => acc + item, 0);

const sortedUsers = computed(() =>
  props.users.slice().sort((a, b) => {
    const organizationA = organizationByUsers.value[a._id];
    const organizationB = organizationByUsers.value[b._id];

    const companyTypeA = organizationA?.company_type || "";
    const companyTypeB = organizationB?.company_type || "";

    const groupsA = getGroupWeight(organizationA?.groups);
    const groupsB = getGroupWeight(organizationB?.groups);

    const userRoleA = a.role || "";
    const userRoleB = b.role || "";

    if (companyTypeA === companyTypeB) {
      if (groupsA === groupsB) {
        return textService.localeCompareWithEmptyStringAtEnd(userRoleA, userRoleB);
      }
      return groupsB - groupsA;
    }
    return textService.localeCompareWithEmptyStringAtEnd(companyTypeA, companyTypeB);
  }),
);

const companyTypes = computed(
  () =>
    new Set<UserCompanyType>(
      props.users
        .map((user) => organizationByUsers.value[user._id]?.company_type)
        .filter((item) => item) as UserCompanyType[],
    ),
);

const getShouldShowCompanyTypeBadge = (user: User, index: number) => {
  if (companyTypes.value.size < 2) {
    return false;
  }
  const previousUser = sortedUsers.value[index - 1];
  if (!previousUser) {
    return true;
  }
  const userCompanyType = organizationByUsers.value[user._id]?.company_type ?? null;
  const previousUserCompanyType = organizationByUsers.value[previousUser._id]?.company_type ?? null;
  return userCompanyType !== previousUserCompanyType;
};
</script>
